export const customerServiceOfficeData = {
    name: 'Biuro obsługi klienta',
    employees: [
        {
            imageUrl: 'foto_Ania.png',
            name: 'Anna Raczkowska',
            workplace: 'Specjalista ds. obsługi klienta',
            telephone: '+48 585 055 914',
            email: 'kontakt@edu-sense.com',
        },
        {
            imageUrl: 'foto_Monika.png',
            name: 'Monika Kmiecik',
            workplace: 'Specjalista ds. obsługi klienta',
            telephone: '+48 585 055 914',
            email: 'kontakt@edu-sense.com',
        },
    ],
};

export const salesDepartmentData = {
    name: 'Dział sprzedaży',
    employees: [
        {
            imageUrl: 'foto_Aga.png',
            name: 'Agnieszka Dereniowska',
            workplace: 'Koordynator ds. dofinansowań i ofertowania',
            telephone: '+48 585 055 906',
            email: 'agnieszka.dereniowska@edu-sense.com',
        },
        {
            imageUrl: 'foto_Sebastian.png',
            name: 'Sebastian Ługowski',
            workplace: 'Koordynator ds. sprzedaży i ofertowania',
            telephone: '+48 585 055 975',
            email: 'sebastian.lugowski@edu-sense.com',
        },
        {
            imageUrl: 'foto_Mateusz.png',
            name: 'Mateusz Koman',
            workplace: 'Manager ds. sprzedaży i rozwoju',
            telephone: '+48 502 384 501',
            email: 'mateusz.koman@edu-sense.com',
        },
    ],
};
