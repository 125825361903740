import React from 'react';
import {
    Image, StyledEmployee, Link, Text,
} from './EmployeeStyles';

const Employee = ({ employee, enableExtraStyles }) => {
    const {
        imageUrl, name, workplace, telephone, email,
    } = employee;

    return (
        <StyledEmployee enableExtraStyles={enableExtraStyles}>
            <Image src={`${process.env.REACT_APP_CDN_URL}${imageUrl}`} alt={name} />
            <Text>{name}</Text>
            <Text>{workplace}</Text>
            {telephone && <Link href={`tel:${telephone}`}>{`Tel. ${telephone}`}</Link>}
            <Link href={`mailto:${email}`}>{email}</Link>
        </StyledEmployee>
    );
};

export default Employee;
