import React, { useContext } from 'react';
import AppContext from '../../../context/app-context';
import {
    EventDate, EventName, Month, StyledEvent, Day,
} from './EventStyles';

const Event = ({ event }) => {
    const appContext = useContext(AppContext);

    return (
        <StyledEvent to={`${appContext.routeMap.events.path[appContext.lang]}`}>
            <EventDate>
                <Month>
                    {(new Date(event.start_at).toLocaleDateString(appContext.lang, { month: 'short' }))}
                </Month>
                <Day>{(new Date(event.start_at)).getDate()}</Day>
            </EventDate>
            <EventName>
                {event.city}
                {' '}
                |
                {' '}
                {event.name}
            </EventName>
        </StyledEvent>
    );
};

export default Event;
