import React from 'react';
import ReactMarkdown from 'react-markdown';
import {Description, RightColumn, Row} from "./ContentStyles";
import LinkedProductsLister from './LinkedProductsLister';

export default function Content({description, rightColumnContent, productId}) {
    return (
        <Row className="text-left">
            <Description>
                <ReactMarkdown source={description} escapeHtml={false}/>
            </Description>
            <RightColumn>
                <ReactMarkdown source={rightColumnContent} escapeHtml={false}/>
                <LinkedProductsLister productId={productId}/>
            </RightColumn>
        </Row>
    );
}