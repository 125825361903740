import React from 'react';
import {
    Column,
    Description,
    Header,
    Image,
    LeftColumn,
    RightColumn,
    Row,
} from "./ProductDescriptionStyles";
import Container from "../Container/Container";
import {EmptyCardText, EmptyCardTitle} from "../ProductsList/Card/EmptyCardStyles";
import {PriceElement} from "../ProductsList/Card/CardStyles";
import {RightColumn as ContentRightColumn, Description as ContentDescription} from './Content/ContentStyles';

export default function ProductDescription({product, language}) {
    return (
        <Container>
            <Row>
                <LeftColumn>
                    <Image className="empty"/>
                </LeftColumn>
                <Column>
                    <Header><EmptyCardTitle>&nbsp;</EmptyCardTitle></Header>
                    <Description>
                        <EmptyCardText>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                        </EmptyCardText>
                    </Description>
                </Column>
                <RightColumn className="justify-content-between empty">
                    <PriceElement className="text-right pr-4 d-flex flex-column justify-content-end"/>
                </RightColumn>
            </Row>
            <div id="more-product-description" className="p-4"/>
            <Row className="text-left bg-transparent">
                <ContentDescription className="empty flex-column d-flex">
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                </ContentDescription>
                <ContentRightColumn className="empty flex-column d-flex">
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                </ContentRightColumn>
            </Row>
        </Container>
    );
}