import styled from 'styled-components';

export const InfoWrapper = styled.section`
    margin-top: 120px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;
    text-align: left;

    @media (max-width: 1350px) {
        padding: 0 24px;
    }

    @media (max-width: 992px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
`;

export const InfoColumn = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Paragraph = styled.p`
    color: #00437E;
    font-family: 'Roboto Condensed',sans-serif;
    font-size: 17px;
    line-height: 1.3;
    color: #00437E;
`;

export const StyledLink = styled.a`
    text-transform: uppercase;
    font-size: 40px;
    line-height: 1;
    font-weight: 700;
    color: #00afff;
    max-width: 400px;

    &:after {
        content: " >>";
    }

    &:hover {
        text-decoration: underline;
        color: #00afff;
    }

    @media (max-width: 992px) {
        max-width: unset;
        margin-bottom: 24px;
    }
`;

export const ImportantText = styled.strong`
    color: #00afff;
    font-size: 22px;
`;

export const Contact = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;

    @media (max-width: 1350px) {
        grid-gap: 20px;
    }

    @media (max-width: 575px) {
        grid-template-columns: 1fr;
    }
`;

export const ContactDescription = styled.p`
    color: #00afff;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'Roboto Condensed';
    font-weight: 700;
    margin: 0 0 8px;
`;

export const Phone = styled.a`
    font-size: 26px;
    font-weight: 700;
    color: #00437e;
    line-height: 1;

    &:hover {
        color: #00afff;
    }
`;

export const Email = styled.a`
    display: block;
    color: white;
    background-color: #00afff;
    padding: 16px;
    font-weight: 700;
    border: 1px solid #00afff;
    font-size: 20px;
    text-align: center;

    &:hover {
        color: #00afff;
        background-color: white;
    }

    @media (max-width: 992px) {
        font-size: 20px;
        text-align: center;
    }
`;
