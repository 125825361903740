import styled from 'styled-components';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';

export const HomeDividedBlock = styled(Col)`
    height: 21rem;
    padding: 0;
    background-image: url(${(props) => props.imagepath});
    background-position: center;
    background-size: cover;
    border: 1px solid #fff;

    &:nth-child(1){
        border-width: 0 1px 1px 0;
    }
    &:nth-child(2){
        border-width: 0 0 1px 1px;
    }
    &:nth-child(3){
        border-width: 1px 1px 0 0;
    }
    &:nth-child(4){
        border-width: 1px 0 0 1px;
    }

    @media (max-width: 1280px){

    }
`;

export const LeftCol = styled(Col)`
    @media(min-width: 1280px) {
        padding-left: 0;
    }
`;

export const RightCol = styled(Col)`
    @media(min-width: 1280px) {
        padding-right: 0;
    }
`;

export const StyledRow = styled(Row)`
    margin: 0;
    padding: 0;
    margin-top: 24px;

    @media(min-width: 1280px) {
        justify-content: space-between;
    }

    @media(max-width: 991.98px) {
        display: none;
    }
`;
