import React from 'react';
import {ImageSlideWrapper} from './ImageSlideStyles';

export default function ImageSlide({imagepath, text, shape, href}) {
    return (
        <ImageSlideWrapper imagepath={imagepath} href={href} className={"shape-" + shape}>
            <span>{text}</span>
        </ImageSlideWrapper>
    );
};
