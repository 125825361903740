import styled from 'styled-components';
import { Button as BootstrapButton } from 'react-bootstrap';

export const StyledButtonMore = styled.span`
    font-size: 13px;
    color: rgb(250, 118, 53);
    text-decoration: none;
    font-weight: 800;
    margin-left: 8px;
    text-transform: uppercase;
    
    &:hover{
        color: #00437E;
    }
`;

export const Button = styled(BootstrapButton)`
    word-break: keep-all;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    padding: 20px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 0;
    color: white;
    outline: none;
    width: 100%;
    background-color: #00437E;
    
    &:focus {
        box-shadow: none;
    }
    
    &:hover {
        color: #00437E;
        background-color: white;
        border-color: white;
    }
    
    &:hover * {
        border-color: white;
    }
`;
