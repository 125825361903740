import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import PriceMain from '../../PriceMain/PriceMain';

export const Item = styled.div`
    background-color: #F7F7F7;
    border-top: #FFFFFF 2px solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    &.empty {
        height: 120px;
    }

    @media (max-width: 991.98px) { 
        flex-wrap: wrap;
    }    

    @media (max-width: 767.98px) {
        padding: 20px 10px;
    }
`;

export const Column = styled.div`
    padding: 0 20px;

    @media (max-width: 767.98px) { 
        padding:  0 10px;
    }

    @media (max-width: 575.98px) {
        align-items: center;
        min-width: 0 !important;
    }
`;

export const DescriptionColumn = styled(Column)`
    display: flex;
    width: 100%;

    @media (max-width: 991.98px) { 
        flex-basis: 100%;
    }  
`;

export const DetailsColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${(props) => (props.isVisible === false && 'color: #C6C6C6')};

    @media (max-width: 991.98px) { 
        flex-basis: 100%;
    }  

    @media (max-width: 767.98px) { 
        flex-wrap: wrap;
        margin-top: 10px;
    }    

    @media (max-width: 575.98px) { 
        justify-content: space-between;
    }  
`;

export const PricePerItemColumn = styled(Column)`
    @media (max-width: 575.98px) {
        display: none;   
    }
`;

export const QuantityColumn = styled(Column)`
`;

export const DiscountColumn = styled(Column)`
    min-width: 120px;
    
    @media (max-width: 767.98px) {
        min-width: 0;   
        flex-basis: auto;
    }

    @media (max-width: 350px) {
        padding: 0;
    }
`;

export const RemoveButtonColumn = styled(Column)`
    @media (max-width: 767.98px) {
        display: none;
    }
`;

export const MobileRemoveButtonColumn = styled(Column)`
    display: none;
    
    @media (max-width: 767.98px) {
        padding: 0 0 0 4px;
        display: unset;
        align-self: flex-start;
        flex-grow: 1;
    }
`;

export const ValueColumn = styled(Column)`
    min-width: 220px;
`;

export const Description = styled(Link)`
    display: flex;
    margin: 0;
    color: #00437E;
    font-size: 1.2em;
    font-weight: 700;
`;

export const Image = styled.img`
    max-width: 105px;
    width: 100%;
    flex: 1 1 auto;
    ${(props) => (props.isVisible === false && 'opacity: 0.2')};

    &.empty {
        width: 70%;
        height: 60%;
        background-image: linear-gradient(90deg, #e9e8e8, #a9a9a9);
        background-size: 400% 400%;
        background-position: 0 0;
        background-repeat: no-repeat;
     
        animation: move 1s ease infinite;
        
        @keyframes move {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
    }

    @media (max-width: 350px) {
        max-width: 64px;
        max-height: 64px;
    }
`;

export const EmptyDescription = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-basis: 0;
    margin: 0;
    color: #00437E;
    font-size: 1.2em;
    font-weight: 700;
    
    & ${Image}.empty {
        height: 100%;
    }
`;

export const Name = styled.div`
    display: flex;
    margin-left: 20px;
    flex: 3 1 auto;
    flex-basis: 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    font-size: 20px;
    line-height: 1.1;
    max-width: 500px;
    ${(props) => (props.isVisible === false && 'color: #C6C6C6')};
    
    &:hover {
        ${(props) => (props.isVisible === false && 'color: #C6C6C6')};
    }
    
    @media (max-width: 575.98px) {
        margin-left: 8px;
        font-size: 16px;
        align-items: flex-start;
    }
`;

export const StockText = styled.small`
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
    color: #F50303;

    @media (max-width: 575.98px) {
        width: 100%;
    }
`;

export const PreorderText = styled(StockText)`
    color: #00AFFF;
`;

export const PriceWrapper = styled.div`
    width: 100%;
    display: grid;
    justify-content: end;

    ${(props) => props.isOld && css`
        @media (max-width: 575.98px) {
            order: -1;
        }
    `}
`;

export const MobilePriceWrapper = styled(PriceWrapper)`
    display: none;

    @media (max-width: 575.98px) {
        padding-right: 10px;
        justify-content: flex-end;
        display: flex;
        font-size: 0.8em;
        line-height: 1;
        font-weight: 400;
        align-items: center;
    }
`;

export const Quantity = styled.div`
    display: flex;
    height: 100%;
    word-break: keep-all;
    font-weight: 700;
    padding: 6px 0;
    border: none;
    text-align: right;
    margin: 0;
    align-items: center;
    justify-content: center;
    min-width: ${(props) => (!props.readOnly ? '0' : '60px')};

    & p {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
    }

    & input {
        width: 70px;
        height: 35px;
        color: #00437E;
        border: #00437E 1px solid;
        background: transparent;
        background-color: #FFFFFF;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        padding: 0 10px;

        &:disabled {
            color: #C6C6C6;
            border-color: #C6C6C6;
        }

        @media (max-width: 575.98px) {
            width: 50px;
            font-size: 16px;
        }
    }
  
    & input[type=number] {
        -moz-appearance:textfield;
    }
    
    & input[type=number]::-webkit-inner-spin-button, 
    & input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
`;

export const Button = styled.span`
    height: 35px;
    word-break: keep-all;
    padding: 0 10px;
    cursor: pointer;
    flex: 1 1 auto;
    flex-basis: 0;
    min-width: 0;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.15;

    &:hover {
        color: #FFFFFF;
        background-color: #00437E;
        border-color: white;
        ${(props) => (props.isVisible === false && 'color: #C6C6C6')};
        ${(props) => (props.isVisible === false && 'background-color: #F7F7F7')};
    }
`;

export const MinusButton = styled(Button)``;

export const PlusButton = styled(Button)``;

export const StyledPriceMain = styled(PriceMain)`
    font-size: 50px;
    justify-items: end;
    max-width: 100%;

    @media (max-width: 575.98px) {
        font-size: 30px;
    }
`;

export const Summary = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    flex-basis: 0;
    color: #00437E;
    font-size: 1.2em;
    font-weight: 700;
    justify-content: flex-end;
    height: 100%;
`;

export const SumAndQuantity = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    justify-content: center;
    max-width: 100%;
`;

export const Sum = styled.p`
    font-weight: 700;
    color: #00437E;
    
    ${(props) => (props.isOld
        ? 'color: #B0B0B0; font-size: 0.8em; padding-left: 22px;'
        : '')
}
`;

export const RemoveButton = styled.button`
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    background-color: #00437E;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    margin: auto;
    font-weight: 400;

    &:hover {
        color: #00437E;
        background-color: #FFFFFF;
    }

    &:focus {
        outline: #000000 1px solid;
    }

    @media (max-width: 767.98px) {
        margin: 0;
        margin-left: auto;
    }
`;

export const ValuePriceContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DiscountText = styled.div`
    font-size: 20px;
    line-height: 1.1;
    font-weight: 700;
    text-align: center;

    @media (max-width: 575.98px) {
        font-size: 16px;
    }
`;

export const StyledPrice = styled(PriceMain)`
    @media (max-width: 575.98px) {
        font-size: 16px;
    }
`;
