import React, { useState, useEffect, useContext } from 'react';
import CategoriesBar from '../../CategoriesBar/CategoriesBar';
import { getNoAuth } from '../../../api/Api';

import Container from '../../Container/Container';
import ProductsList from '../../ProductsList/ProductsList';
import EmptyProductList from '../../ProductsList/EmptyProductList';
import OrderFreeTestForm from '../../OrderFreeTestForm/OrderFreeTestForm';
import AppContext from '../../../context/app-context';
import MetaTags from '../../MetaTags/MetaTags';

export default function Courses({ match }) {
    const [categories, setCategories] = useState([]);
    const language = match.params.lang || 'pl';
    const appContext = useContext(AppContext);
    const { setGoogleData } = appContext;

    useEffect(() => { setGoogleData({ ecomm_pagetype: 'category' }); }, [setGoogleData]);

    useEffect(() => {
        getNoAuth('categories?sortBy=position&subpage=1').then((categories) => {
            const filtered = categories.filter((category) => category.products.length > 0);
            filtered.push({
                color: '#ff6600',
                text_color: 'white',
                id: 'order-test',
                translations: [{ name: 'Bezpłatny test robota', language: 'pl' }],
                products: [],
                onlyNav: true,
            });

            return setCategories(filtered);
        }).catch(() => {
            setCategories([]);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => window.fbq('track', 'PageView'), []);

    return (
        <>
            <MetaTags title="Szkolenia EduSense" />
            <Container className="bg-none">
                <CategoriesBar categories={categories} language={language} />
                {categories.length > 0
                    ? <ProductsList categories={categories} language={language} subpage={1} />
                    : <EmptyProductList />}
            </Container>
            <OrderFreeTestForm />
        </>
    );
}
