import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    query: '',
    products: [],
    events: [],
    areResultsVisible: false,
    status: 'initial',
};

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setQuery: (state, { payload: query }) => {
            state.query = query;
        },
        setProducts: (state, { payload: products }) => {
            state.products = products;
        },
        fetchSearch: (state) => {
            state.status = 'loading';
        },
        fetchSearchSuccess: (state, { payload }) => {
            state.status = 'success';
            state.products = payload.products;
            state.events = payload.events;
        },
        fetchSearchError: (state) => {
            state.status = 'error';
            state.products = [];
            state.events = [];
        },
        setEvents: (state, { payload: events }) => {
            state.events = events;
        },
        showResults: (state) => {
            state.areResultsVisible = true;
        },
        hideResults: (state) => {
            state.areResultsVisible = false;
        },
        resetState: () => initialState,
    },
});

export const {
    setQuery,
    setProducts,
    setEvents,
    showResults,
    hideResults,
    resetState,
    fetchSearch,
    fetchSearchSuccess,
    fetchSearchError,
} = searchSlice.actions;

export const selectSearchState = (state) => state.search;
export const selectQuery = (state) => selectSearchState(state).query;
export const selectAreResultsVisible = (state) => selectSearchState(state).areResultsVisible;
export const selectIsSearchActive = (state) => selectQuery(state).length > 0 && selectAreResultsVisible(state);

export default searchSlice.reducer;
