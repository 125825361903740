import React, { useState, useEffect } from 'react';
import useCart from '../hook/useCart';
import {
    Description,
    Image,
    Item,
    MinusButton,
    Name,
    PlusButton,
    PreorderText,
    PricePerItemColumn,
    Quantity,
    QuantityColumn,
    RemoveButton,
    StockText,
    PriceWrapper,
    DescriptionColumn,
    RemoveButtonColumn,
    ValueColumn,
    StyledPriceMain,
    ValuePriceContainer,
    DiscountText,
    DiscountColumn,
    DetailsColumn,
    StyledPrice,
    MobileRemoveButtonColumn,
    MobilePriceWrapper,
} from './CartItemStyles';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import MaxQuantityModal from '../../MaxQuantityModal/MaxQuantityModal';

export default function CartItem({
    item,
    decreaseClickHandler,
    increaseClickHandler,
    removeClickHandler,
    handleQuantityChange,
    readOnly = false,
    productDiscount = item.priceAfterDiscount,
    displayDiscount,
}) {
    const { loadDiscount } = useCart();
    const [quantity, setQuantity] = useState(item.quantity);
    const [removeModalShow, setRemoveModalShow] = useState(false);
    const [maxQuantityModalShow, setMaxQuantityModalShow] = useState(false);

    useEffect(() => {
        const parsedQuantity = parseInt(quantity, 10);
        if (parsedQuantity > item.maxQuantityPerOrder) {
            setQuantity(item.maxQuantityPerOrder);
            setMaxQuantityModalShow(true);
        }
        if (parsedQuantity <= 0) {
            setQuantity(1);
        }
    }, [quantity, item]);

    useEffect(() => setQuantity(item.quantity), [item.quantity]);

    const isDiscountNotPercentage = () => loadDiscount()?.products
        .filter((product) => product.product.id === item.id)[0]?.is_percentage === false;

    const getDiscountText = () => {
        if (+productDiscount === +item.price) {
            return '';
        }

        if (isDiscountNotPercentage()) {
            return `${(item.price - productDiscount) * item.quantity}zł`;
        }

        return `${Math.round((productDiscount / item.price) * 100 - 100)}%`;
    };

    const handleQuantityBlur = (event) => {
        let value = event.target.value.trim();
        if (value === '') value = 1;
        let parsedValue = parseInt(value, 10);
        if (parsedValue <= 0) parsedValue = 1;
        item.quantity = parsedValue;
        setQuantity(parsedValue);
        handleQuantityChange(item);
    };

    const handleQuantityChangeInput = (event) => {
        const { value } = event.target;
        setQuantity(value);
        if (value === '') return;
        let parsedValue = parseInt(value, 10);
        if (parsedValue <= 0) parsedValue = 1;
        item.quantity = parsedValue;
        setQuantity(parsedValue);
        handleQuantityChange(item);
    };

    const isAvailable = item.isVisible && item.isBuyable;

    return (
        <Item id={item.id} readOnly={readOnly} showDiscount={productDiscount}>
            <DescriptionColumn>
                <Description to={item.url}>
                    <div>
                        <Image
                            isVisible={isAvailable}
                            src={item.image && (process.env.REACT_APP_CDN_URL + item.image.trim())}
                        />
                    </div>
                    <Name isVisible={isAvailable}>
                        <span>{item.name}</span>
                        {item.preorderText && isAvailable
                            && <PreorderText>{item.preorderText}</PreorderText>}
                        {(item.stockText || isAvailable === false)
                            && <StockText>{isAvailable ? item.stockText : 'PRODUKT NIEDOSTĘPNY'}</StockText>}
                    </Name>
                </Description>
                {!readOnly && (
                    <MobileRemoveButtonColumn>
                        <RemoveButton type="button" onClick={() => setRemoveModalShow(true)}>
                            <i className="fa fa-times" aria-hidden="true" />
                        </RemoveButton>
                    </MobileRemoveButtonColumn>
                )}
            </DescriptionColumn>
            <DetailsColumn isVisible={isAvailable}>
                <PricePerItemColumn>
                    <PriceWrapper>
                        <StyledPrice price={item.price} />
                    </PriceWrapper>
                    {item.priceBefore > 0
                        && (
                            <PriceWrapper isOld>
                                <StyledPrice price={item.priceBefore} isOld />
                            </PriceWrapper>
                        )}
                </PricePerItemColumn>
                <QuantityColumn>
                    {readOnly ? (
                        <Quantity readOnly={readOnly}>
                            <p>
                                {quantity}
                                &nbsp;szt
                            </p>
                        </Quantity>
                    )
                        : (
                            <Quantity>
                                <MinusButton
                                    onClick={(e) => {
                                        if (isAvailable) {
                                            decreaseClickHandler(item, e);
                                        }
                                    }}
                                    isVisible={isAvailable}
                                >
                                    -
                                </MinusButton>
                                <input
                                    type="number"
                                    value={quantity}
                                    onBlur={handleQuantityBlur}
                                    onChange={handleQuantityChangeInput}
                                    disabled={!isAvailable}
                                />
                                <PlusButton
                                    onClick={(e) => {
                                        if (isAvailable) {
                                            increaseClickHandler(item, e);
                                        }
                                        if (quantity === item.maxQuantityPerOrder) {
                                            setMaxQuantityModalShow(true);
                                        }
                                    }}
                                    isVisible={isAvailable}
                                >
                                    +
                                </PlusButton>
                            </Quantity>
                        )}
                </QuantityColumn>
                {productDiscount && displayDiscount && (
                    <DiscountColumn>
                        <DiscountText>
                            {getDiscountText()}
                        </DiscountText>
                    </DiscountColumn>
                )}
                <ValueColumn>
                    <ValuePriceContainer>
                        <PriceWrapper>
                            <StyledPriceMain price={parseFloat((productDiscount || item.price) * item.quantity)} />
                            {item.priceBefore > 0
                                && (
                                    <PriceWrapper isOld>
                                        <StyledPrice price={item.priceBefore * quantity} isOld />
                                    </PriceWrapper>
                                )}
                        </PriceWrapper>
                    </ValuePriceContainer>
                </ValueColumn>
                {!readOnly && (
                    <RemoveButtonColumn>
                        <RemoveButton type="button" onClick={() => setRemoveModalShow(true)}>
                            <i className="fa fa-times" aria-hidden="true" />
                        </RemoveButton>
                    </RemoveButtonColumn>
                )}
            </DetailsColumn>
            {quantity > 1 && (
                <MobilePriceWrapper>
                    za sztukę&nbsp;
                    <StyledPrice price={item.price} />
                </MobilePriceWrapper>
            )}
            {!readOnly && (
                <ConfirmationModal
                    show={removeModalShow}
                    onHide={() => setRemoveModalShow(false)}
                    onConfirm={(e) => {
                        removeClickHandler(item, e);
                        setRemoveModalShow(false);
                    }}
                />
            )}
            {!readOnly && (
                <MaxQuantityModal
                    show={maxQuantityModalShow}
                    onHide={() => setMaxQuantityModalShow(false)}
                />
            )}
        </Item>
    );
}
