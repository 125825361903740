import React, { useContext, useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { FaqWrapper } from './FaqStyles';
import FaqElement from './FaqElement';
import Searchbar from '../../Searchbar/Searchbar';
import Container from '../../Container/Container';
import AppContext from '../../../context/app-context';
import faqList from './faqList';

export default function Faq() {
    const appContext = useContext(AppContext);
    const { setGoogleData } = appContext;
    useEffect(() => {
        setGoogleData({ ecomm_pagetype: 'other' });
    }, [setGoogleData]);
    useEffect(() => window.fbq('track', 'PageView'), []);

    const [openedElement, setOpenedElement] = useState(null);
    const [searchPhrase, setSearchPhrase] = useState('');

    return (
        <Container>
            <Searchbar setSearchPhrase={setSearchPhrase} />
            <Accordion as={FaqWrapper} defaultActiveKey="0">
                {faqList.map((e, i) => (
                    <FaqElement
                        searchPhrase={searchPhrase.trim().toLowerCase()}
                        openedElement={openedElement}
                        setOpenedElement={setOpenedElement}
                        data-parent={null}
                        key={i}
                        index={i}
                        title={e.title}
                        content={e.content}
                    />
                ))}
            </Accordion>
        </Container>
    );
}
