import styled from 'styled-components';
import {
    Row as BootstrapRow,
    Col,
} from 'react-bootstrap';
import ButtonAddToCart from '../ButtonAddToCart/ButtonAddToCart';
import PriceMain from '../PriceMain/PriceMain';
import { StyledPriceMain as PriceMainStyles, PriceMainCurrency } from '../PriceMain/PriceMainStyles';

export const Row = styled(BootstrapRow)`
    margin: 140px 10px 30px;
    margin-bottom: 0;
    background-color: #F0F0F0;
    display: flex;
    justify-content: flex-end;
    
    &.black-week {
        color: black;
    } 
    
    @media screen and (max-width: 900px) {
        flex-direction: column;
        display: block;
    }    
`;

export const Header = styled.div`
    color: #00437E;
    font-size: 2.1em;
    font-weight: 700;
    line-height: 1;
`;

export const Description = styled.div`
    padding-top: 20px;
    color: #00437E;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 17px;
    line-height: 1.3;
`;

export const MoreLink = styled.a`
    text-decoration: none;
    font-weight: 800;
    color: #048bff;

    &:hover{
        color: #00437E;
    }
`;

export const Column = styled(Col)`
    padding: 50px 54px 50px 15px;
    text-align: left;
    
    &.black-week ${Header} {
        color: black;
    }
    &.black-week ${Description} {
        color: black;
    }
    &.black-week ${MoreLink} {
        color: black;
    }
    &.black-week ${MoreLink}:hover {
        color: black;
        text-decoration: underline;
    }
    
    &.christmas ${Header} {
        color: black;
    }
    &.christmas ${Description} {
        color: black;
    }
    &.christmas ${MoreLink} {
        color: black;
    }
    &.christmas ${MoreLink}:hover {
        color: black;
        text-decoration: underline;
    }    
    
    @media screen and (max-width: 900px) {
        max-width: 100%;
        padding: 20px; 
    }  
`;

export const AddToCart = styled(ButtonAddToCart)`
    font-size: 30px;
    width: auto;
    padding: 24px 48px;
    background-color: #00AFFF;
    
    &:hover{
        color: #00AFFF;
    }

    @media screen and (max-width: 900px) {
        margin-top: 30px;
    }
`;

export const RightColumn = styled(Column)`
    position: relative;
    background-color: #00437E;
    padding: 50px 40px 50px;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    align-items: center;
    flex-grow: 0;
    
    &.black-week {
        background-color: black;
    }
    
    &.christmas ${AddToCart}  {
        background-color: #008626 !important;
    }
    &.christmas ${AddToCart}:hover {
        color: #008626;
        background-color: white !important;
    }
    
    @media screen and (max-width: 900px) {
        padding-top: 50px;
    }  
    
    &.empty {
        background-image: linear-gradient(90deg, #e9e8e8, #a9a9a9);
        background-size: 400% 400%;
        background-position: 0 0;
        background-repeat: no-repeat;
     
        animation: move 1s ease infinite;
        
        @keyframes move {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
    } 
`;

export const LeftColumn = styled(Column)`
    padding: 0 20px 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;  
`;

export const Image = styled.img`
    width: 100%;
    max-width: 400px;
    
    &.empty {
        width: 70%;
        height: 60%;
        background-image: linear-gradient(90deg, #e9e8e8, #a9a9a9);
        background-size: 400% 400%;
        background-position: 0 0;
        background-repeat: no-repeat;
     
        animation: move 1s ease infinite;
        
        @keyframes move {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
    }
`;

export const AdditionalText = styled.p`
    margin: 0;
    font-size: 1.1rem;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    width: 100%;
    white-space: initial;
    color: #00AFFF;
    margin-bottom: 30px;
`;

export const PreorderText = styled(AdditionalText)`
    color: #00AFFF;
`;

export const StockText = styled(AdditionalText)`
    color: #F50303;
`;

export const PriceContainer = styled.div`
    line-height: 1;
`;

export const PriceStyled = styled(PriceMain)`
    font-size: 24px;
    color: white;
    font-weight: 700;
    text-align: center;
`;

export const StyledPriceMain = styled(PriceMain)`
    ${PriceMainStyles}{
        font-size: 75px;
    }

    ${PriceMainCurrency}{
        font-size: 22px;
        padding: 5px 0;
    }

    color: white;
    font-weight: 700;
    justify-items: end;
    margin-top: 30px;
    margin-bottom: 25px;
`;
