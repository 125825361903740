import { Col, Row as BootstrapRow } from 'react-bootstrap';
import styled from 'styled-components';

export const Row = styled(BootstrapRow)`
    padding: 10px;
    color: #00437E;
    font-size: 1.1rem;
    align-items: flex-start;
    margin: 10px;
    font-size: 1.25rem;
    
    h2, h3 {
        font-size: 2.25rem;
        font-weight: 700;
    }
    
    h4 {
        font-weight: 700;    
    }
    
    p {
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        font-size: 17px;
        margin-bottom: 1rem; 
        line-height: 1.2;
    }
    
    a {
        line-height: 1.3;
        font-weight: 400;
        font-family: 'Roboto Condensed';
        font-size: 18px;
    }
    
    ul, ol {
        padding-left: 20px;
        font-weight: 400;
        font-family: 'Roboto Condensed';
        font-size: 19px;
        line-height: 1.4;
    }
`;

export const Description = styled(Col)`
    padding: 25px 65px 25px 25px;
    flex: 3 1 auto;
    flex-basis: 0;
    
    @media screen and (max-width: 900px) {
        padding: 25px 25px 25px 25px;
    }
    
    a {
        font-weight: 400;
        font-family: 'Roboto Condensed';
        font-size: 17px;
        line-height: 1.2;
    }

    ul, ol {
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        font-size: 17px;
        margin-bottom: 1rem; 
        line-height: 1.2;
    }

    &.empty span {
        margin: 2px 0;
        background-image: linear-gradient(90deg, #e9e8e8, #a9a9a9);
        background-size: 400% 400%;
        background-position: 0 0;
        background-repeat: no-repeat;
     
        animation: move 1s ease infinite;
        
        @keyframes move {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
    }
`;

export const RightColumn = styled(Col)`
    padding: 41.66667px 25px 25px 25px;
    background-color: #F0F0F0;
    flex: 1 1 auto;
    flex-basis: 0;
    min-width: 0;
    
    @media screen and (max-width: 900px) {
        flex-basis: auto;
    }
    
    &.empty span {
        margin: 2px 0;
        background-image: linear-gradient(90deg, #e9e8e8, #a9a9a9);
        background-size: 400% 400%;
        background-position: 0 0;
        background-repeat: no-repeat;
     
        animation: move 1s ease infinite;
        
        @keyframes move {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
    }
`;
