import React from 'react';
import {
    FormControl,
    FormGroup,
    FormLabel
} from "./TextAreaStyles";

export default function TextArea({id, label, onChange, disabled = false}) {
    return (
        <FormGroup controlId={id}>
            <FormLabel>{label}</FormLabel>
            <FormControl
                onChange={e => onChange && onChange(e.currentTarget.value)}
                disabled={disabled}
                as="textarea"
                maxLength={500}
                type="text"
            />
        </FormGroup>
    );
}
