import styled from "styled-components";
import Container from '../../Container/Container';

export const WarrantyWrapper = styled(Container)`
    text-align: left;
    font-size: 1.1rem;
    font-weight: 400;
    padding-left: 5px;
    padding-right: 10px;

    a { font-weight: bold }
`;