import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

export const RowStyled = styled(Row)`
    margin: 0;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0rem;
    font-size: 100%;
    line-height: 2;
    width: calc(100% + 1px);
    height: 2.7rem;
    max-height: 2.7rem;
    overflow: hidden;
    
    @media (max-width: 690px) {
        font-size: 70%;
        line-height: 2.2rem !important;
    }
`;
export const Wrapper = styled.div`
    padding: 0;
    left: 0;
    margin: -1px;
`;

export const LeftPart = styled(Col)`
    background-color: ${(props) => props.backgroundcolor};
    color: ${(props) => props.color};
    font-weight: bold;
    padding: 0.3rem 0 0.3rem 0;
`;

export const RightPart = styled(Col)`
    overflow: hidden;
    position: relative;
    display: flex;
    background-color: #fff;
    color: #000;
    font-size: 100%; 
    
    & a{
        color: #000;
        margin: 0 1rem;
    }
    & a:hover{
        text-decoration: underline;
     }
        
    & .rightInside{
        padding: 0.5rem 1rem;
        display: inline-block;
        line-height: 1.5; 
        white-space: nowrap;
        padding-right: 100%;
        box-sizing: content-box;
        
        @media (max-width: 690px) {
            line-height: 1.7rem !important;
        }
    
        -webkit-animation-iteration-count: infinite; 
            animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
        -webkit-animation-name: ticker;
           animation-name: ticker;
        -webkit-animation-duration: 70s;
            animation-duration: 70s;
   }

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

`;
