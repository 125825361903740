import React, {useContext, useEffect} from 'react';
import {RegulationsWrapper} from './RegulationsStyles';
import AppContext from "../../../context/app-context";

export default function Regulations() {
    const appContext = useContext(AppContext);
    const {setGoogleData} = appContext;
    useEffect(() => {
        setGoogleData({'ecomm_pagetype': 'other'})
    }, [setGoogleData]);
    useEffect(() => window.fbq('track', 'PageView'), []);

    return (
        <RegulationsWrapper className="font-dark-blue">
            <div className="font-weight-bold h1 mt-5 font-dark-blue text-left">REGULAMIN</div>
            <ol>
                <li>Sklep internetowy EDU-SENSE.pl jest prowadzony pod adresem <a
                    href="https://edu-sense.com/pl/koszyk">edu-sense.com/pl/koszyk</a> przez:
                </li>
                <li>EduSense SA z siedzibą w Lublinie, ul. B. Dobrzańskiego 3, 20-262 Lublin (dalej: „Spółka”) 1.
                    wpisany do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy Lublin-Wschód w
                    Lublinie z Siedzibą w Świdniku, VI Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS:
                    0000794442, NIP: 9462657265, REGON: 363648719, kapitał zakładowy 1.218.000,00 zł, numer tel.
                    przedsiębiorstwa +48 585 055 914 e:mail <a
                        href="mailto:kontakt@edu-sense.pl">kontakt@edu-sense.pl</a></li>
            </ol>
            <div className="font-weight-bold h2 mt-5 font-dark-blue text-left">WYJAŚNIENIE POJĘĆ</div>
            <ol>
                <li>Sklep Internetowy EDU-SENSE.pl – prowadzony przez Spółkę sklep internetowy, dostępny pod domeną
                    internetową <a href="https://edu-sense.com/pl/koszyk">edu-sense.com/pl/koszyk</a></li>
                <li>Produkty i Usługi oferowane w Sklepie Internetowym dostępne są na podstronie&nbsp;<a
                    href="https://edu-sense.com/pl/produkty">Produkty</a> oraz <a
                    href="https://edu-sense.com/pl/szkolenia">Szkolenia</a>.
                </li>
                <li>Klient – osoba fizyczna posiadająca pełną lub ograniczoną zdolność do czynności prawnych, osoba
                    prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, ale posiadająca zdolność
                    prawną, korzystająca ze Sklepu Internetowego EDU-SENSE.pl.
                </li>
                <li>Koszyk – funkcjonalność Sklepu Internetowego EDU-SENSE.pl, w której widoczne są wybrane przez
                    Klienta Produkty lub Usługi do zakupu, a także ustalenie i modyfikację danych zamówienia, w
                    szczególności: ilość produktów/usług, adres dostawy, dane do faktury, sposób dostawy, formy
                    płatności.
                </li>
                <li>Podmiot realizujący płatność – zewnętrzny w stosunku do Spółki podmiot, pośredniczący przy
                    realizacji płatności za pomocą przelewu bankowego lub płatności kartą płatniczą, kartą kredytową.
                </li>
            </ol>
            <div className="font-weight-bold h3 mt-5 font-dark-blue text-left">§1 Postanowienia ogólne</div>
            <ol>
                <li>Niniejszy regulamin określa zasady korzystania ze Sklepu Internetowego EDU-SENSE.pl, składania
                    zamówień na produkty lub usługi dostępne w Sklepie Internetowym EDU-SENSE.pl, dostarczania
                    zamówionych produktów lub usług Klientowi, uiszczania przez Klienta ceny sprzedaży produktów lub
                    usług, uprawnienia Klienta do anulowania zamówienia i odstąpienia od umowy oraz zasady składania i
                    rozpatrywania reklamacji.
                </li>
                <li>Spółka w najszerszym dopuszczalnym przez prawo zakresie nie ponosi odpowiedzialności za zakłócenia,
                    w tym przerwy, w funkcjonowaniu Sklepu Internetowego EDU-SENSE.pl spowodowane siłą wyższą,
                    niedozwolonym działaniem osób trzecich lub niekompatybilność Sklepu Internetowego EDU-SENSE.pl z
                    infrastrukturą techniczną Klienta.
                </li>
                <li>Przeglądanie asortymentu Sklepu Internetowego EDU-SENSE.pl nie wymaga rejestracji. Samodzielne
                    składanie zamówień przez Klienta na produkty lub usługi znajdujące się w asortymencie Sklepu
                    Internetowego EDU-SENSE.pl wymaga zarejestrowania się zgodnie z postanowieniami § 2 regulaminu.
                </li>
                <li>Sprzedaż i dostawy asortymentu Sklep Internetowego EDU-SENSE.pl mogą odbywać się wyłącznie na
                    terytorium Polski.
                </li>
            </ol>
            <div className="font-weight-bold h3 mt-5 font-dark-blue text-left">§2 Zasady korzystania ze Sklepu
                Internetowego EDUSENSE
            </div>
            <ol>
                <li>Klient składa zamówienie w Sklepie Internetowym EDU-SENSE.pl poprzez wypełnienie formularza, a
                    następnie dokonując akceptacji regulaminu Sklepu Internetowego EDU-SENSE.pl oraz wyrażając zgodę na
                    przetwarzanie danych osobowych w zakresie niezbędnym do nawiązania, ukształtowania treści, zmiany,
                    rozwiązania i prawidłowej realizacji usług świadczonych drogą elektroniczną oraz do realizacji
                    zamówień Klienta.
                </li>
                <li>Na stronie <a href="https://edu-sense.com/pl/koszyk">edu-sense.com/pl/koszyk</a> umowy zawierane są
                    w języku polskim. Stroną umowy jest spółka EduSense SA, której dane adresowe zostały podane
                    na wstępie do Regulaminu.
                </li>
                <li>Wszelkie nieprawidłowości w działaniu Sklepu Internetowego EDU-SENSE.pl mogą być zgłaszane Spółce za
                    pośrednictwem adresu mailowego: sklep@edu-sense.pl Reklamacje dotyczące produktów mogą być zgłaszane
                    stosownie do postanowień § 8 regulaminu.
                </li>
                <li>Zgłoszenia, o których mowa w ust. 3 niniejszego paragrafu, będą rozpatrywane w terminie 14 dni od
                    dnia zgłoszenia.
                </li>
            </ol>
            <div className="font-weight-bold h3 mt-5 font-dark-blue text-left">§3 Informacje o produktach i usługach
            </div>
            <ol>
                <li>Informacje o produktach i usługach dostępnych w asortymencie Sklepu Internetowego EDU-SENSE.pl
                    stanowią zaproszenie do zawarcia umowy w rozumieniu art. 71 Kodeksu cywilnego.
                </li>
                <li>Ceny produktów i usług wskazanych na stronach internetowych Sklepu Internetowego EDU-SENSE.pl są
                    cenami końcowymi to znaczy, iż zawierają podatek VAT i podawane są w złotych polskich. Obowiązują
                    ceny z chwili dokonania zamówienia.
                </li>
                <li>Spółka zastrzega sobie prawo do dokonywania na bieżąco zmian w cenach produktów i usług oraz do
                    przeprowadzania i odwoływania wszelkiego rodzaju akcji promocyjnych oraz wyprzedaży. Uprawnienie, o
                    którym mowa w poprzednim zdaniu, nie ma wpływu na zamówienia złożone przed datą wejścia w życie
                    zmiany ceny, warunków akcji promocyjnych lub wyprzedaży.
                </li>
            </ol>
            <div className="font-weight-bold h3 mt-5 font-dark-blue text-left">§4 Warunki realizacji zamówień</div>
            <ol>
                <li>Klient może składać zamówienia na produkty i usługi dostępne w asortymencie Sklepu Internetowego
                    EDU-SENSE.pl przez 7 (siedem) dni w tygodniu i 24 (dwadzieścia cztery) godziny na dobę.
                </li>
                <li>W celu złożenia zamówienia należy:
                    <ul>
                        <li>a) wybrać liczbę sztuk każdego produktu i / lub usługi będących przedmiotem zamówienia;</li>
                        <li>b) wybrać sposób dostawy (o ile dostępny jest więcej niż jeden) – dotyczy wyłącznie zakupu
                            produktów; usługi szkoleniowe i warsztatowe realizowane są w miejscu wskazanym w opisie, w tym w drodze on-line lub
                            na terenie obiektu wskazanego przez Klienta);
                        </li>
                        <li>c) wpisać dane odbiorcy zamówienia, wraz z numerem telefonu oraz adres, na który ma nastąpić
                            dostawa produktu;
                        </li>
                        <li>d) wpisać dane do faktury, jeśli są inne niż dane do odbioru zamówienia, jeżeli Klientowi ma
                            zostać wystawiona faktura VAT;
                        </li>
                        <li>e) kliknąć przycisk „Złóż zamówienie”;</li>
                    </ul></li>
                <li>Przed złożeniem zamówienia na usługę szkoleniową lub warsztatową realizowaną na terenie obiektu
                    wskazanego przez Klienta, należy ustalić ze Sklepem Internetowym EDU-SENSE.pl termin realizacji
                    zakupywanej usługi. Klient dokonuje ustalenia terminu mailowo na adres <a
                        href="mailto:kontakt@edu-sense.pl">kontakt@edu-sense.pl</a>. Ustalenia mailowe są wiążące dla
                    obu stron. Ich ewentualna zmiana jest możliwa wyłącznie przy zachowaniu formy pisemnej oraz użyciu
                    tych samych adresów mailowych, z wykorzystaniem których dokonano pierwotnego ustalenia termu
                    świadczenia usługi.
                </li>
                <li>Kliknięcie przycisku „złóż zamówienie”, a następnie jego opłacenie powoduje złożenie Spółce oferty
                    zawarcia umowy sprzedaży produktów znajdujących się w koszyku. Klient, po dokonaniu zamówienia,
                    otrzyma unikalny odnośnik (potwierdzenie zamówienia), który zawiera informację z podsumowaniem i
                    statusem zamówienia w Sklepie Internetowym EDU-SENSE.pl. Potwierdzenie zamówienia zostanie wysłane
                    na adres poczty elektronicznej Klienta wskazany przez Klienta w formularzu zamówienia.
                </li>
                <li>
                    W przypadku certyfikowanych kursów on-line po złożeniu zamówienia Klient otrzymuje oprócz potwierdzenia zamówienia także link aktywacyjny do kursu. Dostęp do kursu jest ważny przez jeden rok od dnia jego aktywacji.
                </li>
                <li>Zawarcie wiążącej umowy następuje wraz z otrzymaniem przez Klienta potwierdzenia zamówienia.
                    Potwierdzenie zamówienia jest oświadczeniem Spółki o przyjęciu oferty, o której mowa w ust. 4
                    powyżej.
                </li>
                <li>Zamówione produkty zostaną wysłane do Klienta po opłaceniu zamówienia oraz kosztów dostawy. Link aktywacyjny do kursu on-line zostanie wysłany do Klienta po opłaceniu zamówienia.</li>
                <li>W przypadku podania przez Klienta błędnych lub niedokładnych danych, w tym w szczególności błędnego
                    lub niedokładnego adresu, Spółka nie ponosi odpowiedzialności za niedostarczenie lub opóźnienie w
                    dostarczeniu przedmiotu zamówienia w najszerszym dopuszczalnym przez prawo zakresie.
                </li>
            </ol>
            <div className="font-weight-bold h3 mt-5 font-dark-blue text-left">§5 Modyfikacje zamówienia</div>
            <ol>
                <li>Klient może dokonywać zmian w zamówieniu aż do momentu:
                    <ul>
                        <li>a) w przypadku produktów: nadania przez Spółkę przesyłki z przedmiotem zamówienia do
                            Klienta,
                        </li>
                        <li>b) w przypadku usług: nie później niż na pięć dni roboczych przed datą świadczenia usługi.
                        </li>
                        <li>c) w przypadku certyfikowanych kursów on-line- do dnia otrzymania linka aktywacyjnego.</li>
                    </ul></li>
                <li>Zmiany mogą dotyczyć rezygnacji z całości bądź z części produktów lub usług składających się na
                    zamówienie, zmiany w adresie dostawy lub zmiany danych na fakturze. W przypadku rezygnacji z
                    zamówienia (zarówno w całości jak i w części), jeżeli zapłata została dokonana z góry, zwrot ceny
                    sprzedaży (odpowiedniej części ceny sprzedaży) nastąpi niezwłocznie, w terminie nie dłuższym niż 14
                    (czternaście) dni od dnia otrzymania rezygnacji Klienta przez Spółkę. Zasady zwrotu należności
                    Klientom zostały szczegółowo opisane w § 10 regulaminu.
                </li>
                <li>Zmian w zamówieniu można dokonywać kontaktując się z adresem sklep@edu-sense.pl</li>
                <li>Klient otrzyma potwierdzenie dokonania zmian lub anulowania zamówienia na adres poczty
                    elektronicznej wskazany przez Klienta w procesie składania zamówienia.
                </li>
            </ol>
            <div className="font-weight-bold h3 mt-5 font-dark-blue text-left">§6 Formy płatności i rozpoczęcie
                realizacji zamówienia
            </div>
            <p>Zapłatę należy dokonać przed wysłaniem przedmiotu zamówienia (płatność z góry) - przelewem przez podmiot
                realizujący płatności (TRANSFERUJ.PL) lub tradycyjnym przelewem bankowym. Realizacja zamówienia nastąpi
                po otrzymaniu przez Spółkę potwierdzenia poprawnego wykonania płatności przez podmiot realizujący
                płatności. Do czasu dokonania płatności złożone przez Klienta zamówienie na produkty znajdujące się w
                koszyku jest niewiążące. Wszelkie koszty transakcji finansowych ponosi Klient.</p>
            <div className="font-weight-bold h3 mt-5 font-dark-blue text-left">§7 Czas realizacji zamówień</div>
            <ol>
                <li>W przypadku produktów spółka zamieszcza na stronie internetowej Sklepu Internetowego EDUSENSE
                    przedstawiającej dany produkt informację o liczbie dni roboczych, tj. dni tygodnia od poniedziałku
                    do piątku z wyłączeniem dni ustawowo wolnych od pracy, w ciągu których nastąpi nadanie przesyłki z
                    przedmiotem zamówienia. Gdy takiej informacji brakuje realizacja następuje w ciągu 3 dni roboczych,
                    a w przypadku certyfikowanych kursów on-line- wysłanie linku aktywacyjnego do kursu następuje niezwłocznie po otrzymaniu przez Sklep Internetowy EDU-SENSE.pl potwierdzenia opłacenia przez Klienta zamówienia.
                    Powyższa informacja to orientacyjny czas liczony od momentu przyjęcia zamówienia do realizacji do
                    chwili wysłania przedmiotu zamówienia do Klienta za pośrednictwem firmy kurierskiej. Czas realizacji
                    zamówienia jest podawany z uwzględnieniem terminu skompletowania wszystkich zamówionych produktów.
                    Czas realizacji zamówienia uzależniony jest od dostępności danego produktu.
                </li>
                <li>Produkty składające się na przedmiot zamówienia mogą być dostarczone za pośrednictwem firmy
                    kurierskiej pod wskazany przez Klienta adres.
                </li>
                <li>
                    Link aktywacyjny do certyfikowanego kursu on-line wysyłany jest na adres poczty elektronicznej wskazany przez Klienta w zamówieniu.
                </li>
                <li>Spółka nie ponosi odpowiedzialności za wydłużony czas dostawy lub brak dostawy produktów z powodu
                    podania przez Klienta nieprawidłowych lub niepełnych danych, w tym nieprawidłowego lub niepełnego
                    adresu dostawy.
                </li>
                <li>W przypadku niedostępności produktów objętych zamówieniem w magazynie lub z innych przyczyn i braku
                    możliwości realizacji zamówienia Klienta, Sklep zawiadomi o tym fakcie Klienta najpóźniej w terminie
                    7 dni licząc od daty złożenia zamówienia przez Klienta i zwróci wpłacone pieniądze zgodnie z § 10
                    Regulaminu. Powiadomienie zostanie wysłane na adres e-mail wskazany przez Klienta w formularzu
                    zamówienia.
                </li>
                <li>W przypadku niedostępności części produktów objętych zamówieniem w magazynie lub z innych przyczyn
                    uniemożliwiających realizację zamówienia w czasie przewidzianym w tym zamówieniu, Klient zostanie
                    powiadomiony o stanie zamówienia z prośbą o decyzję o sposobie jego realizacji. W takiej sytuacji
                    Klientowi przysługuje prawo:
                    <ul>
                        <li>częściowej realizacji - wybór przez Klienta tej możliwości powoduje zrealizowanie zamówienia
                            dotyczącego wyłącznie dostępnych produktów, Sklep zostaje zwolniony z obowiązku realizacji
                            zamówienia na towary niedostępne;
                        </li>
                        <li>anulowania całości zamówienia - wybór przez Klienta tej możliwości powoduje zwolnienie
                            Sklepu z obowiązku realizacji zamówienia. Jeśli Klient dokonał zapłaty za towar - Sklep
                            zwróci należność zgodnie z procedurą opisaną w § 10 Regulaminu;
                        </li>
                    </ul></li>
                <li>Do sprzedaży promocyjnej oraz wyprzedaży przeznaczona jest ograniczona liczba produktów lub usług.
                    Realizacja zamówień następuje według kolejności wpływania potwierdzonych zamówień, aż do wyczerpania
                    się zapasów objętych tą formą sprzedaży w magazynie Sklepu.
                </li>
            </ol>
            <div className="font-weight-bold h3 mt-5 font-dark-blue text-left">§8 Warunki reklamacji</div>
            <ol>
                <li>Wszelkie reklamacje dotyczące produktów zakupionych w Sklepie, jak również co do przebiegu
                    realizacji zamówienia należy składać pod adresem: <a
                        href="mailto:sklep@edu-sense.pl">sklep@edu-sense.pl</a>.
                </li>
                <li>W niektórych przypadkach Spółka może poprosić o przesłanie zdjęć produktów podlegających
                    reklamacji.
                </li>
                <li>Reklamacja zostanie rozpatrzona w terminie 14 dni od daty otrzymania kompletnego zgłoszenia
                    reklamacyjnego. O ewentualnych brakach w zgłoszeniu reklamacyjnym Klient zostanie powiadomiony
                    niezwłoczne. Wraz z takim zawiadomieniem przesłana zostanie informacja, w jaki sposób należy
                    uzupełnić braki w zgłoszeniu reklamacyjnym.
                </li>
                <li>Klient nabywający produkt w celu związanym z działalnością zawodową lub gospodarczą (Klient nie
                    będący konsumentem) ma prawo złożyć reklamację stosownie do obowiązujących w tej mierze przepisów
                    prawa, przy czym odpowiedzialność Spółki z tytułu rękojmi za wady fizyczne produktów ogranicza się
                    do kwoty, jaką Klient zapłacił Spółce za nabycie danego produktu.
                </li>
                <li>Spółka informuje jednocześnie, iż przysługuje Klientom możliwość skorzystania z pozasądowych
                    sposobów rozpatrywania reklamacji i dochodzenia roszczeń zgodnie z procedurą Internetowego
                    Rozstrzygania Sporów opracowaną przez Komisję Europejską dostępną na stronie internetowej:
                    https://webgate.ec.europa.eu/odr/main/?event=main.home.show.
                </li>
            </ol>
            <div className="font-weight-bold h3 mt-5 font-dark-blue text-left">§9 Rezygnacja z zamówienia oraz
                odstąpienie od umowy
            </div>
            <ol>
                <li>Klient może zrezygnować z zamówienia, jeśli składające się na nie produkty nie zostały jeszcze
                    przygotowane do wysyłki, a w przypadku certyfikowanych kursów on-line, link aktywacyjny nie został jeszcze wysłany na adres skrzynki e-mail Klienta, lub jeśli do daty świadczenia zakupionej usługi pozostało co najmniej 5 dni
                    roboczych, kontaktując się na adres mailowy: <a
                        href="mailto:sklep@edu-sense.pl">sklep@edu-sense.pl</a></li>
                <li>W przypadku nabycia produktów w Sklepie Klient ma prawo do odstąpienia od umowy w ciągu czternastu
                    dni bez podania przyczyn. Termin na odstąpienie od umowy biegnie od dnia, w którym Klient lub
                    wskazana przez Klienta osoba trzecia niebędąca przewoźnikiem weszli/weszła w posiadanie produktu.
                </li>
                <li>
                    W przypadku certyfikowanych kursów on-line Klient traci prawo do odstąpienia od umowy, jeżeli przed upływem terminu,
                    o którym mowa w ust. 2 powyżej, użyje przesłanego na jego adres poczty elektronicznej linku aktywacyjnego w ten sposób,
                    że uruchomi kurs. Uruchomienie linku aktywacyjnego przez Klienta oznacza rozpoczęcie przez Sklep spełniania świadczenia
                    w postaci kursu on-line za wyraźną zgodą Klienta przed upływem terminu do odstąpienia od umowy.
                </li>
                <li>Aby skorzystać z prawa odstąpienia od umowy, Klient musi poinformować Sklep tj. spółkę EduSense SA
                    z siedzibą w Lublinie przy ul. B. Dobrzańskiego 3, 20-262 Lublin o swojej decyzji o odstąpieniu
                    od umowy w drodze jednoznacznego oświadczenia (na przykład pismo wysłane pocztą, faksem lub pocztą
                    elektroniczną). Oświadczenie należy wysłać na następujący adres: ul. Kieturakisa 10/14 80-741 Gdańsk
                    e-mail: <a href="mailto:sklep@edu-sense.pl">sklep@edu-sense.pl</a>.
                </li>
                <li>Klient może wypełnić i przesłać Spółce formularz odstąpienia od umowy albo sporządzić i przesłać
                    jakiekolwiek inne oświadczenie woli, także elektronicznie, przy użyciu formularza kontaktowego
                    znajdującego się na stronie internetowej Sklepu. Jeśli Klient skorzysta z tej możliwości, Spółka
                    prześle niezwłocznie (np. drogą mailową) potwierdzenie otrzymania oświadczenia Klienta. W celu
                    wykonania prawa do odstąpienia od umowy wystarczy wysłać powiadomienie o skorzystaniu z tego prawa
                    przed upływem terminu na odstąpienie od umowy.
                </li>
                <li>Aby zachować termin do odstąpienia od umowy, wystarczy, aby Klient wysłał informację dotyczącą
                    wykonania przysługującego mu prawa odstąpienia od umowy przed upływem terminu do odstąpienia od
                    umowy.
                </li>
                <li>W przypadku odstąpienia przez Klienta od umowy Spółka ma obowiązek niezwłocznie, jednak nie później
                    niż w ciągu czternastu dniu od dnia otrzymania powiadomienia o odstąpieniu od umowy, zwrócić
                    Klientowi wszystkie otrzymane od niego płatności, włącznie z kosztami dostawy (z wyjątkiem kosztów
                    dodatkowych, które ewentualnie wynikną w związku z wyborem przez Klienta innego sposobu dostawy niż
                    najtańszy zwykły oferowany przez nas sposób dostawy). Do zwrotu płatności stosujemy ten sam środek
                    płatności, którym posłużył się Klient podczas pierwotnej transakcji, chyba że jednoznacznie zostanie
                    z Klientem uzgodniony inny środek płatności. W każdym przypadku Klient nie poniesie żadnych opłat w
                    związku z tym zwrotem.
                </li>
                <li>Spółka może odmówić dokonania zwrotu płatności otrzymanej od Klienta do chwili otrzymania zwrotu
                    produktu lub do chwili, gdy Klient przedstawi dowód, że produkt został do Spółki wysłany, w
                    zależności od tego, które z tych zdarzeń nastąpi wcześniej.
                </li>
                <li>Klient ma obowiązek odesłać lub przekazać produkt niezwłocznie, jednak nie później niż czternaście
                    dni od dnia, w którym Klient poinformował o odstąpieniu od umowy. Termin uważa się za dochowany,
                    jeśli produkt zostanie wysłany przed upływem czternastu dni.
                </li>
                <li>Klient odpowiada za zmniejszenie wartości rzeczy będące wynikiem korzystania z niej w sposób
                    wykraczający poza konieczny do stwierdzenia charakteru, cech i funkcjonowania rzeczy.
                </li>
                <li>Wzór formularza odstąpienia od umowy</li>
                <li>W przypadku chęci odstąpienia od umowy, można wypełnić niniejszy formularz. Alternatywnie można
                    także odstąpić od umowy w sposób przedstawiony powyżej.
                    Adresat: EduSense SA z siedzibą w Gdańsku, ul. Kieturakisa 10/12, 80-741 Gdańsk
                </li>
            </ol>
            <p>Ja/My(<em>) niniejszym informuję/informujemy(</em>) o moim/naszym odstąpieniu od umowy sprzedaży
                następujących rzeczy(<em>)
                    Data zawarcia umowy(</em>)/odbioru(<em>)
                    Imię i nazwisko konsumenta(-ów)
                    Adres konsumenta(-ów)
                    Podpis konsumenta(-ów) (tylko jeżeli formularz jest przesyłany w wersji papierowej)
                    Data
                    (</em>) niepotrzebne skreślić</p>
            <div className="font-weight-bold h3 mt-5 font-dark-blue text-left">§10 Zwrot należności Klientom</div>
            <ol>
                <li>Spółka dokona zwrotu należności niezwłocznie, nie później niż w terminie 14 dni w przypadku:
                    <ul>
                        <li>a) anulowania zamówienia lub części zamówienia (w takim przypadku zwrotowi podlega
                            odpowiednia część ceny) opłaconego z góry przed jego realizacją;
                        </li>
                        <li>b) zwrotu produktu (odstąpienia od umowy) z zamówienia, które zostało dostarczone za
                            pośrednictwem firmy kurierskiej;
                        </li>
                        <li>c) uznania reklamacji i braku możliwości naprawy uszkodzonego produktu lub wymiany na
                            nowy.
                        </li>
                    </ul></li>
                <li>Spółka dokona zwrotu pieniędzy na rachunek bankowy Klienta (w tym powiązany z kartą kredytową lub
                    płatniczą Klienta) – jeśli zamówienie było opłacone z góry za pomocą przelewu lub karty kredytowej.
                </li>
            </ol>
            <div className="font-weight-bold h3 mt-5 font-dark-blue text-left">§11 Ustawowa odpowiedzialność za wady
                produktów
            </div>
            <p>Spółka zobowiązuje się do dostarczenia Klientowi produktów wolnych od wad. W przypadku wszelkich wad
                produktów zamówionych Spółka ponosi ustawową odpowiedzialność z tytułu rękojmi za wady, wynikającą z
                art. 556 i następnych ustawy z dnia 23 kwietnia 1964 r. - Kodeks cywilny (tekst jedn. Dz.U.2018 poz.
                1025 ze zm.).</p>
            <div className="font-weight-bold h3 mt-5 font-dark-blue text-left">§12 Dane osobowe</div>
            <ol>
                <li>Złożenie zamówienia w Sklepie Internetowym EDU-SENSE.pl jest jednoznaczne z akceptacją Regulaminu
                    Sklepu.
                </li>
                <li>Administratorem danych osobowych Klienta przekazanych w formularzu zamówienia jest EduSense SA
                    z siedzibą w Lublinie, ul. B. Dobrzańskiego 3, 20-262 Lublin zwany dalej: „Administratorem”.
                    Klient może skontaktować się z Administratorem pisząc na adres: <a
                        href="mailto:kontakt@edu-sense.pl">kontakt@edu-sense.pl</a> lub telefonując pod numer: + 48 517
                    608 085.
                </li>
                <li>Podstawą prawną przetwarzania danych Klienta jest umowa pomiędzy Klientem a Administratorem, zwana
                    dalej: „Umową”, do zawarcia której dochodzi wskutek akceptacji Regulaminu i dla wykonania której
                    przetwarzanie danych Klienta jest niezbędne.
                </li>
                <li>Dane osobowe Klienta przetwarzane są wyłącznie dla celów związanych z realizacją Umowy, w tym wystawienia certyfikatu za odbycie kursu on-line oraz do
                    podjęcia niezbędnych działań przed zawarciem Umowy (np. przyjmowanie zamówień, świadczenia usług,
                    obsługi zleceń i wniosków, rozpatrywania reklamacji); wypełnienia prawnych obowiązków ciążących na
                    administratorze danych (np. przechowywanie dokumentacji podatkowej); wynikających z prawnie
                    uzasadnionych interesów realizowanych przez Administratora (np. marketing bezpośredni własnych
                    produktów, zabezpieczenie i dochodzenie roszczeń, zabezpieczenie i ochrona przed roszczeniami ze
                    strony Klienta lub osób trzecich); marketingowych, nie wynikających z prawnie usprawiedliwionych
                    interesów realizowanych przez Administratora (np. marketing usług i produktów podmiotów trzecich,
                    marketing własny niebędący marketingiem bezpośrednim).
                </li>
                <li>Podstawami prawnymi przetwarzania danych są: Rozporządzenie Parlamentu Europejskiego i Rady (UE)
                    2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
                    danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                    (ogólne rozporządzenie o ochronie danych), w szczególności art. 6 tego rozporządzenia ustanawiający
                    ogólne zasady zgodności przetwarzania danych; ustawa prawo telekomunikacyjne – w szczególności dział
                    VII tej ustawy traktujący o tajemnicy telekomunikacyjnej i ochronie danych użytkowników końcowych;
                    zgoda – w sytuacjach gdy Administrator jest uprawniony lub zobowiązany do przetwarzania danych na
                    podstawie wyraźnej zgody i wynika to z wcześniej powołanych aktów prawnych.
                </li>
                <li>W przypadku przetwarzania danych na podstawie art. 6 ust. 1 lit f) ogólnego rozporządzenia o
                    ochronie danych (gdy przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych
                    interesów realizowanych przez Administratora lub przez stronę trzecią), Administrator informuje, że
                    prawnie uzasadnionymi interesami realizowanymi przez administratora są: marketing bezpośredni
                    własnych produktów; zabezpieczenie i dochodzenie swoich roszczeń oraz zabezpieczenie się i ochrona
                    przed roszczeniami ze strony Klienta.
                </li>
                <li>Zgodnie z definicją „odbiorcy”, umieszczoną w ogólnym rozporządzeniu o ochronie danych, wymienioną w
                    art. 4 pkt 9, Administrator informuje, że jego dane osobowe w trakcie ich przetwarzania mogą być
                    ujawniane następującym kategoriom odbiorców: Klientowi oraz osobom upoważnionym przez Klienta;
                    upoważnionym przez Administratora osobom zatrudnionym u Administratora; podmiotom przetwarzającym
                    dane na zlecenie i w imieniu Administratora oraz osobom upoważnionym, zatrudnionym w tych podmiotach
                    (np. windykacja należności i dochodzenie roszczeń w oparciu o usługi firm zewnętrznych, podmiotom
                    prowadzącym działalność płatniczą oraz pośredniczącym w płatnościach (banki, instytucje płatnicze),
                    podmiotą prowadzącym działalność pocztową lub kurierską); stronom trzecim - w przypadku korzystania
                    przez Administratora z przysługujących praw (np. w razie cesji wierzytelności wraz z przekazaniem
                    danych osobowych związanych z wierzytelnością); organy publiczne, które mogą otrzymywać dane w
                    przypadkach innych niż w ramach konkretnego postępowania prowadzonego zgodnie z prawem Unii lub
                    prawem polskim.
                </li>
                <li>Dane osobowe są przechowywane przez następujący okres:
                    <ul>
                        <li>a) w przypadku gromadzenia danych osobowych na potrzeby realizacji zamówienia lub zawarcia
                            Umowy (podstawa prawna: art. 6 ust. 1 lit. b RODO): od momentu zebrania danych przed
                            zawarciem umowy w celu jej zawarcia lub od momentu zebrania tych danych w trakcie zawierania
                            Umowy lub od momentu zebrania tych danych w trakcie trwania umowy (w razie uzupełnienia lub
                            aktualizacji danych w trakcie trwania Umowy) do czasu rozwiązania umowy lub realizacji umowy
                            po momencie jej rozwiązania (np. rozpatrywanie reklamacji);
                        </li>
                        <li>b) w przypadku gromadzenia danych osobowych w celu wypełniania obowiązków wynikających z
                            przepisów prawa lub w związku z realizacją zadań w interesie publicznym (podstawa prawna:
                            art. 6 ust. 1 lit. c) przez okres realizacji obowiązków i zadań wynikających z
                            poszczególnych przepisów prawa;
                        </li>
                        <li>c) w przypadku przetwarzania danych osobowych w celach wynikających z prawnie uzasadnionych
                            interesów Administratora (podstawa prawna: art. 6 ust. 1 lit. f) dane będą przechowywane nie
                            dłużej niż w okresie dziesięciu lat od momentu rozwiązania umowy lub do momentu wniesienia
                            uzasadnionego sprzeciwu wobec przetwarzania w takim celu;
                        </li>
                        <li>d) w przypadku gromadzenia danych osobowych na podstawie zgody (podstawa prawna: art. 6 ust.
                            1 lit. a lub f RODO): od momentu wyrażenia zgody na przetwarzanie danych nią objętych
                            (również w trakcie realizacji umowy) do momentu realizacji wniosku o wycofanie tej zgody, w
                            przypadku jej wycofania, lub do momentu odnotowania sprzeciwu;
                        </li>
                        <li>e) poza sytuacjami wymienionymi powyżej dane będą mogły być przechowywane w okresie
                            ograniczenia przetwarzania tych danych ustanowionego na wniosek Klienta, wniosek organu
                            nadzorczego – w sytuacjach przewidzianych przez RODO w art. 18 oraz art. 58.
                        </li>
                    </ul></li>
                <li>Administrator nie zamierza przekazywać danych Klienta do państwa trzeciego ani do organizacji
                    międzynarodowych.
                </li>
                <li>Klientowi przysługuje prawo żądania dostępu do treści jego danych osobowych, ich sprostowania,
                    usunięcia, ograniczenia przetwarzania. Dodatkowo posiadasz prawo wniesienia sprzeciwu wobec
                    przetwarzania danych oraz prawo do przenoszenia danych. Realizacja wymienionych w niniejszym
                    akapicie praw odbywa się zgodnie z przepisami ogólnego rozporządzenia o ochronie danych (RODO) – w
                    oparciu o definicje i mechanizmy opisane w tym rozporządzeniu.
                </li>
                <li>W przypadku gdy Administrator przetwarza dane osobowe na podstawie zgody Klienta, Klient ma prawo do
                    cofnięcia zgody w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania, którego dokonano
                    na podstawie zgody przed jej cofnięciem.
                </li>
                <li>Klient ma prawo wniesienia skargi do organu nadzorczego na zasadach określonych przez ogólne
                    rozporządzenie o ochronie danych, a w szczególności na podstawie art. 77 tego rozporządzenia. W
                    Polsce Organem nadzorczym od 25 maja 2018 r. jest Urząd Ochrony Danych Osobowych.
                </li>
                <li>Podanie danych osobowych nie jest obowiązkowe, jednakże ich niepodanie spowoduje, że zawarcie i
                    realizacja Umowy będą niemożliwe.
                </li>
                <li>W oparciu o dane osobowe Klienta Administrator nie będzie podejmował wobec Klienta zautomatyzowanych
                    decyzji, w tym decyzji będących wynikiem profilowania.
                </li>
                <li>Więcej informacja na temat przetwarzania danych osobowych przez Administratora znajdziesz w Polityce
                    prywatności znajdującej się pod następującym linkiem: https://edu-sense.com/pl/politykaprywatnosci
                </li>
            </ol>
            <div className="font-weight-bold h3 mt-5 font-dark-blue text-left">§13 Postanowienia końcowe</div>
            <ol>
                <li>Informacje o produktach i usługach zamieszczone na stronach Sklepu Internetowego EDUSENSE nie
                    stanowią oferty w rozumieniu kodeksu cywilnego.
                </li>
                <li>Spółka dokłada wszelkich starań, by świadczone usługi w ramach Sklepu Internetowego EDU-SENSE.pl
                    były na najwyższym poziomie, jednakże Spółka nie wyklucza możliwości czasowego zawieszenia
                    dostępności Sklepu Internetowego EDU-SENSE.pl w przypadku konieczności przeprowadzenia konserwacji,
                    przeglądu, wymiany sprzętu lub też w związku z koniecznością modernizacji lub rozbudowy Sklepu
                    Internetowego EDU-SENSE.pl.
                </li>
                <li>W najszerszym dopuszczalnym przez prawo zakresie Spółka nie ponosi odpowiedzialności za blokowanie
                    przez administratorów serwerów pocztowych przesyłania wiadomości na adres e-mail wskazany przez
                    Klienta oraz za usuwanie i blokowanie e-maili przez oprogramowanie zainstalowane na komputerze
                    używanym przez Klienta.
                </li>
                <li>Indywidualne ustawienia komputera mogą powodować różnice pomiędzy wizualizacją produktu na
                    komputerze Klienta a rzeczywistym wyglądem produktu (kolor, proporcje itp.).
                </li>
                <li>W sprawach nieuregulowanych w regulaminie mają zastosowanie przepisy prawa polskiego, a zwłaszcza
                    Kodeksu cywilnego oraz przepisy Ustawy z dnia 30 maja 2014 r. o&nbsp;prawach konsumenta (tekst jedn.
                    Dz. U. z 2017 r. poz. 683 ze zm.).
                </li>
                <li>Wszystkie nazwy produktów i usług zamieszczone na stronie Sklepu Internetowego EDUSENSE. używane są
                    w celach identyfikacyjnych i mogą być chronione i zastrzeżone na podstawie przepisów Ustawy z dnia
                    30 czerwca 2000 r. Prawo własności przemysłowej (tekst jedn. Dz. U. z 2017 r. poz. 776 ze zm.).
                </li>
                <li>Klient akceptując niniejszy regulamin zgadza się na zbieranie i udostępnianie Spółce statystyk
                    swojej obecności i aktywności w Sklepie Internetowym EDU-SENSE.pl. Dane są zbierane automatycznie i
                    mogą zostać wykorzystane do analizy aktywności Klientów. Więcej na ten temat w <a
                        href="https://edu-sense.com/pl/politykaprywatnosci">Polityce prywatności</a>.
                </li>
                <li>W celach statystycznych oraz w celu zapewnienia jak najwyższej jakości usług Sklep Internetowy
                    EDU-SENSE.pl. korzysta z informacji zapisywanych przez serwer na urządzeniu końcowym Klienta, które
                    następnie są odczytywane przy każdorazowym połączeniu się przeglądarki internetowej (tzw. cookies).
                    Klient może w dowolnym momencie zmienić ustawienia przeglądarki, by nie akceptowała takich plików
                    lub informowała o ich przesyłaniu. Należy jednak pamiętać, iż nieakceptowanie plików cookie może
                    spowodować utrudnienia w korzystaniu z Sklepu Internetowego EDU-SENSE.pl. Korzystanie przez Klienta
                    z przeglądarki, której ustawienia zezwalają na zapisywanie plików cookie na urządzeniu Klienta,
                    oznacza wyrażenie zgody na zapisywanie powyższych plików na tym urządzeniu Klienta. Więcej na ten
                    temat w <a href="https://edu-sense.com/pl/politykaprywatnosci">Polityce prywatności</a>.
                </li>
                <li>Ewentualne spory powstałe między Klientem niebędącym konsumentem w rozumieniu przepisów Kodeksu
                    cywilnego a Spółką rozstrzygane będą przez sąd powszechny miejscowo właściwy dla siedziby Spółki.
                </li>
                <li>Spółka zastrzega sobie prawo do zmiany regulaminu z ważnych przyczyn technicznych, prawnych lub
                    organizacyjnych. Zmiana regulaminu staje się skuteczna w terminie wskazanym przez Spółkę, nie
                    krótszym jednak niż 7 dni od momentu udostępnienia na stronie Sklepu Internetowego EDU-SENSE.pl.
                    zmienionego regulaminu. Zamówienia złożone przez Klientów przed wejściem w życie zmian regulaminu są
                    realizowane według dotychczasowych postanowień regulaminu.
                </li>
            </ol>
        </RegulationsWrapper>
    );
}
