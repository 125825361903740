import React from 'react';
import { Title, Wrapper, RowStyled } from './RowTitleStyles';
import { CategoryColor } from '../CategoriesBar/CategoriesBarStyles';

export default function RowTitle({
    className, color, text, textColor,
}) {
    return (
        <RowStyled className={`${className} w-100`}>
            <Wrapper>
                <CategoryColor color={color} />
                <Title textColor={textColor} color={color}>{text}</Title>
            </Wrapper>
        </RowStyled>
    );
}
