import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

export const ModalWrapper = styled(Modal)`
    & {
        max-width: 100%;
        padding-top: 1rem;
    }
    & .modal-tile-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
    & h6 span{
        text-transform: none;
    }
    & h4{
        font-size: 1.7rem;
    }
    & .font-light-blue, .font-light-blue span{ 
        color: #00AFFF !important;
    }
    & .tile{
        width: 8.5rem;
        height: 8.5rem;
        background-size: contain;
        padding-top: 1rem;
        flex-direction: column;
        max-width: 35vw;
        max-height: 35vw;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    & .tile-big{
        width: 17rem;
    }
    & .tile-kodowanie{
        background-size: 80%;
    }
    & .tile-programowac{
        line-height: 1.2;
    }
    & .modal-custom-close{
        position: absolute;
        right: 0;
        top: -1.5rem;
        cursor: pointer;
    }
`;
