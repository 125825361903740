import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppContext from '../../../context/app-context';
import { useQueryParameter } from '../../../tools/queryParameters';
import Container from '../../Container/Container';
import { SEARCH_PARAM_NAME } from '../../Search/useSearch';
import MetaTags from '../../MetaTags/MetaTags';
import EmptyProductList from '../../ProductsList/EmptyProductList';
import { ProductsWrapper, SearchHeader, SearchWrapper } from './SearchResultsStyles';
import SearchComponent from '../../Search/Search';
import { setQuery } from '../../Search/searchSlice';
import { fetchProducts, selectSearchResultsState } from './searchResultsSlice';
import Card from '../../ProductsList/Card/Card';
import useGetProductUrl from '../../ProductsList/useGetProductUrl';
import escapeDiacritics from '../../../tools/escapeDiacritics';

const SearchResults = () => {
    const appContext = useContext(AppContext);
    const language = appContext.lang;
    const { setGoogleData } = appContext;
    const query = useQueryParameter(SEARCH_PARAM_NAME);
    const dispatch = useDispatch();
    const { products, status } = useSelector(selectSearchResultsState);
    const numberOfProducts = products?.length;
    const getProductUrl = useGetProductUrl();
    const getCategoryFromProduct = (product) => product?.product_categories[0]?.category?.translations[0]?.name || '';
    useEffect(() => {
        setGoogleData({ ecomm_pagetype: 'search' });
    }, [setGoogleData]);

    useEffect(() => {
        dispatch(setQuery(query));
        dispatch(fetchProducts({ query }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => window.fbq('track', 'PageView'), []);

    useEffect(() => {
        const gtagItems = products?.map((item) => {
            if (item.translations.length <= 0) {
                return false;
            }

            const itemContent = item.translations.filter((item) => item.language === language)[0];
            return {
                id: item.sku,
                name: escapeDiacritics(itemContent.name),
                price: item.price,
                category: escapeDiacritics(getCategoryFromProduct(item)),
                quantity: 1,
            };
        });

        if (gtagItems) {
            window.gtag('event', 'view_item_list', {
                items: gtagItems.map((item, key) => {
                    item.list_position = key + 1;
                    return item;
                }),
            });
        }
    }, [products, language]);

    return (
        <Container className="bg-none">
            <MetaTags title="Produkty EduSense" />
            <SearchWrapper>
                <SearchComponent />
            </SearchWrapper>
            <SearchHeader>
                {['initial', 'loading', 'success'].includes(status) && numberOfProducts !== 0
                    ? `Wyniki wyszukiwania dla "${query}" ${numberOfProducts ? `(${numberOfProducts})` : ''}`
                    : 'Brak wyników wyszukiwania dla podanej frazy'}
            </SearchHeader>
            {['loading', 'initial'].includes(status) && <EmptyProductList subpage={3} />}
            <ProductsWrapper>
                {status === 'success' && (
                    products.map((item) => {
                        if (item.translations.length <= 0) {
                            return false;
                        }

                        return (
                            <Card
                                key={item.id}
                                product={item}
                                language={language}
                                isBlackWeek={item?.product_categories[0]?.category?.color === '#000000'}
                                isChristmas={
                                    escapeDiacritics(getCategoryFromProduct(item))
                                        .toLowerCase().startsWith('swiateczna promocja')
                                }
                                productUrl={getProductUrl(item?.product_categories[0]?.category?.subpage, item.slug)}
                            />
                        );
                    })
                )}
            </ProductsWrapper>
        </Container>
    );
};

export default SearchResults;
