import React, {useEffect} from "react";
import {
    useLocation,
    useHistory
} from "react-router-dom";

export default function Gtag() {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const gtag = window.gtag;

        if (history.action === 'PUSH' && typeof(gtag) === 'function') {
            gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, {
                'page_location': window.location.href,
                'page_path': location.pathname,
            });
        }
    }, [location, history]);

    return <></>;
};
