export default function OrderPayment(
    currency,
    type,
    invoiceName = null,
    invoiceAddress = null,
    invoicePostcode = null,
    invoiceCity = null,
    invoiceTaxId = null,
    invoiceRecipientMode = null,
    invoiceRecipientName = null,
    invoiceRecipientAddress = null,
    invoiceRecipientPostcode = null,
    invoiceRecipientCity = null,
    invoiceRecipientTaxId = null,
    returnUrl,
    returnErrorUrl,
) {
    this.currency = currency;
    this.invoice_name = invoiceName;
    this.invoice_address = invoiceAddress;
    this.invoice_postcode = invoicePostcode;
    this.invoice_city = invoiceCity;
    this.invoice_tax_id = invoiceTaxId;
    this.invoice_recipient_mode = invoiceRecipientMode;

    if (invoiceRecipientMode === 1) { // separate recipient data for invoice checked
        this.recipient_name = invoiceRecipientName;
        this.recipient_address = invoiceRecipientAddress;
        this.recipient_postcode = invoiceRecipientPostcode;
        this.recipient_city = invoiceRecipientCity;
        this.recipient_tax_id = invoiceRecipientTaxId;
    } else {
        this.recipient_name = null;
        this.recipient_address = null;
        this.recipient_postcode = null;
        this.recipient_city = null;
        this.recipient_tax_id = null;
    }

    this.payment_type = type;
    this.return_url = returnUrl;
    this.return_error_url = returnErrorUrl;

    Object.freeze(this);
}