import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ children, location }) => {
    const { hash, pathname } = location;

    useEffect(() => {
        if (!hash.startsWith('#')) {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 100);
        }
    }, [pathname, hash]);

    return children;
};

export default withRouter(ScrollToTop);
