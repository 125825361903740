import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const SearchWrapper = styled.div`
    position: fixed;
    top: 90px;
    margin: 16px 320px;
    z-index: 100;
    
    & form {
        width: 640px;
    }
    
    @media (max-width: 1280px) {
        margin: 16px calc((100vw - 640px - 15px)/2);
    }
    
    @media (max-width: 992px) {
        margin: 16px 2.5%;
        width: 95%;
        
        & form {
            width: 100%;
        }
    }
`;

export const SearchHeader = styled.h2`
    color: #00437E;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    margin: 110px 24px 50px;
    
    @media (max-width: 992px) {
        font-size: 24px;
    }
`;

export const ProductsWrapper = styled(Row)`
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;
