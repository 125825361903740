import React from 'react';
import {Modal as ModalBs} from 'react-bootstrap';
import {ModalWrapper} from './ModalStyles';

export default function Modal({isOpen, onHide}){
    const nav = document.querySelector('header > nav');
    if( !nav ){
        return null;
    }
    const width = 0.9 * nav.offsetWidth;
    return <ModalWrapper onClick={e => e.stopPropagation()} size="xl" show={isOpen} onHide={onHide}>
        <ModalBs.Header>
            <iframe title="Drewniane puzzle do Ozobota - Edu-Sense.pl" width={width} height={0.56 * width} src="https://www.youtube.com/embed/gV0ApxFO-Lk" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
            <button type="button" className="close" aria-label="Close"
                    onClick={onHide}>
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalBs.Header>
    </ModalWrapper>
}