import React, {useState} from 'react';
import {
    CarouselStyled,
    Icon, Indicator, IndicatorsContainer
} from "./CarouselStyle";

export default function Carousel({className, photos}) {
    const [index, setIndex] = useState(0);
    const [direction, setDirection] = useState(null);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
        setDirection(e?.direction);
    };

    const handleIndicatorClick = selectedIndex => {
        setIndex(selectedIndex);
    };

    return (
        <React.Fragment>
            <CarouselStyled
                activeIndex={index}
                direction={direction}
                onSelect={handleSelect}
                indicators={false}
                prevIcon={<Icon className="fa fa-chevron-left" aria-hidden="true" />}
                nextIcon={<Icon className="fa fa-chevron-right" aria-hidden="true" />}
            >
                {photos.map((photo, key) =>
                    <CarouselStyled.Item key={key}>
                        <img
                            className="d-block w-100"
                            src={photo}
                            alt="aaa"
                        />
                    </CarouselStyled.Item>
                )}
            </CarouselStyled>
            <IndicatorsContainer className="carousel-indicators">
                {photos.map((photo, key) =>
                    <Indicator
                        key={key}
                        onClick={() => handleIndicatorClick(key)}
                        className={key === index ? 'active' : ''}
                    >
                        <img className="d-block img-fluid" src={photo} alt={key} />
                    </Indicator>
                )}
            </IndicatorsContainer>
        </React.Fragment>
    );
}