import React from 'react';
import { Form } from 'react-bootstrap';
import {
    Header, PreorderInfo, StyledPreorder, StyledSplitCheckbox,
} from './styled';

const Preorder = ({
    data, setDataValue, cartTotalPrice, isSplitableItem,
}) => (
    <StyledPreorder>
        {cartTotalPrice >= 600 && isSplitableItem ? (
            <>
                <Header>
                    <i className="fa fa-truck" />
                    Podziel wysyłkę
                </Header>
                <StyledSplitCheckbox type="checkbox" id="split">
                    <Form.Check.Input
                        type="checkbox"
                        onChange={(e) => setDataValue('deliverySplit', e.target.checked)}
                        checked={data.deliverySplit}
                    />
                    <Form.Check.Label>
                        <span className="placeholder" />
                        <span style={{ display: 'inline-block', paddingRight: '30px' }} className="label">
                            Zaznacz, jeśli chcesz otrzymać wcześniej dostępne produkty.
                            Zamówienie zawiera produkt w przedsprzedaży.
                            Wysyłka produktu będącego w przedsprzedaży nastąpi w terminie wskazanym na stronie produktu.
                        </span>
                    </Form.Check.Label>
                </StyledSplitCheckbox>
            </>
        ) : (
            <Header>
                <i className="fa fa-truck" />
                <PreorderInfo>
                    Zamówienie zawiera produkt w przedsprzedaży.
                    Wysyłka nastąpi po zakończeniu przedsprzedaży w terminie wskazanym na stronie produktu.
                </PreorderInfo>
            </Header>
        )}
    </StyledPreorder>
);

export default Preorder;
