import React from 'react';
import { SearchbarWrapper } from "./SearchbarStyles";

export default function Searchbar({setSearchPhrase}) {
    return (
        <SearchbarWrapper>
            <input type="text" onChange={(e) => setSearchPhrase(e.target.value)} name="searchbar-faq" placeholder="Tutaj wpisz czego szukasz..." />
            <button><i className="fa fa-search" aria-hidden="true"></i>&nbsp;Szukaj</button>
        </SearchbarWrapper>
    );
}
