import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: end;
    position: relative;
`;

export const ProductsContainer = styled.div`
    z-index: 1;
    position: absolute; 
    top: 51px;
    padding: 30px 15px;
    width: 100%;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    border: 1px solid #00437E;
    color: #00437E;
    -webkit-box-shadow: -5px 5px 10px 0px rgba(150, 150, 150, 0.8);
    -moz-box-shadow: -5px 5px 10px 0px rgba(150, 150, 150, 0.8);
    box-shadow: -5px 5px 10px 0px rgba(150, 150, 150, 0.8);
    
    @media (max-width: 991px){
        width: 100%;
        padding: 8px 4px;
        overflow-y: auto;
        max-height: calc(90vh - 150px);
    }
`;

export const Form = styled.form`
    position: relative;
    width: 280px;
    height: 52px;
    border: 1px solid #00437E;
    border-radius: 26px;
    background-color: white;
    display: flex;
    justify-content: space-between;

    ${Wrapper}:focus-within & {
        width: 640px;
    }

    ${(props) => props.isSearchActive && 'width: 640px;'}
    
    @media (max-width: 991px){
        width: 100%;
        
        ${Wrapper}:focus-within & {
            width: 100%;
        }

        ${(props) => props.isSearchActive && 'width: 100%;'}
    }
`;

export const Input = styled.input`
    width: 80%;
    color: #00437E;
    align-self: flex-start;
    font-size: 20px;
    border: none;
    border-radius: 26px 0 0 26px;
    font-weight: 700;
    padding: 10px 20px;
    
    &::placeholder {
        color: #00437E;
        font-size: 20px;
        font-weight: 700;
    }
    
    &:focus {
        outline: none;
    }
`;

export const SearchButton = styled.button`
    cursor: pointer;
    padding: 0 20px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    color: #00437E;

    &:focus {
        outline: none;
    }
`;

export const ButtonLabel = styled.span`
    font-size: 11px;
    font-weight: 700;
    line-height: 1;
    padding-right: 8px;
    text-transform: uppercase;
    font-family: 'Roboto Condensed';
`;

export const SearchIcon = styled.i`
    display: block;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:focus {
        box-shadow: none;
    }
`;
