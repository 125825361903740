import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import AppContext from '../../context/app-context';
import {
    fetchSearch,
    resetState,
    selectQuery,
    setQuery,
} from './searchSlice';

export const SEARCH_PARAM_NAME = 'query';

const useSearch = () => {
    const query = useSelector(selectQuery);
    const dispatch = useDispatch();
    const appContext = useContext(AppContext);
    const location = useLocation();
    const history = useHistory();

    const parseInputValue = (inputValue) => inputValue?.trim().replace(/ +/g, '-');

    const onInputChange = (event) => {
        dispatch(setQuery(event.target.value));
        const parsedInputValue = parseInputValue(event.target.value);

        if (parsedInputValue.length > 0) {
            dispatch(fetchSearch({ query: parsedInputValue }));
        } else {
            dispatch(resetState());
        }
    };

    const onInputFocus = () => {
        if (query.length > 0) {
            dispatch(fetchSearch({ query }));
        }
    };

    const getSearchUrl = () => {
        const parsedInputValue = query?.trim().replace(/ +/g, '-');

        return `${appContext.routeMap.search.path[appContext.lang]}?${SEARCH_PARAM_NAME}=${parsedInputValue}`;
    };

    const onFormReset = () => {
        dispatch(resetState());
        document.activeElement.blur();
    };

    const onFormSubmit = (e) => {
        e.preventDefault();

        if (query.includes('warsztat')) {
            history.push(`${appContext.routeMap.events.path[appContext.lang]}`);
            return;
        }

        if (query.length > 0) {
            history.push(getSearchUrl());
        }
    };

    useEffect(() => {
        dispatch(resetState());
    }, [location, dispatch]);

    return {
        onFormSubmit,
        onFormReset,
        onInputChange,
        onInputFocus,
    };
};

export default useSearch;
