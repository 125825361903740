import React, {
    useCallback, useContext, useEffect, useState,
} from 'react';
import { Form, Tab } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import {
    CartTab, Label, StepContainer, StepLink, StepNumber, StyledContainer,
} from './CartStyles';
import CartComponent from '../../Cart/Cart';
import Deliveries from '../../Deliveries/Deliveries';
import useCart from '../../Cart/hook/useCart';
import Payments from '../../Payments/Payments';
import ContactDetailsForm from '../../ContactDetailsForm/ContactDetailsForm';
import Summary from '../../Summary/Summary';
import CartFooterBar from '../../CartFooterBar/CartFooterBar';
import { isFirstStepValid, isSecondStepValid, isThirdStepValid } from './validation';
import CartContext from '../../Cart/context/cartContext';

const Cart = withRouter(() => {
    const cartContext = useContext(CartContext);
    const { numberOfActiveStep, setNumberOfActiveStep } = cartContext;
    const [data, setData] = useState(JSON.parse(localStorage.getItem('order')) || {});
    const {
        getItems, itemsCount, loadDiscount, onlyDigitalItems, getTotalPrice, getTotalPriceBefore,
    } = useCart();
    const [cartTotalPrice, setCartTotalPrice] = useState(getTotalPrice());
    const [cartTotalPriceBefore, setCartTotalPriceBefore] = useState(getTotalPriceBefore());
    const [discount, setDiscount] = useState(loadDiscount());
    const [productsDiscounts, setProductsDiscounts] = useState([]);
    const [deliveries, setDeliveries] = useState([]);
    const [orderStatus, setOrderStatus] = useState('ready');
    const [stepValidations, setStepValidations] = useState(
        {
            1: {
                wasValidated: false,
                isStepValid: isFirstStepValid(itemsCount, getItems()),
            },
            2: {
                wasValidated: false,
                isStepValid: false,
            },
            3: {
                wasValidated: false,
                isStepValid: false,
            },
            4: {
                wasValidated: false,
                isStepValid: false,
            },
        },
    );
    const items = getItems();

    const setIsStepValid = (step, value) => {
        setStepValidations((stepValidations) => (
            {
                ...stepValidations,
                [step]: { ...stepValidations[step], isStepValid: value },
            }
        ));
    };

    const setWasValidated = (step, value) => {
        setStepValidations((stepValidations) => (
            {
                ...stepValidations,
                [step]: { ...stepValidations[step], wasValidated: value },
            }
        ));
    };

    const price = cartTotalPrice + (parseFloat(data.deliveryPrice) || 0);

    useEffect(() => {
        if (!['1', '2', '3', '4'].includes(numberOfActiveStep)) {
            setNumberOfActiveStep('1');
        }
    }, [numberOfActiveStep, setNumberOfActiveStep]);

    useEffect(() => {
        setIsStepValid(1, isFirstStepValid(itemsCount, items));
        isFirstStepValid(itemsCount, items);
        // eslint-disable-next-line
    }, [itemsCount]);

    const setDataValue = useCallback((key, value) => {
        data[key] = value;
        localStorage.setItem('order', JSON.stringify({ ...data }));
        setData({ ...data });
    }, [data, setData]);

    const cartSteps = [
        {
            label: 'Koszyk',
            isStepAvailable: true,
        },
        {
            label: 'Dostawa i\u00A0płatności',
            isStepAvailable: isFirstStepValid(itemsCount, getItems()),
        },
        {
            label: 'Dane do wysyłki\u00A0i\u00A0faktury',
            isStepAvailable: stepValidations[1].isStepValid && stepValidations[2].isStepValid,
        },
        {
            label: 'Podsumowanie',
            isStepAvailable: stepValidations[1].isStepValid
                && stepValidations[2].isStepValid
                && stepValidations[3].isStepValid,
        },
    ];

    const countDiscount = () => {
        const calculatePercentDiscount = (price, discountValue) => -(Math.round(
            -(price - ((price * discountValue) / 100)) * 100,
        ) / 100);

        const findProductDiscountById = (id) => discount.products.find(
            (discountProduct) => discountProduct.product.id === id,
        );

        const calculateDiscountedPriceForProduct = (item) => {
            const discountProduct = findProductDiscountById(item.id);
            if (discountProduct) {
                const discountedPrice = discountProduct.is_percentage
                    ? calculatePercentDiscount(item.price, discountProduct.value)
                    : item.price - discountProduct.value;

                setProductsDiscounts((p) => {
                    p[item.id] = discountedPrice;
                    return p;
                });
                return discountedPrice * item.quantity;
            }

            return calculatePercentDiscount(item.price, discount.value) * item.quantity;
        };

        const setCartTotalPriceWithPercentageDiscount = () => setCartTotalPrice(
            getItems().filter((item) => item.isVisible).reduce((prevValue, item) => {
                const discountedItemPrice = calculatePercentDiscount(item.price, discount.value);
                setProductsDiscounts((p) => {
                    p[item.id] = discountedItemPrice;
                    return p;
                });
                let discountedPrice = discountedItemPrice * item.quantity;
                if (discount.products.length > 0) {
                    discountedPrice = calculateDiscountedPriceForProduct(item);
                }

                return prevValue + discountedPrice;
            }, 0),
        );

        const setCartTotalPriceWithNoPercentageDiscount = () => {
            if (discount.products.length > 0) {
                setCartTotalPrice(getItems().filter((item) => item.isVisible).reduce(
                    (prevValue, item) => prevValue + parseFloat(calculateDiscountedPriceForProduct(item)),
                    0,
                ));
            } else {
                setCartTotalPrice((cartTotalPrice) => cartTotalPrice - discount.value);
            }
        };

        if (getItems().length > 0 && discount) {
            if (discount.is_percentage) {
                setCartTotalPriceWithPercentageDiscount();
            } else {
                setCartTotalPriceWithNoPercentageDiscount();
            }
            setDataValue('discount', discount.id);
        } else {
            setCartTotalPrice(getTotalPrice());
        }
    };

    const setDeliveryPrice = () => {
        const delivery = deliveries.find((delivery) => delivery.type === data.deliveryType);
        if (delivery) {
            const threshold = parseFloat(delivery.price_threshold_for_free);
            if (getTotalPrice() >= threshold) {
                setDataValue('deliveryPrice', 0);
            } else {
                setDataValue('deliveryPrice', delivery.price);
            }
        }
    };

    useEffect(() => {
        setDeliveryPrice();
        // eslint-disable-next-line
    }, [cartTotalPrice, deliveries]);

    const totalPriceChangesHandler = () => {
        setCartTotalPrice(getTotalPrice());
        setCartTotalPriceBefore(getTotalPriceBefore());
        countDiscount();
        setDeliveryPrice();
    };

    const goToNextStep = () => {
        setIsStepValid(numberOfActiveStep, true);
        setNumberOfActiveStep(`${+numberOfActiveStep + 1}`);
        window.scrollTo(0, 0);
    };

    const getGlobalOffset = (element) => {
        let top = 0;
        while (element) {
            top += element.offsetTop;
            element = element.offsetParent;
        }

        return top;
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        const isFormValid = form.checkValidity()
            && form.querySelectorAll('input:not(:disabled):not(#discount).is-invalid').length === 0;

        const secondStepValidation = numberOfActiveStep === '2' && isSecondStepValid(data, onlyDigitalItems());
        const thirdStepValidation = numberOfActiveStep === '3' && isThirdStepValid(data);

        if (isFormValid && (secondStepValidation || thirdStepValidation)) {
            goToNextStep();

            return;
        }

        setIsStepValid(numberOfActiveStep, false);
        window.scrollTo(0, getGlobalOffset(form.querySelector('input:invalid')) - (window.innerHeight / 2));
    };

    const clearValidation = () => {
        setIsStepValid(numberOfActiveStep, false);
    };

    return (
        <StyledContainer>
            <Tab.Container id="cartStep" activeKey={numberOfActiveStep} onSelect={(k) => setNumberOfActiveStep(k)}>
                <CartTab>
                    {cartSteps.map((step, index) => (
                        <StepContainer key={index + 1} disabled={!step.isStepAvailable}>
                            <StepLink
                                eventKey={index + 1}
                                className={
                                    !step.isStepAvailable ? 'disabled'
                                        : `${numberOfActiveStep > index + 1 ? 'active' : ''}`
                                }
                            >
                                <StepNumber className={`${numberOfActiveStep >= index + 1 ? 'active' : ''}`}>
                                    {index + 1}
                                </StepNumber>
                                <Label>{step.label}</Label>
                            </StepLink>
                        </StepContainer>
                    ))}
                </CartTab>
                <Tab.Content>
                    <Tab.Pane eventKey={1}>
                        <CartComponent
                            handleTotalPriceChange={totalPriceChangesHandler}
                            productsDiscounts={productsDiscounts}
                            isInvalid={stepValidations[1].wasValidated && !isFirstStepValid(itemsCount, getItems())}
                            discount={discount}
                            setIsStepValid={setIsStepValid}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey={2}>
                        <Form
                            noValidate
                            validated={stepValidations[2].wasValidated}
                            onSubmit={handleSubmit}
                            onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                            id="deliveriesAndPayments"
                            onChange={clearValidation}
                        >
                            <Deliveries
                                data={data}
                                setDataValue={setDataValue}
                                setData={setData}
                                cartTotalPrice={cartTotalPrice}
                                deliveries={deliveries}
                                setDeliveries={setDeliveries}
                                validated={stepValidations[2].wasValidated}
                            />
                            <Payments
                                setDataValue={setDataValue}
                                totalCartPrice={price}
                                data={data}
                            />
                        </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey={3}>
                        <Form
                            noValidate
                            validated={stepValidations[3].wasValidated}
                            onSubmit={handleSubmit}
                            onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                            id="contactDetails"
                            onChange={clearValidation}
                        >
                            <ContactDetailsForm
                                data={data}
                                setDataValue={setDataValue}
                            />
                        </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey={4}>
                        <Summary
                            data={data}
                            setData={setData}
                            setDataValue={setDataValue}
                            productsDiscounts={productsDiscounts}
                            stepValidations={stepValidations}
                            setNumberOfActiveStep={setNumberOfActiveStep}
                            getGlobalOffset={getGlobalOffset}
                            setOrderStatus={setOrderStatus}
                            cartTotalPrice={cartTotalPrice}
                        />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            <CartFooterBar
                cartTotalPrice={cartTotalPrice}
                setProductsDiscounts={setProductsDiscounts}
                discount={discount}
                setDiscount={setDiscount}
                deliveries={deliveries}
                data={data}
                totalPrice={price}
                stepValidations={stepValidations}
                setWasValidated={setWasValidated}
                orderStatus={orderStatus}
                cartTotalPriceBefore={cartTotalPriceBefore}
                setIsStepValid={setIsStepValid}
                setDataValue={setDataValue}
            />
        </StyledContainer>
    );
});

export default Cart;
