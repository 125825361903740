import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import Product from './Product/Product';
import {
    ButtonLabel, Form, Input, ProductsContainer, SearchButton, SearchIcon, Wrapper,
} from './SearchStyles';
import useAutoClose from './useAutoClose';
import useSearch from './useSearch';
import Event from './Event/Event';
import { selectIsSearchActive, selectSearchState } from './searchSlice';

const Search = () => {
    const {
        onFormSubmit,
        onFormReset,
        onInputChange,
        onInputFocus,
    } = useSearch();

    const {
        query, areResultsVisible, products, events,
    } = useSelector(selectSearchState);
    const isSearchActive = useSelector(selectIsSearchActive);

    const resultsRef = useRef();
    useAutoClose({ resultsRef });
    const isResetButtonEnabled = query.length > 0;

    return (
        <Wrapper ref={resultsRef}>
            <Form onSubmit={onFormSubmit} onReset={onFormReset} isSearchActive={isSearchActive}>
                <Input
                    type="text"
                    placeholder="SZUKAJ"
                    value={query.replace(/-+/g, ' ')}
                    onChange={onInputChange}
                    onFocus={onInputFocus}
                />
                <SearchButton type={isResetButtonEnabled ? 'reset' : 'submit'}>
                    <ButtonLabel>
                        {isResetButtonEnabled ? 'wyczyść' : ''}
                    </ButtonLabel>
                    <SearchIcon
                        className={`fa ${isResetButtonEnabled ? 'fa-times' : 'fa-search'}`}
                        aria-hidden="true"
                    />
                </SearchButton>
            </Form>
            {areResultsVisible && query.length > 0 && (products.length > 0 ? (
                <ProductsContainer>
                    {products.map((product) => <Product key={product.id} product={product} />)}
                </ProductsContainer>
            ) : (events.length > 0 ? (
                <ProductsContainer>
                    {events.map((event) => <Event key={event.id} event={event} />)}
                </ProductsContainer>
            ) : (
                <ProductsContainer>Brak wyników wyszukiwania dla podanej frazy</ProductsContainer>
            )))}
        </Wrapper>
    );
};

export default Search;
