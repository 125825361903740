import styled, { css } from 'styled-components';
import { Row } from 'react-bootstrap';

export const RowStyled = styled(Row)`
    margin: 0;
`;

export const CategoryAnchor = styled.div`
    height: 200px;
    margin-top: -105px;
        
    @media (max-width: 992px) {
        height: 250px;
        margin-top: -105px;
    }
    
    ${({ subpage }) => subpage === 2 && css`
        height: 0;
        margin-top: 24px;
        
        @media (max-width: 992px) {
            height: 0;
            margin-top: 24px;
        }
    `}
`;
