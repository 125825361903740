import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './client/Search/searchSlice';
import SearchResultsReducer from './client/pages/SearchResults/searchResultsSlice';
import saga from './saga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        search: searchReducer,
        searchResults: SearchResultsReducer,
    },
    middleware: [sagaMiddleware],
});

sagaMiddleware.run(saga);

export default store;
