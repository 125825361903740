import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { hideResults } from './searchSlice';

const useAutoClose = ({ resultsRef }) => {
    const dispatch = useDispatch();
    const handleClosure = useCallback(
        (event) => !resultsRef.current.contains(event.target) && dispatch(hideResults()),
        [dispatch, resultsRef],
    );

    React.useEffect(() => {
        window.addEventListener('mousedown', handleClosure);
        window.addEventListener('focusin', handleClosure);

        return () => {
            window.removeEventListener('mousedown', handleClosure);
            window.removeEventListener('focusin', handleClosure);
        };
    }, [handleClosure, resultsRef]);
};

export default useAutoClose;
