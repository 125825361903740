import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    status: 'initial',
};

const searchResultsSlice = createSlice({
    name: 'searchResults',
    initialState,
    reducers: {
        fetchProducts: () => ({
            status: 'loading',
        }),
        fetchProductsSuccess: (state, { payload: products }) => {
            state.status = 'success';
            state.products = products;
        },
        fetchProductsError: () => ({
            status: 'error',
        }),
    },
});

export const { fetchProducts, fetchProductsSuccess, fetchProductsError } = searchResultsSlice.actions;

export const selectSearchResultsState = (state) => state.searchResults;
export const selectProducts = (state) => state.searchResults.products;
export const selectStatus = (state) => state.searchResults.status;

export default searchResultsSlice.reducer;
