import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import LazyLoad from 'react-lazy-load';
import Container from '../../Container/Container';
import ImageSlide from '../../ImageSlide/ImageSlide';
import { HomeDividedBlock, LeftCol, RightCol, StyledRow } from './HomeStyles';
import AppContext from '../../../context/app-context';

export default function Home() {
    const appContext = useContext(AppContext);
    const { setGoogleData } = appContext;
    useEffect(() => {
        setGoogleData({'ecomm_pagetype': 'home'})
    }, [setGoogleData]);
    useEffect(() => window.fbq('track', 'PageView'), []);

    return (
        <Container>
            <StyledRow>
                <LeftCol>
                    <ImageSlide text="Roboty" href="/pl/produkty#ozobot" shape="circle"
                                imagepath={process.env.REACT_APP_CDN_URL + "roboty_img.jpg"}/>
                </LeftCol>
                <Col>
                    <ImageSlide text="Puzzle" href="/pl/produkty#puzzle" shape="square"
                                imagepath={process.env.REACT_APP_CDN_URL + "puzzle_img.jpg"}/>
                </Col>
                <RightCol>
                    <ImageSlide text="Mata" href="/pl/produkty#mata" shape="cross"
                                imagepath={process.env.REACT_APP_CDN_URL + "mata_img.jpg"}/>
                </RightCol>
            </StyledRow>
            <div className="d-block text-center font-weight-bold h1 font-dark-blue my-5 pt-5">NAGRODY</div>
            <LazyLoad>
                <Row className="my-5 font-light-blue justify-content-center">
                    <Col xs="12" sm="6" md="3" className="my-3">
                        <img src={process.env.REACT_APP_CDN_URL + "laury-codeweek.png"} className="img-fluid" alt=""/>
                        <div className="h4 font-weight-bold mt-2">Wspieramy Code Week</div>
                    </Col>
                    <Col xs="12" sm="6" md="3" className="my-3">
                        <img src={process.env.REACT_APP_CDN_URL + "laury-zabawkaroku.png"} className="img-fluid" alt=""/>
                        <div className="h4 font-weight-bold mt-2">Zabawka Roku</div>
                    </Col>
                    <Col xs="12" sm="6" md="3" className="my-3">
                        <img src={process.env.REACT_APP_CDN_URL + "laury-gameinn.png"} className="img-fluid" alt=""/>
                        <div className="h4 font-weight-bold mt-2">Laureat GameInn</div>
                    </Col>
                </Row>
            </LazyLoad>
            <LazyLoad>
                <Row className="mt-5 justify-content-center pt-5 mx-0">
                    <HomeDividedBlock xs="12" md="6"
                                      className="bg-dark-green h1 text-left font-weight-bold font-white p-5 m-0">
                        SCIENCE<br/>TECHNOLOGY<br/>ENGINEERING<br/>ART<br/>MATHEMATICS
                    </HomeDividedBlock>
                    <HomeDividedBlock xs="12" md="6" imagepath={process.env.REACT_APP_CDN_URL + "stem-02.png"}/>
                    <HomeDividedBlock xs="12" md="6" imagepath={process.env.REACT_APP_CDN_URL + "stem-01.png"}/>
                    <HomeDividedBlock xs="12" md="6" imagepath={process.env.REACT_APP_CDN_URL + "stem-03.png"}/>
                </Row>
            </LazyLoad>
        </Container>
    );
}
