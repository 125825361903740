import styled, { css } from 'styled-components';
import { Col, Form, Button } from 'react-bootstrap';
import Container from '../Container/Container';
import Input from '../Input/Input';

export const ContainerStyled = styled(Container)`
    display: flex;
    padding: 30px 20px;
    min-height: 0;
    text-align: left; 

    @media (max-width: 767.98px) {
        flex-direction: column;
    }

    @media (max-width: 575.98px) {
        padding: 20px 15px;
    }
`;

export const Column = styled(Col)`
    margin: 0;
    color: #00437E;
    font-size: 1.2em;
    padding: 0;
`;

export const FormColumn = styled(Column)`
    &.padding-right {
        padding-right: 30px;

        @media (max-width: 767.98px) {
            padding: 0;
        }
    }

    &.padding-left {
        padding-left: 30px;

        @media (max-width: 767.98px) {
            padding: 0;
        }
    }
`;

export const Header = styled.div`
    color: #00437E;
    font-weight: 700;
    font-size: 1.5em;
    margin-bottom: 20px;
    display: block;

    ${(props) => props.disabled && css`
        color: #C6C6C6;
    `};
`;

export const ContactDetailsHeader = styled(Header)`
    margin-bottom: 30px;

    @media(max-width: 1240px) {
        margin-bottom: 40px;
    }

    @media(max-width: 767px) {
        margin-bottom: 20px;
    }
`;

export const DeliveryDetailsHeader = styled(Header)`
    margin-bottom: 0;
`;

export const PostCodeColumn = styled(Col)`
    max-width: 130px;
    margin-right: 30px;
`;

export const VatCheckbox = styled(Form.Check)`
    margin-bottom: 10px;
    color: #00437E;
    font-weight: 900;
    font-size: 21px;
    
    & > input {
        width: 1px;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
    }
    
    & .checkbox-placeholder {
        background-color: white;
        border: 2px #00437E solid;
        position: absolute;
        width: 25px;
        height: 25px;
        left: 0;
        top: 2px;
        cursor: pointer;
    }

    & .checkbox-placeholder.disabled {
        border: 2px #C6C6C6 solid !important;
    }
    
    & .checkbox-label {
        margin-left: 10px;
        color: #00437E;
        cursor: pointer;
    }

    & .checkbox-label.disabled {
        color: #C6C6C6;
        cursor: unset;
    }

    & input:checked + label .checkbox-placeholder::after {
        content: '';
        border: 2px #00437E solid;
        background-color: #00437E; 
        position: absolute;
        left: 3px;
        right: 3px;
        bottom: 3px;
        top: 3px;
        cursor: pointer;
    }
`;

export const CopyAddressDataButton = styled(Button)`
    float: right;
    font-weight: 700;
    font-size: 0.8rem;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 2px 5px;
    border: none;
    background-color: #00AFFF;
    color: white;
    outline: none;
    margin-bottom: 20px;

    &:focus {
        box-shadow: none;
    }
    
    &:hover {
        color: white;
        background-color: #00437E;
        border-color: #00437E;
    }

    &:disabled {
        background-color: #C6C6C6;
        cursor: unset;
    }
`;

export const InvoiceFormHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 991.98px) {
        flex-wrap: wrap;
    }
`;

export const Info = styled.p`
    height: 30px;
    margin: 0;
    font-size: 15px;
    line-height: 1;
    font-weight: 600;

    @media(max-width: 1240px) {
        height: 40px;
    }

    @media(max-width: 767px) {
        height: unset;
        margin-bottom: 10px;
    }
`;

export const StyledInput = styled(Input)`
    font-size: 18px;
`;
