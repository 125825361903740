import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import { Col } from 'react-bootstrap';

export const PersonalCard = styled(Card)`
    & img{
        width: 9rem;
        height: 9rem;
        margin: 0 auto;
    }

    & .social-icon{
        color: #007AB5;
    }
    
    @media(max-width: 567px){
        & div{
            padding: 20px 0;
        }

        & img{
            width: 6rem;
            height: 6rem;
        }

        & h4{
            white-space: pre-wrap;
            font-size: 1.1rem;
        }
    }
`;

export const StyledColumn = styled(Col)`
    @media(max-width: 992px){
        padding: 0 !important;
    }
`;
