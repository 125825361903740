import styled from 'styled-components';
import PriceMain from '../PriceMain/PriceMain';

export const Wrapper = styled.div`
    display: flex;
    color: #FFFFFF;
    background-color: #00437E;
    text-align: left;
    justify-content: flex-start;

    @media (max-width: 991.98px) { 
        flex-wrap: wrap;
    } 
`;

export const DiscountContainer = styled.div`
    padding: 30px 40px;
    flex-basis: 40%;

    &.green-background{
        background-color: #39B54A;
    }

    @media (max-width: 1200px) { 
        padding: 30px 20px;
    } 

    @media (max-width: 991.98px) { 
        flex-basis: 100%;
    } 

    @media (max-width: 575.98px) { 
        padding: 30px 20px;
    } 
`;

export const DescriptionList = styled.dl`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    margin-bottom: 0;

    &.start-align {
        align-items: start;
    }

    &.total-price {
        @media (max-width: 575.98px) { 
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }
    }
`;

export const DescriptionTerm = styled.dt`
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    margin-right: 10px;
    text-align: right;
    max-width: 400px;

    @media (max-width: 575.98px) { 
        font-size: 16px;
        white-space: unset;
    } 

    &.start-align {
        align-self: start;
        padding-top: 3px;
    }

    &.summary {
        text-transform: uppercase;
        color: #00AFFF;
    }

    &.wide {
        grid-column: 1 / span 2;
        text-align: left;
    }
`;

export const DescriptionDetails = styled.dd`
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 0;

    &.align-left{
        text-align: left;
    }   

    @media (max-width: 575.98px) { 
        font-size: 16px;

        &.mobile-wrap {
            padding-top: 10px;
            grid-column: 1 / span 2;
            text-align: center;
        }
    } 

    &.margin-right {
        margin-right: 10px;
    }
`;

export const SavingsAmount = styled(PriceMain)`
    color: #FFFD00;
    font-size: 26px;
    justify-content: flex-start;
`;

export const FooterContainer = styled.div`
    padding: 30px 40px;
    background-color: #00437E;

    @media (max-width: 1199.98px) { 
        padding: 30px 20px;
    } 

    @media (max-width: 575.98px) { 
        flex-basis: 100%;
    } 
`;

export const DeliveryPriceContainer = styled(FooterContainer)`
    @media (max-width: 575.98px) { 
        padding-bottom: 0;
    }
`;

export const SummaryContainer = styled(FooterContainer)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin-left: auto;
`;

export const NextStepButton = styled.button`
    align-self: flex-end;
    padding: 10px 20px;
    background-color: #FFFD00;
    color: #00437E;
    border: none;
    outline: none;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.3;
    border-radius: 6px; 
    margin-top: 5px; 

    @media (max-width: 575.98px) { 
        padding: 20px;
        font-size: 24px;
        width: 100%;
    } 

    &:hover {
        background-color: #FFFFFF;
        color: #00AFFF;
    }

    &:disabled {
        background-color: #EEEEEE;
        color: #00AFFF;
    }
`;

export const ButtonContainer = styled.div`
    padding: 30px 0;
    background-color: #FFFFFF;
    min-width: 300px;

    @media (max-width: 991.98px) { 
        flex-basis: 100%;
    } 
`;

export const CartTotalPrice = styled(PriceMain)`
    color: #39B54A;
    justify-content: flex-start;
`;

export const StyledPriceMain = styled(PriceMain)`
    font-size: 65px;
    max-width: 100%;

    @media (max-width: 575.98px) { 
        flex-basis: 40px;
    } 
`;

export const StyledPrice = styled(PriceMain)`
    font-size: 20px;
    justify-items: left;
    width: auto;
    justify-content: flex-start;
`;

export const SelectedDelivery = styled.div`
    display: flex;
    align-items: center;
`;

export const LoaderContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:rgba(256, 256, 256, 0.4);
`;
