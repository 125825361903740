import React from 'react';
import Container from '../../Container/Container';
import { RowStyled } from '../../CategoriesBar/CategoriesBarStyles';
import { CategoryAnchor } from '../../ProductsList/ProductsListStyles';
import {
    EventLeftColumn,
    CardStyled,
    CardWrapper,
    EventRightColumn,
    EventMiddleColumn, TextPlaceholder,
} from './EventsStyles';
import { EmptyRowTitle } from '../../ProductsList/Card/EmptyCardStyles';

export default function EmptyEvents() {
    return (
        <Container>
            {[...Array(2)].map((category, categoryIndex) => (
                <div key={categoryIndex}>
                    <CategoryAnchor id={categoryIndex} />
                    <RowStyled>
                        <EmptyRowTitle color="#a9a9a9" text={<i>&nbsp;</i>} />
                        {[...Array(3)].map((item, index) => (
                            <RowStyled key={index} className="d-flex flex-wrap flex-col w-100">
                                <CardWrapper>
                                    <CardStyled className="d-flex flex-row justify-content-between">
                                        <div className="d-flex flex-row col-10 pl-0">
                                            <EventLeftColumn className="d-flex flex-column justify-content-center empty" />
                                            <EventMiddleColumn className="d-flex flex-column justify-content-start w-100">
                                                <TextPlaceholder>
                                                    <span>&nbsp;</span>
                                                    <span className="w-50">&nbsp;</span>
                                                    <span className="w-75">&nbsp;</span>
                                                </TextPlaceholder>
                                            </EventMiddleColumn>
                                        </div>
                                        <EventRightColumn className="d-flex flex-row justify-content-end align-self-center mr-2" />
                                    </CardStyled>
                                </CardWrapper>
                            </RowStyled>
                        ))}
                    </RowStyled>
                </div>
            ))}
        </Container>
    );
}
