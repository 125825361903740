import React, { useContext, useEffect, useState } from 'react';
import Container from '../../Container/Container';
import { getNoAuth } from '../../../api/Api';
import { RowStyled } from '../../CategoriesBar/CategoriesBarStyles';
import { CategoryAnchor } from '../../ProductsList/ProductsListStyles';
import RowTitle from '../../RowTitle/RowTitle';
import {
    EventName,
    EventLeftColumn,
    CardStyled,
    CardWrapper,
    EventLink,
    EventRightColumn,
    EventButton,
    EventAddressLink,
    Month,
    Day,
    EventLinkDisabled,
    EventMiddleColumn,
} from './EventsStyles';
import EmptyEvents from './EmptyEvents';
import CategoriesBar from '../../CategoriesBar/CategoriesBar';
import AppContext from '../../../context/app-context';

export default function Events() {
    const [isLoading, setIsLoading] = useState(true);
    const [actualEventsCategories, setActualEventsCategories] = useState([]);
    const [completedEvents, setCompletedEvents] = useState([]);
    const appContext = useContext(AppContext);
    const language = appContext.lang;

    const addToCompletedEvents = (event) => setCompletedEvents((e) => {
        e.push(event);
        return e;
    });
    const categoriesColors = [
        '#0176a3',
        '#f5d000',
        '#dc2c38',
        '#65ba00',
        '#9e0b0f',
        '#00afff',
        '#b22eaf',
        '#ff6600',
    ];
    const monthsNames = [
        ['styczeń', 'stycznia'],
        ['luty', 'lutego'],
        ['marzec', 'marca'],
        ['kwiecień', 'kwietnia'],
        ['maj', 'maja'],
        ['czerwiec', 'czerwca'],
        ['lipiec', 'lipca'],
        ['sierpień', 'sierpnia'],
        ['wrzesień', 'września'],
        ['październik', 'października'],
        ['listopad', 'listopada'],
        ['grudzień', 'grudnia'],
    ];
    const daysNames = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];

    const getCategoryColor = (index) => categoriesColors[index % categoriesColors.length];

    useEffect(() => {
        getNoAuth('events')
            .then((events) => {
                Object.keys(events).forEach((month) => Object.keys(events[month]).forEach((eventIndex) => {
                    if (((new Date(events[month][eventIndex].start_at) - (new Date()))) < 0) {
                        addToCompletedEvents(events[month][eventIndex]);
                        delete events[month][eventIndex];
                        if (Object.values(events[month]).length <= 0) {
                            delete events[month];
                        }
                    }
                }));

                Object.keys(events).forEach((key, index) => {
                    if (index > 5) {
                        delete events[key];
                    }
                });

                setIsLoading(false);

                return setActualEventsCategories(events);
            })
            .catch(() => {
                setActualEventsCategories([]);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const eventTimeFormatForTitle = (dateTime) => {
        const date = (new Date(dateTime));
        return `${monthsNames[date.getMonth()][0]} ${date.getFullYear()}`;
    };

    const getCategoriesFromEvents = (eventsCategories) => {
        const categories = Object.keys(eventsCategories).map((date, index) => ({
            color: getCategoryColor(index),
            id: index,
            translations: [{
                language: 'pl',
                name: eventTimeFormatForTitle(date),
                href: `#${date}`,
            }],
        }));

        if (completedEvents.length > 0) {
            return [
                ...categories,
                {
                    color: '#00437E',
                    id: categories.length,
                    translations: [{
                        language: 'pl',
                        name: 'Poprzednie warsztaty',
                        href: '#completed',
                    }],
                },
            ];
        }

        return categories;
    };

    const eventDateString = (startAt, endAt) => {
        const startAtDate = new Date(startAt);
        const endAtDate = new Date(endAt);
        return `${daysNames[startAtDate.getDay()]}, ${startAtDate.getDate()} ${monthsNames[startAtDate.getMonth()][1]} ${startAtDate.getFullYear()},`
            + ` godz. ${(`a0${startAtDate.getHours()}`).slice(-2)}:${(`a0${startAtDate.getMinutes()}`).slice(-2)}`
            + ` - ${(`a0${endAtDate.getHours()}`).slice(-2)}:${(`a0${endAtDate.getMinutes()}`).slice(-2)}`;
    };

    const isCompletedEvent = (event) => ((new Date(event.start_at) - (new Date()))) < 0;

    const renderEventCard = (event) => (
        <CardWrapper key={event.id + 1}>
            <CardStyled className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-row col-10 pl-0">
                    <EventLeftColumn className="d-flex flex-column justify-content-center">
                        <Month className="text-green font-weight-bold text-center">{monthsNames[(new Date(event.start_at)).getMonth()][0].slice(0, 3)}</Month>
                        <Day className="text-green">{(new Date(event.start_at)).getDate()}</Day>
                    </EventLeftColumn>
                    <EventMiddleColumn className="d-flex flex-column justify-content-start">
                        {isCompletedEvent(event) || event.is_date_changed
                            ? (
                                <EventLinkDisabled>
                                    <EventName title={`${event.city} | ${event.name}`}>
                                        {event.city}
                                        {' '}
                                        |
                                        {' '}
                                        {event.name}
                                    </EventName>
                                </EventLinkDisabled>
                            )
                            : (
                                <EventLink href={event.url} target="_blank">
                                    <EventName title={`${event.city} | ${event.name}`}>
                                        {event.city}
                                        {' '}
                                        |
                                        {' '}
                                        {event.name}
                                    </EventName>
                                </EventLink>
                            )}
                        <div className="text-green font-weight-bold">
                            {eventDateString(event.start_at, event.end_at)}
                        </div>
                        <EventAddressLink
                            href={`https://maps.google.com/?q=${event.address}`}
                            target="_blank"
                            className="d-flex flex-column justify-content-center text-blue font-weight-600"
                            title={event.address}
                        >
                            <div>{event.address}</div>
                        </EventAddressLink>
                    </EventMiddleColumn>
                </div>
                <EventRightColumn className="d-flex flex-row justify-content-end align-self-center mr-2">
                    {isCompletedEvent(event)
                        ? (
                            <EventLink>
                                <EventButton className="completed" title="Zakończony" disabled>
                                    <i className="fa fa-lock" />
                                    <span className="text">Zakończony</span>
                                </EventButton>
                            </EventLink>
                        )
                        : event.is_date_changed
                            ? (
                                <EventLink>
                                    <EventButton className="date-changed d-block d-lg-flex" title="Zmiana terminu" disabled>
                                        <i className="fa fa-shopping-cart" />
                                        <span className="text">Zmiana terminu</span>
                                    </EventButton>
                                </EventLink>
                            )
                            : (
                                <EventLink href={event.url} target="_blank">
                                    <EventButton className="buy d-block d-lg-flex" title="Kup bilet">
                                        <i className="fa fa-shopping-cart" />
                                        <span className="text">Kup bilet</span>
                                    </EventButton>
                                </EventLink>
                            )}
                </EventRightColumn>
            </CardStyled>
        </CardWrapper>
    );

    const renderEvents = (categories) => Object.values(categories).map((category, index) => (
        <React.Fragment key={index}>
            <CategoryAnchor id={Object.keys(categories)[index]} className="event-anchor" />
            <RowStyled>
                <RowTitle
                    color={getCategoryColor(index)}
                    textColor="white"
                    text={eventTimeFormatForTitle(Object.keys(categories)[index])}
                />
                <RowStyled className="d-flex flex-wrap flex-col w-100">
                    {category.map(renderEventCard)}
                </RowStyled>
            </RowStyled>
        </React.Fragment>
    ));

    const renderCompleted = () => completedEvents.length > 0
        && (
        <>
            <CategoryAnchor id="completed" className="event-anchor" />
            <RowStyled>
                <RowTitle color="#00437E" textColor="white" text="Poprzednie warsztaty" />
                <RowStyled className="d-flex flex-wrap flex-col w-100">
                    {[...completedEvents].reverse().map(renderEventCard)}
                </RowStyled>
            </RowStyled>
        </>
        );

    return (
        <Container>
            {!isLoading
                ? (
                    <>
                        <CategoriesBar
                            categories={getCategoriesFromEvents(actualEventsCategories)}
                            language={language}
                        />
                        {renderEvents(actualEventsCategories)}
                        {renderCompleted()}
                    </>
                )
                : <EmptyEvents />}
        </Container>
    );
}
