import React, { useContext, useEffect } from 'react';
import { CertificationsWrapper } from './CertificationsStyles';
import AppContext from '../../../context/app-context';
import { certificationsEnglish, certificationsPolish } from './certificationsData';

export default function Certifications() {
    const appContext = useContext(AppContext);
    const { setGoogleData } = appContext;
    useEffect(() => { setGoogleData({ ecomm_pagetype: 'other' }); }, [setGoogleData]);
    useEffect(() => window.fbq('track', 'PageView'), []);

    return (
        <CertificationsWrapper className="font-dark-blue">
            <div className="font-weight-bold h1 mt-5 font-dark-blue text-left">CERTYFIKATY EDUSENSE</div>
            <div className="font-weight-bold h5 mt-5 font-dark-blue text-left">Język polski</div>
            {certificationsPolish.map((certification) => (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_CDN_URL}${certification.url}`}
                >
                    {certification.title}
                </a>
            ))}
            <div className="font-weight-bold h5 mt-5 font-dark-blue text-left">Język angielski</div>
            {certificationsEnglish.map((certification) => (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_CDN_URL}${certification.url}`}
                >
                    {certification.title}
                </a>
            ))}
        </CertificationsWrapper>
    );
}
