import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import CategoriesBar from '../../CategoriesBar/CategoriesBar';
import { getNoAuth } from '../../../api/Api';
import Container from '../../Container/Container';
import ProductsList from '../../ProductsList/ProductsList';
import EmptyProductList from '../../ProductsList/EmptyProductList';
import AppContext from '../../../context/app-context';
import MetaTags from '../../MetaTags/MetaTags';

export default function ProductsForSchool() {
    const [categories, setCategories] = useState([]);
    const appContext = useContext(AppContext);
    const language = appContext.lang;
    const { setGoogleData } = appContext;

    useEffect(() => { setGoogleData({ ecomm_pagetype: 'category' }); }, [setGoogleData]);

    useEffect(() => {
        getNoAuth('categories?sortBy=position&subpage=0')
            .then((categories) => setCategories(categories.filter((category) => category.products.length > 0
                && category.products.filter((product) => product.is_for_school).length > 0)))
            .catch(() => {
                setCategories([]);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => window.fbq('track', 'PageView'), []);

    return (
        <Container className="bg-none">
            <MetaTags title="Produkty EduSense" />
            <Switch>
                <Redirect from="/produkty/:category/:slug" to="/produkty/:slug" />
            </Switch>
            <CategoriesBar categories={categories} language={language} />
            {categories.length > 0
                ? <ProductsList categories={categories} language={language} isForSchool />
                : <EmptyProductList />}
        </Container>
    );
}
