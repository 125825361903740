import React from 'react';
import { Modal } from 'react-bootstrap';
import {
    RedStyledButton, StyledButton, StyledModal, StyledModalBody, ButtonsContainer, ModalInfo,
} from './ConfirmationModalStyles';

export default ({ show, onHide, onConfirm }) => (
    <StyledModal show={show} onHide={onHide} centered>
        <Modal.Header closeButton />
        <StyledModalBody>
            <ModalInfo>Czy na pewno chcesz usunąć ten przedmiot?</ModalInfo>
            <ButtonsContainer>
                <StyledButton onClick={onHide}>NIE</StyledButton>
                <RedStyledButton onClick={onConfirm}>TAK</RedStyledButton>
            </ButtonsContainer>
        </StyledModalBody>
    </StyledModal>
);
