import React, {useEffect, useState, useRef} from 'react';
import Notification from "../Notification/Notification";
import useNotification from "./hook/useNotification";

export default function NotificationContainer() {
    const {notification} = useNotification();
    const [notificationComponent, setNotificationComponent] = useState(null);
    const timerRef = useRef(0);

    useEffect(() => {
        if (timerRef.current !== 0) {
            clearTimeout(timerRef.current);
            setNotificationComponent(null);
        }

        if (notification) {
            if (notificationComponent !== null) {
                setNotificationComponent(null);
                setTimeout(() =>
                    setNotificationComponent(<Notification text={notification.text} type={notification.type}/>), 100);
            } else {
                setNotificationComponent(<Notification text={notification.text} type={notification.type}/>);
            }
            timerRef.current = setTimeout(
                () => setNotificationComponent(null),
                5000
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    return notificationComponent;
}