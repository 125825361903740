import React, { useEffect, useState } from 'react';
import {
    AddToCart,
    Column,
    Description,
    Header,
    Image,
    LeftColumn,
    MoreLink,
    PreorderText,
    PriceStyled,
    RightColumn,
    Row,
    StockText,
    StyledPriceMain,
    PriceContainer,
} from './ProductDescriptionStyles';
import Carousel from '../Carusel/Carousel';
import Container from '../Container/Container';
import Content from './Content/Content';
import MetaTags from '../MetaTags/MetaTags';
import escapeDiacritics from '../../tools/escapeDiacritics';

export default function ProductDescription({ product, language }) {
    const isBlackWeek = product.is_black_week;
    const isChristmas = product.is_christmas;
    const photos = product.carousel_images
        ? product.carousel_images.map((image) => process.env.REACT_APP_CDN_URL + image.path)
        : [];

    const JsonLd = ({ data }) => (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
        />
    );

    const [data, setData] = useState({});

    useEffect(() => {
        if (product === null) return;
        const translation = product.translations.filter((t) => t.language === language)[0];

        window.gtag('event', 'view_item', {
            items: [
                {
                    id: product.sku,
                    name: escapeDiacritics(translation.name),
                    price: product.price,
                    quantity: 1,
                },
            ],
        });

        setData({
            '@context': 'http://schema.org/',
            '@type': 'Product',
            image: process.env.REACT_APP_CDN_URL + product.main_image.path,
            name: translation.name,
            description: translation.short_description,
            offers: {
                '@type': 'Offer',
                priceCurrency: 'PLN',
                price: `${product.price}`,
            },
        });
    }, [product, language]);

    const renderProduct = () => {
        if (product.translations === undefined || product.translations.length <= 0) return;
        const translation = product.translations.filter((t) => t.language === language)[0];

        // eslint-disable-next-line consistent-return
        return (
            <>
                <MetaTags
                    title={`${translation.name} - EduSense`}
                    image={process.env.REACT_APP_CDN_URL + product.main_image.path}
                    local={language === 'pl' ? 'pl_PL' : 'en_US'}
                    url={document.location.href}
                    description={translation.short_description}
                />
                <Row className={isBlackWeek ? 'black-week' : ''}>
                    <LeftColumn className="d-none d-lg-flex">
                        {product.main_image
                            && (
                                <Image
                                    src={`${process.env.REACT_APP_CDN_URL + product.main_image.path}`}
                                    alt="product image"
                                />
                            )}
                    </LeftColumn>
                    <Column className={`${isBlackWeek ? 'black-week' : ''} ${isChristmas ? 'christmas' : ''}`}>
                        <Header>{translation.name}</Header>
                        <div className="d-block d-lg-none">
                            {product.main_image
                                && (
                                    <Image
                                        src={`${process.env.REACT_APP_CDN_URL + product.main_image.path}`}
                                        alt="product image"
                                    />
                                )}
                        </div>
                        <Description>
                            {translation.short_description}
                            {' '}
                            <MoreLink href="#more-product-description">
                                Przewiń do pełnego opisu&nbsp;
                                <i className="fa fa-caret-right" />
                            </MoreLink>
                        </Description>
                    </Column>
                    <RightColumn className={`justify-content-between ${isBlackWeek ? 'black-week' : ''} ${isChristmas ? 'christmas' : ''}`}>
                        <PriceContainer>
                            {product.price_before && <PriceStyled description="oldPrice" price={parseFloat(product.price_before)} />}
                            {product.price_before && <PriceStyled description="savings" savings price={parseFloat(product.price_before) - parseFloat(product.price)} />}
                            {product.price && <StyledPriceMain price={parseFloat(product.price)} />}
                        </PriceContainer>
                        {translation.preorder_text && product.is_visible && <PreorderText>{translation.preorder_text}</PreorderText>}
                        {translation.stock_text && product.is_visible && <StockText>{translation.stock_text}</StockText>}
                        {product.is_visible ? (
                            <AddToCart
                                isBlackWeek={isBlackWeek}
                                isDisabled={!product.is_buyable}
                                hoverImageUrl={`${process.env.REACT_APP_CDN_URL}/kosz_edu_light_blue.svg`}
                                item={{
                                    id: product.id,
                                    sku: product.sku,
                                    name: translation.name,
                                    price: product.price,
                                    priceBefore: product.price_before,
                                    image: product.main_image && product.main_image.path,
                                    preOrderText: translation.preorder_text,
                                    stockText: translation.stock_text,
                                    isLargeSize: product.is_large_size,
                                    isDigital: product.is_digital,
                                    url: window.location.pathname.substring(3),
                                    contentType: product.marketing[0].fb_content_type,
                                }}
                            />
                        ) : <StockText className="position-static">produkt niedostępny</StockText>}
                    </RightColumn>
                </Row>
                {photos.length > 0 && <Row className="bg-transparent justify-content-center"><Carousel photos={photos} /></Row>}
                <div id="more-product-description" className="p-4" />
                <Content productId={product.id} description={translation.description} rightColumnContent={translation.sub_content} />
                <JsonLd data={data} />
            </>
        );
    };

    return (
        <Container>
            {renderProduct()}
        </Container>
    );
}
