import {
    Col, Form, Nav, Row as BootstrapRow, Button,
} from 'react-bootstrap';
import styled from 'styled-components';
import Container from '../../Container/Container';

export const StyledContainer = styled(Container)`
`;

export const CartTab = styled(Nav)`
    font-size: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    justify-content: space-between;
    grid-gap: 1px;
    row-gap: 0;
    
    @media (max-width: 767.98px) { 
        grid-template-columns: repeat(2,1fr);
    }

    @media (max-width: 575.98px) { 
    }
`;

export const StepContainer = styled(Nav.Item)`
    min-height: 50px;
    border-top: #FFFFFF 1px solid;
`;

export const StepLink = styled(Nav.Link)`
    color: #00437E;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 16px;
    min-height: 65px;
    line-height: 1;

    &.active {
        color: #ffffff;
        background-color: #00437E;
    }

    & > span {
        position: relative;
    }

    &:hover {
        background-color: #39B54A;
        color: #ffffff;
    }

    @media (max-width: 767.98px) { 
        justify-content: flex-start;
        padding-left: 32px;
    }

    @media (max-width: 575.98px) { 
        font-size: 14px;
        padding: 8px;
    }
    
`;

export const StepNumber = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: #00437E;
    font-size: 22px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 10px;
    padding-top: 2px;
    flex-shrink: 0;

    &.active {
        color: #00437E;
        background-color: #ffffff;
    }

    @media (max-width: 575.98px) { 
        font-size: 18px;
        width: 32px;
        height: 32px;
    }
`;

export const Label = styled.span`
    text-align: left;
`;

export const Header = styled.div`
    color: #00437E;
    font-weight: 700;
    font-size: 1.5em;
    margin-bottom: 20px;
    display: block;
`;

export const PostCodeColumn = styled(Col)`
    max-width: 130px;
    margin-right: 30px;
`;

export const Radio = styled(Form.Check)`
    padding: 0;

    & input {
        width: 1px;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
    }
    
    & input + label > * {
        display: block;
    }
    
    & .placeholder {
        margin-top: 3px;
        background-color: white;
        margin-right: 10px;
        cursor: pointer;
        width: 23px;
        height: 23px;
        border: 2px #00AFFF solid;
        border-radius: 50%;
        position: relative;
        display: block;
    }
    
    & input:checked + label .placeholder::after {
        content: '';
        position: absolute;
        left: 3px;
        right: 3px;
        top: 3px;
        bottom: 3px;
        border: 2px #00AFFF solid;
        border-radius: 50%;
    }
    
    & .fit {
        flex: 0;
    }
    
    & label {
        display: flex;
        cursor: pointer;
    }
    
    & label .header {
        font-size: 21px;
        color: #00437E;
        font-weight: 700;
        display: inline-flex;
    }
    
    & label .sub {
        color: #00437E;
        font-weight: 700;
        font-size: 14px;
    }
    
    & input:disabled + label {
        cursor: default;
    }
    
    & input:disabled + label .placeholder {
        border: 2px gray solid;
        cursor: default;
        background-color: #e9ecef;
    }
    
    & input:disabled + label .header {
        color: gray;
        cursor: default;
    } 
    
    @media screen and (max-width: 900px) {
        margin-bottom: 10px;
    }
`;

export const RulesAndSubmitContainer = styled(Container)`
    background: white;
    min-height: 0;
    display: flex;
    padding: 10px;
    
    @media screen and (max-width: 900px) {
        flex-direction: column;
        width: 100%;
    }
`;

export const Column = styled(Col)`
    margin: 0;
    color: #00437E;
    font-size: 1.2em;
    padding: 0;
`;

export const RulesColumn = styled(Column)`
    flex: 2;
    margin: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: 700;
    color: #00437E;
`;

export const SubmitColumn = styled(Column)`
    flex: 1;
    display: flex;
`;

export const ContainerStyled = styled(Container)`
    display: flex;
    padding: 30px 40px;
    min-height: 0;
    
    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
`;

export const RadioHeaderContainer = styled(ContainerStyled)`
    padding-bottom: 0;
`;

export const FormColumn = styled(Column)`
    padding-right: 20px;
`;

export const RadioColumn = styled(Col)`
    flex: 1 1 auto;
    flex-basis: 0;
    min-width: 0;
    padding: 0;
    font-size: 18px;
`;

export const Row = styled(BootstrapRow)`
    background-color: #F0F0F0;
    margin: 0;
    text-align: left;
    
    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
`;

export const ProductsRow = styled(Row)`
    color: #00437E;
    font-size: 1.2em;
    font-weight: 600;
`;

export const TotalColumn = styled(Col)`
    padding: 30px 40px;
    min-height: 150px;
    background-color: #00437E;
    color: white;
    font-size: 2.2rem;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    flex-basis: 0;
    min-width: 0;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
`;

export const SubmitButton = styled(Button)`
    font-size: 48px;
    font-weight: 700;
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 15px 29px;
    border: none;
    background-color: #00AFFF;
    color: white;
    margin: 30px 40px;
    outline: none;
    
    &:focus {
        box-shadow: none;
    }
    
    &:hover {
        color: white;
        background-color: #00437E;
        border-color: #00437E;
    }
    
    &.in-progress {
        background: #aaa;
        cursor: default;
    }
    
    @media screen and (max-width: 900px) {
        width: 100%;
    }
`;
