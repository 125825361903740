import styled from 'styled-components';
import { Container as BootstrapContainer } from 'react-bootstrap';

export default styled(BootstrapContainer)`
    width: 80rem;
    max-width: 80rem;
    padding: 0;
    background: white;
    
    &.bg-none {
        background: none;
    }

    @media (max-width: 1280px){
        max-width: 100%;
        margin: 0;
    }
`;
