import React, { useContext, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Cart from '../Cart/Cart';
import {
    Addresses,
    AddressHeader,
    AddressSection,
    ChechboxInfo,
    Detail,
    StyledCheckbox,
    StyledTermsCheckbox,
    TermsContainer,
} from './SummaryStyles';
import ButtonPreviousStep from '../ButtonPreviousStep/ButtonPreviousStep';
import AppContext from '../../context/app-context';
import useNotification from '../NotificationContainer/hook/useNotification';
import useCart from '../Cart/hook/useCart';
import SendForm from './SendForm';
import Preorder from './Preorder';

export const concatPostCodeWithCity = (postCode, city) => {
    if (postCode && city) {
        return `${postCode}, ${city}`;
    }

    return undefined;
};

const Summary = ({
    data, setData, productsDiscounts, stepValidations,
    setDataValue, setNumberOfActiveStep, setOrderStatus, cartTotalPrice,
}) => {
    const { getItems, removeAll } = useCart();
    const ContactDetails = {
        header: 'Dane kontaktowe',
        details: [
            `${data.firstName || ''} ${data.lastName || ''}`,
            data.email,
            data.phone,
        ],
    };

    const ShippmentAddress = {
        header: 'Dane do wysyłki',
        details: [
            data.institution,
            data.address,
            concatPostCodeWithCity(data.postCode, data.city),
            data.comment,
            data.deliveryExtra ? `Wybrany paczkomat: ${data.deliveryExtra}` : undefined,
            data.deliveryInpostAddress,
        ],
    };

    const InvoiceDetails = {
        header: 'Dane nabywcy na fakturze',
        details: [
            data.invoiceName,
            data.invoiceAddress,
            concatPostCodeWithCity(
                data.invoicePostcode ? data.invoicePostcode : data.invoicePostCode,
                data.invoiceCity,
            ),
            data.invoiceTaxId,
        ],
    };

    const InvoiceRecipientDetails = {
        header: 'Dane odbiorcy na fakturze',
        details: [
            data.invoiceRecipientName,
            data.invoiceRecipientAddress,
            concatPostCodeWithCity(
                data.invoiceRecipientPostcode ? data.invoiceRecipientPostcode : data.invoiceRecipientPostCode,
                data.invoiceCity,
            ),
            data.invoiceRecipientTaxId,
        ],
    };

    const appContext = useContext(AppContext);
    const { notify } = useNotification();
    const history = useHistory();
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        const isValid = form.checkValidity();

        const previousStepValidations = [3, 2, 1].map((numberOfStep) => {
            if (!stepValidations[numberOfStep].isStepValid) {
                setNumberOfActiveStep(numberOfStep.toString());

                return false;
            }

            return true;
        });

        if (isValid && previousStepValidations.every((isValid) => isValid)) {
            data.items = getItems();
            const orderPreviewUrl = `${appContext.lang}${appContext.routeMap.order_preview.path[appContext.lang]}`;
            data.returnUrl = `${process.env.REACT_APP_PAYMENT_RETURN_URL}/${orderPreviewUrl}`;
            data.returnErrorUrl = `${process.env.REACT_APP_PAYMENT_RETURN_ERROR_URL}/${orderPreviewUrl}`;
            data.referer = document.referrer;
            setOrderStatus('pending');

            SendForm(data).then((result) => {
                if (result.status !== 'ok') {
                    notify('Wystąpił błąd. Spróbuj ponownie później.', 'error');
                    setOrderStatus('error');
                    return;
                }

                notify('Dziękujemy za złożenie zamówienia.');
                setOrderStatus('ready');
                removeAll();
                setData({});
                localStorage.removeItem('order');
                localStorage.removeItem('isInvoiceDisabled');
                localStorage.removeItem('isInvoiceRecipientDisabled');

                if (result.payment_url) {
                    window.location = result.payment_url;
                } else {
                    history.push({
                        pathname: appContext.routeMap.order_preview.path[appContext.lang],
                        search: `?h=${result.hash}&s=c`,
                    });
                }
            }).catch(() => {
                setOrderStatus('error');
                notify('Wystąpił błąd. Spróbuj ponownie później.', 'error');
            });
        }
    };

    const getSplitableItems = getItems().filter((item) => {
        if (item.name.toUpperCase().includes('ZESTAW') && item.preorderText.toUpperCase().includes('PRZEDSPRZEDAŻ')) {
            return 1;
        }

        return 0;
    });

    const getItemsWithPreorder = getItems().filter((item) => item.preorderText.length > 0);
    const getItemsWithoutPreorder = getItems().filter((item) => item.preorderText.length === 0);

    const marketingCheckboxRef = useRef(null);
    const termsCheckboxRef = useRef(null);

    return (
        <>
            <Cart readOnly productsDiscounts={productsDiscounts} />
            {getItemsWithPreorder.length > 0 && (
                <Preorder
                    data={data}
                    setDataValue={setDataValue}
                    cartTotalPrice={cartTotalPrice}
                    isSplitableItem={(getSplitableItems.length > 0 || getItemsWithoutPreorder.length > 0)}
                />
            )}
            <Addresses>
                {[ContactDetails, ShippmentAddress, InvoiceDetails, InvoiceRecipientDetails].map((address) => {
                    if (address.details.join('').trim().length) {
                        return (
                            <AddressSection key={address.header}>
                                <AddressHeader>{address.header}</AddressHeader>
                                {address.details.map((field, index) => <Detail key={index}>{field}</Detail>)}
                            </AddressSection>
                        );
                    }

                    return undefined;
                })}
            </Addresses>
            <TermsContainer>
                <ButtonPreviousStep />
                <Form
                    noValidate
                    validated={stepValidations[4].wasValidated}
                    onSubmit={handleSubmit}
                    onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                    id="summary"
                >
                    <StyledCheckbox type="checkbox" id="all">
                        <Form.Check.Input
                            type="checkbox"
                            onChange={(e) => {
                                marketingCheckboxRef.current.checked = e.target.checked;
                                termsCheckboxRef.current.checked = e.target.checked;
                                setDataValue('marketingConsent', e.target.checked);
                            }}
                        />
                        <Form.Check.Label>
                            <span className="placeholder" />
                            <span style={{ display: 'inline-block', paddingRight: '30px' }} className="label">
                                Zaznacz wszystkie
                            </span>
                        </Form.Check.Label>
                    </StyledCheckbox>
                    <StyledCheckbox type="checkbox" id="marketing">
                        <Form.Check.Input
                            ref={marketingCheckboxRef}
                            type="checkbox"
                            onChange={(e) => setDataValue('marketingConsent', e.target.checked)}
                        />
                        <Form.Check.Label>
                            <span className="placeholder" />
                            <span style={{ display: 'inline-block', paddingRight: '30px' }} className="label">
                                Wyrażam zgodę na otrzymywanie od Edu-Sense S.A. z siedzibą w Lublinie
                                informacji handlowych oraz materiałów promujących w celach prezentowania
                                i oferowania produktów i usług Edu Sense S.A. z siedzibą w Lublinie.
                            </span>
                        </Form.Check.Label>
                    </StyledCheckbox>
                    <StyledTermsCheckbox inputRef={termsCheckboxRef} />
                    <ChechboxInfo>* Pola obligatoryjne</ChechboxInfo>
                </Form>
            </TermsContainer>
        </>
    );
};

export default Summary;
