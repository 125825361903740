import styled from "styled-components";

export const ImageSlideWrapper = styled.a`
    transition: background-position 0.3s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E7E5DD;
    width: 100%;
    padding-top: 100%;
    position: relative;
    background-position: 0 27rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => props.imagepath});
    font-size: 1rem;

    &:hover{ 
        background-position: 0 0;
    }

    & span{
        position: absolute;
        height: 4.5rem
        left: 0;
        top: calc( 50% - 2rem );
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 3rem;
        text-align: center;
        width: 100%;
        z-index: 2;
    }

    &.shape-circle:before{
        width: 50%;
        height: 50%;
        background-color: #00437E;
        border-color: #00437E;
        border-radius: 50%;
        display: block;
        position: absolute;
        content: ' ';
        top: 25%;
        left: 25%;
    }
    &.shape-square span{
        background-color: #00AFFF;
        margin: 0 25%;
        width: 50%;
    }

    &.shape-cross span{
        background-color: #65BA00;
        margin: 0 25%;
        width: 50%;
    }

    &.shape-cross:before{
        width: 4.5rem;
        height: 50%;
        background-color: #65BA00;
        display: block;
        position: absolute;
        content: ' ';
        top: 25%;
        left: calc(50% - 2.25rem);
    }
`;