import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Empty = styled.div`
    margin: 20px 40px;
`;

export const CartItemsContainer = styled.div`
    width: 100%;
    color: #00437E;
    background-color: #F7F7F7;
`;

export const Labels = styled.div`
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;

    @media (max-width: 767.98px) { 
        display: none;
    }
`;

export const LabelsFixed = styled(Labels)`
    position: fixed;
    background-color: #F7F7F7;
    right: auto;
    width: 100%;
    top: 88px;
    max-width: 1280px;
    border-bottom: 2px solid #FFFFFF;
    z-index: 1;
    
    @media (max-width: 767.98px) { 
        display: none;
    }
`;

export const Label = styled.div`
    padding: 0 20px;
    text-align: center;

    &.quantity {
        min-width: ${(props) => (!props.readOnly ? '175px' : '100px')};
    }

    &.totalValue {
        min-width: 220px;
    }

    &.remove {
        width: 70px;
    }
`;

export const EmptyMessage = styled.p`
    font-size: 20px;
    font-weight: 600;
    padding: 100px 30px;
    
    @media(max-width: 575.98px) {
        font-size: 16px;
        line-height: 1.1;
        padding: 70px 24px;
    }
`;

export const StyledLink = styled(Link)`
    display: inline-block;
    padding: 8px;
    color: #00AFFF;
`;

export const WarningMessage = styled.p`
    color: red;
    font-weight: 600;
`;

export const StyledWarningMessage = styled(WarningMessage)`
    background-color: #F7F7F7;
    padding: 20px;
`;
