import styled from 'styled-components';
import Card from 'react-bootstrap/Card';

export const FaqWrapper = styled.div`
`;

export const FaqElementWrapper = styled(Card)`
    border: 1px solid #00437E !important;
    border-radius: 0;
    text-align: left;
    padding: 1.15rem;
    color: #00437E;
    margin: 0 1rem 1rem 1rem;

    @media (min-width: 1280px) {
        margin: 0 0 1rem;
    }

    & a{
        font-weight: 700;
    }

    & .card-header {
        background-color: #fff;
        border: none;
        font-weight: 700;
        font-size: 1.5rem;
        cursor: pointer;
    }
`;

export const Image = styled.img`
    @media(max-width: 992px){
        width: 100%;
    }
`;
