import React, { useContext } from 'react';
import CartContext from '../Cart/context/cartContext';
import StyledPreviousStepButton from './ButtonPreviousStepStyles';

const PreviousStepButton = () => {
    const cartContext = useContext(CartContext);
    const { numberOfActiveStep, setNumberOfActiveStep } = cartContext;

    const handlePreviousStepButtonClick = () => {
        setNumberOfActiveStep(`${+numberOfActiveStep - 1}`);
        window.scrollTo(0, 0);
    };

    return (
        <StyledPreviousStepButton onClick={handlePreviousStepButtonClick}>
            <span>Wstecz</span>
        </StyledPreviousStepButton>
    );
};

export default PreviousStepButton;
