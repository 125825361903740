import styled from "styled-components";
import Container from '../../Container/Container';

export const CertificationsWrapper = styled(Container)`
    text-align: left;
    font-size: 1.1rem;
    font-weight: 400;
    min-height: 38rem;
    padding-left: 5px;
    padding-right: 10px;
    word-break: break-all;

    a { 
        font-weight: 600; 
        display: block;
        line-height: 2;
     }
`;