import React, { useContext } from 'react';
import { ClipLoader } from 'react-spinners';
import useCart from '../Cart/hook/useCart';
import Discount from '../Discount/Discount';
import {
    DescriptionDetails,
    DescriptionList,
    DescriptionTerm,
    NextStepButton,
    SummaryContainer,
    DiscountContainer,
    SavingsAmount,
    DeliveryPriceContainer,
    Wrapper,
    ButtonContainer,
    CartTotalPrice,
    StyledPriceMain,
    StyledPrice,
    SelectedDelivery,
    LoaderContainer,
} from './CartFooterBarStyles';
import { DELIVERY_INPOST } from '../Deliveries/Deliveries';
import { ONLINE_TRANSFER, STANDARD_TRANSFER } from '../Payments/Payments';
import ButtonPreviousStep from '../ButtonPreviousStep/ButtonPreviousStep';
import CartContext from '../Cart/context/cartContext';
import { isFirstStepValid } from '../pages/Cart/validation';

const CartFooterBar = ({
    totalPrice,
    cartTotalPrice,
    setProductsDiscounts,
    discount,
    setDiscount,
    deliveries,
    data,
    setWasValidated,
    orderStatus,
    cartTotalPriceBefore,
    setIsStepValid,
    setDataValue,
}) => {
    const {
        saveDiscount,
        removeDiscount,
        onlyDigitalItems,
        isLargeSizeItem,
        itemsCount,
        getItems,
    } = useCart();
    const cartContext = useContext(CartContext);
    const { numberOfActiveStep, setNumberOfActiveStep } = cartContext;

    const getLowestDeliveryPrice = () => {
        if (onlyDigitalItems() || !getAmountForFreeDelivery()) return 0;

        const availableDeliveries = deliveries.filter(
            (delivery) => !(delivery.type === DELIVERY_INPOST && isLargeSizeItem()),
        );
        if (availableDeliveries.length > 0) {
            return Math.min(...availableDeliveries.map((delivery) => delivery.price));
        }

        return null;
    };

    const getAmountForFreeDelivery = () => {
        const minDeliveryPrice = Math.min(...deliveries.map(
            (delivery) => parseInt(delivery.price_threshold_for_free, 10),
        ));

        if (deliveries.length > 0 && cartTotalPrice < minDeliveryPrice) {
            return minDeliveryPrice - cartTotalPrice;
        }

        return null;
    };

    const getDeliveryFullName = (deliveryType) => (deliveryType === 'gls' ? 'KURIER GLS' : 'INPOST');

    const getSelectedDelivery = () => {
        if (data.deliveryType === undefined) return 'Elektronicznie';
        return data.deliveryType && getDeliveryFullName(data.deliveryType);
    };

    const handleSubmitButton = () => {
        setWasValidated(4, true);
    };

    const handleNextStepButtonClick = () => {
        if (isNextStepAvailable()) {
            setNumberOfActiveStep(`${+numberOfActiveStep + 1}`);
            window.scrollTo(0, 0);
        }
    };

    const isNextStepAvailable = () => {
        if (numberOfActiveStep === '1' && isFirstStepValid(itemsCount, getItems())) {
            setIsStepValid('1', true);

            return true;
        }
        setWasValidated(numberOfActiveStep, true);

        return false;
    };

    const getDeliveryPriceMessage = () => {
        if (onlyDigitalItems()) {
            return 'W zamówieniu znajdują się tylko produkty, które nie wymagają wysyłki.';
        }
        if (data.deliveryType) {
            return `Koszt dostawy ${getDeliveryFullName(data.deliveryType)}:`;
        }

        return getAmountForFreeDelivery() && 'Koszt dostawy od:';
    };

    const getDeliveryPrice = () => {
        if (onlyDigitalItems()) {
            return null;
        }
        if (data.deliveryPrice || data.deliveryPrice === 0) {
            return data.deliveryPrice;
        }

        return getAmountForFreeDelivery() && getLowestDeliveryPrice();
    };

    return (
        <Wrapper>
            {(numberOfActiveStep === '1' || numberOfActiveStep === '4')
                && (
                    <DiscountContainer className={numberOfActiveStep === '1' ? 'green-background' : ''}>
                        <DescriptionList>
                            <DescriptionTerm>Przed&nbsp;rabatem:</DescriptionTerm>
                            <DescriptionDetails><StyledPrice price={cartTotalPriceBefore} /></DescriptionDetails>

                            <DescriptionTerm>Oszczędzasz: </DescriptionTerm>
                            <DescriptionDetails>
                                <SavingsAmount price={(cartTotalPriceBefore - cartTotalPrice)} />
                            </DescriptionDetails>
                            {(discount || numberOfActiveStep === '1')
                                && (
                                    <>
                                        <DescriptionTerm className="start-align mobile-wrap">
                                            Kod&nbsp;rabatowy:
                                        </DescriptionTerm>
                                        <DescriptionDetails className="mobile-wrap">
                                            <Discount
                                                readOnly={numberOfActiveStep === '4'}
                                                value={discount ? discount.value : null}
                                                code={discount ? discount.code : null}
                                                handleSetDiscount={(discount) => {
                                                    saveDiscount(discount);
                                                    setDiscount(discount);
                                                }}
                                                handleRemoveDiscount={() => {
                                                    removeDiscount();
                                                    setDataValue('discount', null);
                                                    setDiscount(null);
                                                    setProductsDiscounts([]);
                                                }}
                                                cartTotalPrice={cartTotalPrice}
                                            />
                                        </DescriptionDetails>
                                    </>
                                )}
                        </DescriptionList>
                    </DiscountContainer>
                )}
            {(numberOfActiveStep === '2' || numberOfActiveStep === '3')
                && (
                    <ButtonContainer>
                        <ButtonPreviousStep />
                    </ButtonContainer>
                )}
            <DeliveryPriceContainer>
                <DescriptionList>
                    {(numberOfActiveStep === '1' || numberOfActiveStep === '2' || numberOfActiveStep === '3')
                        && (
                            <>
                                <DescriptionTerm className={!onlyDigitalItems() ? '' : 'wide'}>
                                    {getDeliveryPriceMessage()}
                                </DescriptionTerm>
                                    {!onlyDigitalItems()
                                        && (data.deliveryPrice !== undefined || getAmountForFreeDelivery())
                                        && (
                                            <DescriptionDetails>
                                                <StyledPrice price={getDeliveryPrice()} />
                                            </DescriptionDetails>
                                        )}
                            </>
                        )}
                    {(numberOfActiveStep === '1' || numberOfActiveStep === '2')
                        && !data.deliveryType && !onlyDigitalItems()
                        && (
                            <>
                                <DescriptionTerm className={getAmountForFreeDelivery() ? '' : 'wide'}>
                                    {getAmountForFreeDelivery()
                                        ? 'Do darmowej dostawy brakuje:'
                                        : 'Otrzymujesz darmową dostawę'}
                                </DescriptionTerm>
                                {getAmountForFreeDelivery()
                                    && (
                                        <DescriptionDetails>
                                            <StyledPrice price={getAmountForFreeDelivery()} />
                                        </DescriptionDetails>
                                    )}
                            </>
                        )}
                    {(numberOfActiveStep === '2' || numberOfActiveStep === '3')
                        && (
                            <>
                                <DescriptionTerm>
                                    Wartość koszyka:
                                </DescriptionTerm>
                                <DescriptionDetails className="margin-right">
                                    <CartTotalPrice price={cartTotalPrice} />
                                </DescriptionDetails>
                            </>
                        )}
                    {numberOfActiveStep === '4'
                        && (
                            <>
                                <DescriptionTerm className="summary">
                                    Sposób wysyłki:
                                </DescriptionTerm>
                                <DescriptionDetails>
                                    <SelectedDelivery>
                                        <span>{getSelectedDelivery()}</span>
                                        {!data.deliveryType === undefined && (
                                            <>
                                                <span>|</span>
                                                <StyledPrice price={data.deliveryPrice} />
                                            </>
                                        )}
                                    </SelectedDelivery>
                                </DescriptionDetails>
                                <DescriptionTerm className="summary">
                                    Forma Płatności:
                                </DescriptionTerm>
                                <DescriptionDetails>
                                    {data.paymentType === ONLINE_TRANSFER && 'Przelew elektroniczny'}
                                    {data.paymentType === STANDARD_TRANSFER && 'Przelew tradycyjny'}
                                </DescriptionDetails>
                            </>
                        )}
                </DescriptionList>
            </DeliveryPriceContainer>
            <SummaryContainer>
                <DescriptionList className="start-align total-price">
                    <DescriptionTerm>Do&nbsp;zapłaty:</DescriptionTerm>
                    <DescriptionDetails>
                        <StyledPriceMain price={totalPrice} />
                    </DescriptionDetails>
                </DescriptionList>
                {numberOfActiveStep === '1'
                    && (
                        <NextStepButton onClick={handleNextStepButtonClick}>
                            Przejdź dalej
                        </NextStepButton>
                    )}
                {numberOfActiveStep === '2'
                    && (
                        <NextStepButton form="deliveriesAndPayments" type="submit" onClick={handleNextStepButtonClick}>
                            Przejdź dalej
                        </NextStepButton>
                    )}
                {numberOfActiveStep === '3'
                    && (
                        <NextStepButton form="contactDetails" type="submit" onClick={handleNextStepButtonClick}>
                            Przejdź dalej
                        </NextStepButton>
                    )}
                {numberOfActiveStep === '4'
                    && (
                        <NextStepButton
                            form="summary"
                            type="submit"
                            onClick={handleSubmitButton}
                            disabled={orderStatus !== 'ready'}
                        >
                            {orderStatus === 'pending' ? 'Przetwarzanie zamówienia…' : 'Zamawiam i płacę'}
                        </NextStepButton>
                    )}
            </SummaryContainer>
            {orderStatus === 'pending'
            && (
                <LoaderContainer>
                    <ClipLoader
                        size={300}
                        color="#00437E"
                        loading
                    />
                </LoaderContainer>
            ) }
        </Wrapper>
    );
};

export default CartFooterBar;
