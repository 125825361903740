import styled from 'styled-components';
import RowTitle from "../../RowTitle/RowTitle";
import {Title} from "../../RowTitle/RowTitleStyles";
import {CardText, CardTitle} from "./CardStyles";

export const animation = `
    background-image: linear-gradient(90deg, #e9e8e8, #a9a9a9);
    background-size: 400% 400%;
    background-position: 0 0;
    background-repeat: no-repeat;
 
    animation: move 1s ease infinite;
    
    @keyframes move {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
`;

export const EmptyRowTitle = styled(RowTitle)`
    & ${Title} {
        color: #a9a9a9;
        ${animation}
    }
`;
export const EmptyCardImg = styled.div`
    width: 35%;
    margin-right: 1.5rem;
    height: 200px; 
    ${animation}
`;
export const EmptyCardTitle = styled(CardTitle)`
    ${animation}
`;
export const EmptyCardText = styled(CardText)`
    display: flex;
    flex-direction: column;
    
    & span {
        margin: 2px 0;
        ${animation}
    }
`;