import React from 'react';
import {StyledContainer, RowHeader, TotalRow} from "./OrderPreviewStyles";
import {Col} from "react-bootstrap";
import {
    ContainerStyled,
    FormColumn,
    ProductsRow, RadioColumn, RadioHeaderContainer, Row,
    TotalColumn,
} from "../Cart/CartStyles";
import {
    EmptyDescription, Image,
    Item, Name,
    Summary
} from "../../Cart/CartItem/CartItemStyles"
import {EmptyCardText, EmptyCardTitle} from "../../ProductsList/Card/EmptyCardStyles";

export default function EmptyOrderPreview() {
    return <StyledContainer>
        <RowHeader className="empty">&nbsp;</RowHeader>
        <ProductsRow>
            <Col>
                <Item className="empty">
                    <EmptyDescription>
                        <Image className="empty"/>
                        <Name>
                            <EmptyCardText>
                                <span>&nbsp;</span>
                            </EmptyCardText>
                        </Name>
                    </EmptyDescription>
                    <Summary>
                    </Summary>
                </Item>
            </Col>
        </ProductsRow>
        <TotalRow className="empty">
            <TotalColumn className="d-flex align-items-center">
                <div>&nbsp;</div>
            </TotalColumn>
        </TotalRow>
        <Row className="pb-4">
            <ContainerStyled>
                <FormColumn>
                    <EmptyCardTitle className="mb-4">&nbsp;</EmptyCardTitle>
                    <EmptyCardText>
                        {[...Array(10)].map((value, i) => <span key={i} className="my-1">&nbsp;</span>)}
                    </EmptyCardText>
                </FormColumn>
                <FormColumn>
                    <EmptyCardTitle className="mb-4">&nbsp;</EmptyCardTitle>
                    <EmptyCardText>
                        {[...Array(8)].map((value, i) => <span key={i} className="my-1">&nbsp;</span>)}
                    </EmptyCardText>
                </FormColumn>
                <FormColumn>
                    <EmptyCardTitle className="mb-4">&nbsp;</EmptyCardTitle>
                    <EmptyCardText>
                        {[...Array(8)].map((value, i) => <span key={i} className="my-1">&nbsp;</span>)}
                    </EmptyCardText>
                </FormColumn>
            </ContainerStyled>
            <RadioHeaderContainer>
                <RadioColumn>
                    <EmptyCardTitle className="w-25 mb-4">&nbsp;</EmptyCardTitle>
                    <EmptyCardText className="w-25">
                        <span>&nbsp;</span>
                    </EmptyCardText>
                </RadioColumn>
            </RadioHeaderContainer>
            <RadioHeaderContainer>
                <RadioColumn>
                    <EmptyCardTitle className="w-25 mb-4 mt-2">&nbsp;</EmptyCardTitle>
                    <EmptyCardText className="w-25">
                        <span>&nbsp;</span>
                    </EmptyCardText>
                </RadioColumn>
            </RadioHeaderContainer>
        </Row>
        <TotalRow className="empty">
            <TotalColumn className="d-flex align-items-center">
                <div>&nbsp;</div>
            </TotalColumn>
        </TotalRow>
    </StyledContainer>;
}
