import styled from "styled-components";
import {Form} from "react-bootstrap";

export const Wrapper = styled(Form.Check)`
    & input {
        width: 1px;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
    }
    
    & label {
        cursor: pointer;
        position: relative;
        margin-bottom: 0;
    }
    
    & .placeholder {
        background-color: white;
        border: 2px #00AFFF solid;
        position: absolute;
        width: 20px;
        height: 20px;
        left: 0;
    }
    
    & .label {
        margin-left: 30px;
    }
    
    & input:checked + label .placeholder::after {
        content: '';
        border: 2px #00AFFF solid;
        position: absolute;
        left: 3px;
        right: 3px;
        bottom: 3px;
        top: 3px;
    }
    
    & a {
        text-decoration: underline;
        color: #00AFFF;
    }
`;