import React from 'react';

const CartContext = React.createContext({
    cartItemsCount: 0,
    setCartItemsCount: () => {},
    numberOfActiveStep: '1',
    setNumberOfActiveStep: () => {},
});

export default CartContext;
