import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGetProductUrl from '../../ProductsList/useGetProductUrl';
import { resetState, selectQuery } from '../searchSlice';
import {
    Image, Name, StyledProduct, OldPrice, Price, ImageContainer,
} from './ProductStyles';
import escapeDiacritics from '../../../tools/escapeDiacritics';

const Product = ({ product }) => {
    const query = useSelector(selectQuery);
    const dispatch = useDispatch();
    const getProductUrl = useGetProductUrl();
    const getProductSubpage = (product) => {
        if (!product.product_categories.length > 0) {
            return 0;
        }

        return product.product_categories[0].category.subpage;
    };

    const highlightQueryInName = (query, name) => {
        const queryWords = query.split(' ');
        let nameWithHighlightedText = name;

        if (queryWords.length > 0) {
            // eslint-disable-next-line array-callback-return
            queryWords.map((word) => {
                if (word.trim().length > 1) {
                    try {
                        const regexp = new RegExp(word, 'giu');
                        const upperToHyphenLower = (match) => `<strong>${match}</strong>`;
                        nameWithHighlightedText = nameWithHighlightedText.replace(regexp, upperToHyphenLower);

                        if (!nameWithHighlightedText.includes('<strong>')) {
                            nameWithHighlightedText = nameWithHighlightedText.split(' ').map(
                                (text) => (escapeDiacritics(text).match(regexp) ? `<strong>${text}</strong>` : text),
                            ).join(' ');
                        }
                    } catch (error) {
                        console.warn(error);
                    }
                }
            });
        }

        return nameWithHighlightedText;
    };

    return (
        <StyledProduct
            to={getProductUrl(getProductSubpage(product), product.slug)}
            onClick={() => { dispatch(resetState()); }}
        >
            <ImageContainer>
                <Image
                    src={`${process.env.REACT_APP_CDN_URL}${product.main_image?.path}`}
                    alt="obrazek"
                />
            </ImageContainer>
            <Name
                dangerouslySetInnerHTML={{ __html: `${highlightQueryInName(query, product.translations[0].name)}` }}
            />
            {product.price_before ? <OldPrice price={product.price_before} isOld /> : <div />}
            <Price price={product.price} />
        </StyledProduct>
    );
};

export default Product;
