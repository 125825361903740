import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const Title = styled.div`
    font-size: 32px;
    color: ${(props) => props.color};
    font-weight: 700;
    text-transform: capitalize;
    text-align: left;
    
    @media (max-width: 992px) {
        margin: 0 10px;
    }

    @media (max-width: 575px) {
        line-height: 1;
        text-transform: uppercase;
        margin: 0;
        font-size: 24px;
    }

    @media (min-width: 1280px) {
        margin-left: 0;
    }
`;

export const RowStyled = styled(Row)`
    margin: 0;
`;

export const Wrapper = styled.div`
    width: 50%;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
    align-items: center;
    padding: 15px 20px;
    
    @media (max-width: 992px) {
        left: 0rem;
        width: 100vw;
    }
`;
