import React from 'react';
import {
    Text,
    TransitionGroup,
    Wrapper
} from "./NotificationStyles";

export default function Notification({className, text, type}) {
    return (
        <TransitionGroup
            transitionName="notify"
            transitionAppear={true}
            transitionAppearTimeout={300}
            transitionEnter={false}
            transitionLeave={false}
        >
            <Wrapper className={className} type={type}>
                <Text>{text}</Text>
            </Wrapper>
        </TransitionGroup>
    );
}