import styled from "styled-components";

export const Rectangle = styled.div`
    width: 214px;
    height: 214px;
    background: ${props => props.color};
    
    @media (max-width: 1200px) {
        width: 150px;
        height: 150px;
    }
    
    @media (max-width: 992px) {
        display: none;
    }
`;

export const ErrorText = styled.div`
    width: 428px;
    height: 214px;
    text-align: left;
    position: relative;
    padding: 10px 0 0 50px;
    font-weight: 700;
    color: #00437e;
    
    & a {
        color: #00AFFF;
        position: absolute;
        bottom: 10px;
    }
    & a:hover {
        text-decoration: underline;
    }
    
    & span {
        font-size: 85px;
        line-height: 0.7;
    }
    
    & h3 {
        color: #00437e;
        font-weight: 700;
    }
    
    @media (max-width: 1200px) {
        width: 300px;
        height: 150px;
        
        & span {
            font-size: 55px;
        }
        
        & h3 {
            font-size: 20px;
        }
    }
    
    @media (max-width: 992px) {
        padding-left: 0;
    }
`;

export const ContactText = styled.div`
    font-weight: 700;
    color: #00437e;
    height: 214px;
    width: 214px;
    text-align: left;
    padding-left: 10px;
    
    & a {
        color: #00437e;
        text-decoration: underline;
    }
    & a:hover {
        text-decoration: none;
    }
    
    @media (max-width: 1200px) {
        width: 150px;
        height: 150px;
        
        font-size: 11px;
    }
    
    @media (max-width: 992px) {
        padding-left: 0;
        width: 400px;
    }
`;

export const Image = styled.div`
    background: url(${props => props.url}) no-repeat;
    background-position: center;
    background-size: contain;
    height: 70%;
    width: 100%;
`;