import React, { Suspense, lazy } from 'react'
const LazyAdmin = lazy(() => import('./Admin'))

export default function AsyncAdmin() {
    return (
        <div>
            <Suspense fallback={<div>Ładownie...</div>}>
                <LazyAdmin />
            </Suspense>
        </div>
    )
}
