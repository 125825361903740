export const certificationsPolish = [
    {
        url: 'pdf/Deklaracja_Zgodnosci_CE_Ozobot_BIT_Bialy_EduSense.pdf',
        title: 'Deklaracja_Zgodnosci_CE_Ozobot_BIT_Bialy_EduSense.pdf',
    },
    {
        url: 'pdf/Deklaracja_Zgodnosci_CE_Ozobot_BIT_Czarny_EduSense.pdf',
        title: 'Deklaracja_Zgodnosci_CE_Ozobot_BIT_Czarny_EduSense.pdf',
    },
    {
        url: 'pdf/Deklaracja_Zgodnosci_CE_Ozobot_BIT_Dwupak_EduSense.pdf',
        title: 'Deklaracja_Zgodnosci_CE_Ozobot_BIT_Dwupak_EduSense.pdf',
    },
    {
        url: 'pdf/Deklaracja_Zgodnosci_CE_Ozobot_EVO_Bialy_EduSense.pdf',
        title: 'Deklaracja_Zgodnosci_CE_Ozobot_EVO_Bialy_EduSense.pdf',
    },
    {
        url: 'pdf/Deklaracja_Zgodnosci_CE_Ozobot_EVO_Czarny_EduSense.pdf',
        title: 'Deklaracja_Zgodnosci_CE_Ozobot_EVO_Czarny_EduSense.pdf',
    },
    {
        url: 'pdf/Auraeko Baterpak Certyfikat Firma Przyjazna Naturze.pdf',
        title: 'Firma Przyjazna Naturze - odzysk opakowań',
    },
    {
        url: 'pdf/Auraeko OOSEiE Certyfikat Firma Przyjazna Naturze.pdf',
        title: 'Firma Przyjazna Naturze - odzysk sprzętu elektrycznego i elektronicznego',
    },
];

export const certificationsEnglish = [
    {
        url: 'pdf/EC_Declaration_of_Conformity_Ozboot_BIT_White_EduSense.pdf',
        title: 'EC_Declaration_of_Conformity_Ozboot_BIT_White_EduSense.pdf',
    },
    {
        url: 'pdf/EC_Declaration_of_Conformity_Ozboot_BIT_Black_EduSense.pdf',
        title: 'EC_Declaration_of_Conformity_Ozboot_BIT_Black_EduSense.pdf',
    },
    {
        url: 'pdf/EC_Declaration_of_Conformity_Ozboot_BIT_DualPack_EduSense.pdf',
        title: 'EC_Declaration_of_Conformity_Ozboot_BIT_DualPack_EduSense.pdf',
    },
    {
        url: 'pdf/EC_Declaration_of_Conformity_Ozboot_EVO_White_EduSense.pdf',
        title: 'EC_Declaration_of_Conformity_Ozboot_EVO_White_EduSense.pdf',
    },
    {
        url: 'pdf/EC_Declaration_of_Conformity_Ozboot_EVO_Black_EduSense.pdf',
        title: 'EC_Declaration_of_Conformity_Ozboot_EVO_Black_EduSense.pdf',
    },
];
