import { call, put, takeLatest } from 'redux-saga/effects';
import { getProductsFromQuery } from './getProducts';
import { fetchProducts, fetchProductsError, fetchProductsSuccess } from './searchResultsSlice';

function* fetchProductsHandler({ payload }) {
    try {
        const products = yield call(getProductsFromQuery, payload.query);
        yield put(fetchProductsSuccess(products));
    } catch (error) {
        yield put(fetchProductsError());
    }
}

export function* watchFetchSearchProducts() {
    yield takeLatest(fetchProducts.type, fetchProductsHandler);
}
