import styled, { css } from 'styled-components';

export const PriceMainSection = styled.div`
    display: flex;
    font-size: 24px;
    justify-content: flex-end;
    font-weight: 700;
    position: relative;
    z-index: 0;

    ${(props) => props.isOld && css`
        &:before {
            position: absolute;
            content: "";
            left: -8%;
            top: 50%;
            right: 0;
            border-top: 1px solid;
            border-color: rgb(250,118,53);
            width: 116%;

            -webkit-transform:rotate(-8deg);
            -moz-transform:rotate(-8deg);
            -ms-transform:rotate(-8deg);
            -o-transform:rotate(-8deg);
            transform:rotate(-8deg);
        }
        color: #B0B0B0 !important;
    `}
`;

export const StyledPriceMain = styled.span`
    font-size: 1em;
    line-height: 1;

    ${(props) => props.savings && css`
        color: #FA7635 !important;
    `}
`;

export const PriceMainCurrencyContainer = styled.span`
    margin-left: 0.1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    ${(props) => props.savings && css`
        color: #FA7635 !important;
    `}
`;

export const PriceMainCurrency = styled.span`
    font-size: 0.4em;
    line-height: 1;
    padding: 0.02em 0;
`;

export const Description = styled.span`
    margin-right: 0.3em;
    font-size: 0.9em;
    align-self: center;
`;
