import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledEvent = styled(Link)`
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 16px;
    justify-items: start
    align-items: center;
    color: #00437E;
    border-bottom: 1px solid #dddddd;
    padding: 8px;
`;

export const EventDate = styled.div`
    color: #39B54A;
    line-height: 1;
`;

export const Month = styled.div`
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
`;

export const Day = styled.div`
    font-size: 40px;
    font-weight: 400;
`;

export const EventName = styled.p`
    margin: 0;
    font-size: 18px;
    line-height: 1;
`;
