import { Col, Form, Modal } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import Container from '../Container/Container';
import { WarningMessage } from '../Input/InputStyles';
import PriceMain from '../PriceMain/PriceMain';

export const ContainerStyled = styled(Container)`
    display: flex;
    padding: 40px;
    min-height: 0;
    background-color: #F7F7F7;
    border-bottom: #FFFFFF 2px solid;
    
    @media (max-width: 991.98px) {
        flex-direction: column;
    }
`;

export const DeliveriesContainer = styled(ContainerStyled)`
    @media (max-width: 991.98px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const SectionHeader = styled.h2`
    margin: 0;
    padding: 0 40px 0 0;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    color: #00437E;

    @media (max-width: 1250px) { 
        margin-bottom: 30px;
    }

    @media (max-width: 991.98px) {
        flex-basis: 100%;
    }
`;

export const RadioColumn = styled(Col)`
    flex: 1 1 auto;
    flex-basis: 0;
    min-width: 0;
    padding: 0;
    font-size: 18px;
`;

export const DeliveriesColumn = styled(RadioColumn)`
    @media (max-width: 991.98px) {
        flex-basis: 51%;
    }

    @media (max-width: 575.98px) {
        flex-basis: 100%;
    }
`;

export const Radio = styled(Form.Check)`
    padding: 0;

    & input {
        width: 1px;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
    }
    
    & input + label > * {
        display: block;
    }
    
    & .placeholder {
        margin-top: 3px;
        background-color: white;
        margin-right: 10px;
        cursor: pointer;
        width: 23px;
        height: 23px;
        border: 2px #00437E solid;
        border-radius: 50%;
        position: relative;
        display: block;
    }
    
    & input:checked + label .placeholder::after {
        content: '';
        position: absolute;
        left: 3px;
        right: 3px;
        top: 3px;
        bottom: 3px;
        border: 2px #00437E solid;
        background-color: #00437E;
        border-radius: 50%;
    }
    
    & .fit {
        flex: 0;
    }
    
    & label {
        display: flex;
        cursor: pointer;
    }
    
    & label .header {
        font-size: 35px;
        line-height: 1;
        color: #00437E;
        font-weight: 700;
        display: inline-flex;
        white-space: nowrap;

        @media (max-width: 1100px) {
            font-size: 30px;
        }

        @media (max-width: 575.98px) { 
            font-size: 24px;
            line-height: 1;
        }
    }
    
    & label .price {
        color: #00AFFF;
        font-size: 28px;
        font-weight: 700;
        text-align: left;
        line-height: 1.1;
    }

    & label .sub {
        color: #00437E;
        font-weight: 700;
        font-size: 16px;
        text-align: left;
    }

    & label .sub-payments {
        color: #00437E;
        font-weight: 700;
        font-size: 14px;
        text-align: left;
        line-height: 1.1;
    }
    
    & input:disabled + label {
        cursor: default;
    }
    
    & input:disabled + label .placeholder {
        border: 2px gray solid;
        cursor: default;
        background-color: #e9ecef;
    }
    
    & input:disabled + label .header,
    & input:disabled + label .price,
    & input:disabled + label .sub {
        color: gray;
        cursor: default;
    } 
    
    @media screen and (max-width: 1250px) {
        margin-bottom: 10px;
    }
`;

export const InpostMessageContainer = styled(Col)`
    display: flex;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 1100px) {
        justify-content: flex-end;
        padding-right: 0;
    }

    @media (max-width: 991.98px) {
        display: block;
        padding-left: 48px;
        flex-basis: 49%;
    }

    @media (max-width: 575.98px) {
        flex-basis: 100%;
    }
`;

export const InpostMessage = styled.p`
    margin: 0;
    color: #00AFFF;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    line-height: 1.2;
    transition: font-size 1s;
    ${(props) => props.validated && css`
        font-size: 18px;
        color: red;

        &:hover {
            color: #00AFFF;
        }
    `}
`;

export const StyledInpostMessage = styled(InpostMessage)`
    color: #39B54A;

    &:hover {
        color: #00AFFF;
    }
`;

export const InpostAddress = styled.span`
    color: #00437E;
`;

export const StyledModal = styled(Modal)`
    & div.modal-dialog {
        max-width: 90vw !important;
        height: 90%;

        @media(max-width: 767.98px) {
            max-width: 100vw !important;
        }
    }

    & div.modal-content {
        width: 100%;
        height: 100%;
    }
`;

export const StyledWarningMessage = styled(WarningMessage)`
    padding-left: 48px;
`;

export const StyledPrice = styled(PriceMain)`
    justify-content: flex-start;
    font-size: 28px;
`;

export const MessageContainer = styled.div`
    display: block;
`;

export const LoaderContainer = styled.div`
    display: block;
    margin: 0 auto;
    flex-grow: 5;
`;
