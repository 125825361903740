import styled from "styled-components";

export const SearchbarWrapper = styled.div`
    background-color: #F0F0F0;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    & input{
        background-color: #fff;
        padding: 0.5rem 1rem;
        max-width: 90%;
        width: 100%;
        font-weight: 700;
        border: none;
        opacity: 0.7;
        font-size: 1.2rem;
    }

    & button{
        background-color: #fff;
        padding: 1rem 0.5rem;
        max-width: 12rem;
        width: 100%;
        font-weight: 700;
        text-decoration: none;
        background-color: #00437E;
        color: #fff;
        padding: 0.8rem;
        text-align: center;
        text-transform: uppercase;
        font-size: 2rem;
        border: none;
        outline: none;
        
        &:focus {
            box-shadow: none;
        }
        
        &:hover {
            color: #00437E;
            background-color: white;
            border-color: white;
        }
        
        &:hover * {
            border-color: white;
        }
    }
`;
