import styled, { css } from 'styled-components';

export const StyledEmployee = styled.div`
    margin: ${({ enableExtraStyles }) => (enableExtraStyles ? '0 auto' : '0')};

    @media (max-width: 992px) {
        margin: 0 auto;
    }

    @media (max-width: 575px) {
        width: 70%;
        min-height: 400px;
    }
`;

const textStyles = css`
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 1.3;

    @media (max-width: 575px) {
        font-size: 18px;
        line-height: 1.4;
        margin: 0 -32px;
        z-index: 11;
    }
`;

export const Text = styled.p`
    ${textStyles};
`;

export const Link = styled.a`
    ${textStyles};
    display: block;
    color: white;

    &:hover {
        color: #00437E;
    }
`;

export const Image = styled.img`
    width: 150px;
    height: 150px;
    border: 2px solid #00AFFF;
    border-radius: 50%;
    margin-bottom: 25px;

    @media (max-width: 575px) {
        width: 100%;
        height: auto;
    }
`;
