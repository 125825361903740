import { call, put, takeLatest } from 'redux-saga/effects';
import levenshtein from 'fast-levenshtein';
import { getNoAuth } from '../../api/Api';
import {
    fetchSearch, fetchSearchError, fetchSearchSuccess, showResults,
} from './searchSlice';

const LEVENSHTEIN_MAX_DIFFERENCE = 2;
const PRODUCT_LIMIT = 8;

function* fetchProductsHandler({ payload }) {
    const { query } = payload;
    if (query.split('-').some((word) => levenshtein.get(word, 'warsztat') <= LEVENSHTEIN_MAX_DIFFERENCE)) {
        try {
            const events = yield call(getNoAuth, 'events/active');
            yield put(fetchSearchSuccess({
                events: events.slice(0, 8),
                products: [],
            }));
        } catch (error) {
            yield put(fetchSearchError());
        }
    } else {
        try {
            const products = yield call(getNoAuth, `products?search=${query}&limit=${PRODUCT_LIMIT}`);
            yield put(fetchSearchSuccess({
                products,
                events: [],
            }));
        } catch (error) {
            yield put(fetchSearchError());
        }
    }

    yield put(showResults());
}

export function* watchFetchSearch() {
    yield takeLatest(fetchSearch.type, fetchProductsHandler);
}
