import styled from "styled-components";
import {Card as BootstrapCard} from "react-bootstrap";
import {Button} from "../../ButtonAddToCart/ButtonAddToCartStyles";
import {animation} from "../../ProductsList/Card/EmptyCardStyles";

export const CardWrapper = styled.div`
    width: 100%;
    padding: 0px 10px 10px;
    
    @media (max-width: 992px) {
        flex: 1 0 auto;
        max-width: 100%;
        padding: 0 10px 10px;
        width: 100%;
    }
    
    @media (min-width: 1280px) {
        padding-left: 0;
        padding-right: 0;
    }

    .text-green {
        color: #39b54a !important;
    }
    
    .text-blue {
        color: #00437E;
    }
    
    .font-weight-600 {
        font-weight: 600;
    }
`;

export const CardStyled = styled(BootstrapCard)`
    background-color: #F0F0F0;
    height: 100%;
    position: relative;
    text-align: left;
    color: #00437E;
    border: none;
    border-radius: 0;
    padding: 20px;
`;

export const EventName = styled.div`
    font-weight: 700;
    font-size: 1.7em;
    line-height: 1.1em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    
    &:hover {
        text-decoration: underline;
    }
    
    @media (max-width: 992px) {
        text-overflow: unset;
        white-space: normal;
    }
    
    @media (max-width: 600px) {
        font-size: 1.3rem;
    }
`;

export const EventLeftColumn = styled.div`
    font-size: 2.3rem;
    line-height: 2.3rem;
    margin-right: 25px;
    
    &.empty {
        height: 35px;
        width: 35px;
        margin: 20px 20px;
    }
    
    @media (max-width: 600px) {
        display: none !important;
    }
`;

export const EventMiddleColumn = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const Month = styled.div`
    text-transform: uppercase;
    font-size: 1.5rem;
`;

export const Day = styled.div`
    text-align: center;
    font-size: 2.5rem;
`;

export const EventLink = styled.a`
    color: #00437E;
    
    &:hover {
        color: #00437E;
    }
    
    & a:hover {
        text-decoration: underline;
    }
`;

export const EventLinkDisabled = styled.div`
    color: #00437E;
    
    & ${EventName}:hover {
        text-decoration: none;
    }
`;

export const EventAddressLink = styled.a`
    color: #00437E;
    
    & div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    
    &:hover {
        text-decoration: underline;
    }
`;

export const EventRightColumn = styled.div`
    @media (max-width: 992px) {
        width: 100px;
        margin: 0 15px 0 0;
    }
`;

export const EventButton = styled(Button)`
    padding: 15px;
    width: 100%;
    
    & i {
       font-size: 1.25rem;
       display: none;
       margin-right: 10px;
    }
    
    @media (max-width: 992px) {
        & i {
           display: block; 
           font-size: 1.5rem;
           margin-right: 0;
        }
        
        & span {
            display: none;
        }
    }
    
    &.completed {
        background-color: #bebebe;
        cursor: initial; 
    }
    
    &.completed:hover {
        background-color: #bebebe;
        color: #ffff;
    }
    
    &.completed:active {
        background-color: #bebebe !important;
        color: #ffff !important;
    }
    
    &.buy {
        background-color: #39b54a;
    }
    
    &.buy:hover {
        background-color: #ffff;
        color: #39b54a;
    }
    
    &.buy:active {
        background-color: #ffff;
        color: #39b54a;
    }
    
    &.date-changed {
        opacity: 1;
        background-color: #ff6600;
    }
    
    &.date-changed:hover {
        background-color: #ff6600;
        color: #ffff;
    }
    
    &.date-changed:active {
        background-color: #ff6600 !important;
        color: #ffff !important;
    }
`;

export const TextPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    
    & span {
        margin: 2px 0;
        width: 100%;
        ${animation}
    }
`;
