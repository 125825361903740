import React from 'react';
import dompurify from 'dompurify';
import {
    Wrapper, RowStyled, LeftPart, RightPart,
} from './TickerBarStyles';

const TickerBar = ({ breakingNews }) => {
    const sanitizer = dompurify.sanitize;

    const textRight = breakingNews !== null ? sanitizer(breakingNews?.content) : '';

    return breakingNews && breakingNews?.is_visible && (
        <RowStyled>
            <Wrapper className="w-100 row">
                <LeftPart
                    backgroundcolor={breakingNews !== null ? sanitizer(breakingNews?.background_color) : '#fcb21a'}
                    color={breakingNews !== null ? sanitizer(breakingNews?.color) : '#000000'}
                    xs={4}
                    md={3}
                    dangerouslySetInnerHTML={{ __html: breakingNews !== null ? sanitizer(breakingNews?.title) : '' }}
                />
                <RightPart xs={8} md={9}>
                    <div className="rightInside" dangerouslySetInnerHTML={{ __html: `${textRight} ${textRight}` }} />
                    <div className="rightInside" dangerouslySetInnerHTML={{ __html: `${textRight} ${textRight}` }} />
                </RightPart>
            </Wrapper>
        </RowStyled>
    );
};

export default TickerBar;
