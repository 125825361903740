import React, { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import latinize from 'latinize';
import {
    Bar,
    BarWrapper, CategoriesNavigation,
    CategoryColor,
    CategoryLabel,
    CategoryLink, CategoryLinkInProduct, EmptyCategory,
    EmptyCategoryLabel, LeftScrollButton, RightScrollButton,
    RowStyled,
    SearchWrapper,
} from './CategoriesBarStyles';
import AppContext from '../../context/app-context';
import Search from '../Search/Search';
import { selectIsSearchActive } from '../Search/searchSlice';

const SCROLL_OFFSET = 270;

const CategoriesBar = ({ categories, language }) => {
    const appContext = useContext(AppContext);
    const isSearchActive = useSelector(selectIsSearchActive);

    const ref = useRef();
    const scroll = (scrollOffset) => {
        ref.current.scrollTo({
            top: 100,
            left: ref.current.scrollLeft + scrollOffset,
            behavior: 'smooth',
        });
    };
    const isActivePageProductPage = appContext.routePage.includes(':product');
    const getRoutePath = appContext.routePage.replace('/:product', '');
    const getCategorySlugFromName = (name) => `#${latinize(name).replace(/\s/g, '-').toLowerCase()}`;

    return (
        <RowStyled>
            <BarWrapper className={appContext.breakingNews?.is_visible ? 'with-news' : ''}>
                <Bar>
                    <LeftScrollButton onClick={() => scroll(-SCROLL_OFFSET)}>
                        <i className="fa fa-chevron-left" />
                    </LeftScrollButton>
                    <CategoriesNavigation ref={ref}>
                        {categories.map(
                            (category) => category.translations.filter((i) => i.language === language).map(
                                ({ name, href }) => (isActivePageProductPage ? (
                                    <CategoryLinkInProduct
                                        key={category.id}
                                        to={`${getRoutePath}${getCategorySlugFromName(name)}`}
                                    >
                                        <CategoryColor color={category.color} />
                                        <CategoryLabel>{name}</CategoryLabel>
                                    </CategoryLinkInProduct>
                                ) : (
                                    <CategoryLink
                                        key={category.id}
                                        href={href || getCategorySlugFromName(name)}
                                    >
                                        <CategoryColor color={category.color} />
                                        <CategoryLabel>{name}</CategoryLabel>
                                    </CategoryLink>
                                )),
                            ),
                        )}
                        {categories.length === 0 && [...Array(4)].map((a, index) => (
                            <EmptyCategory key={index}>
                                <CategoryColor color="#a9a9a9" />
                                <EmptyCategoryLabel />
                            </EmptyCategory>
                        ))}
                    </CategoriesNavigation>
                    <RightScrollButton onMouseDown={() => scroll(SCROLL_OFFSET)}>
                        <i className="fa fa-chevron-right" />
                    </RightScrollButton>
                </Bar>
                <SearchWrapper isSearchActive={isSearchActive}>
                    <Search />
                </SearchWrapper>
            </BarWrapper>
        </RowStyled>
    );
};

export default CategoriesBar;
