import styled from 'styled-components';
import { Col } from 'react-bootstrap';

export const Description = styled.div`
    color: white;
    background-color: ${(props) => (props.success ? '#65BA00' : '#00437E')};
    padding: 16.66667px 25px;
    font-weight: 700;
    line-height: 0.9;
    font-size: 20px;
    margin-bottom: 50px;
`;

export const RadioColumn = styled(Col)`
    flex: 1 1 auto;
    flex-basis: 0;
    min-width: 0;
    padding: 0;
    font-size: 18px;
`;
