import { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ANY_PAGE_THAT_WILL_BE_REPLACED_IN_CONTENT } from '../anyPage';
import AppContext from '../context/app-context';

export default withRouter(({ children, location }) => {
    const appContext = useContext(AppContext);
    useEffect(() => {
        const page = children.props.children.find((e) => e.props
            && e.props.path
            && location.pathname.startsWith(
                e.props.path
                    .replace(`:${ANY_PAGE_THAT_WILL_BE_REPLACED_IN_CONTENT}`, '.+')
                    .replace(':product', ''),
            ));
        appContext.setRoutePage(page ? page.props.path : null);
        appContext.setPage(page ? page.key : null);
    }, [location, children, appContext]);

    return children;
});
