import styled from 'styled-components';
import { Card as BootstrapCard } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button } from '../../ButtonMore/ButtonMoreStyles';
import { Button as ButtonAddToCard } from '../../ButtonAddToCart/ButtonAddToCartStyles';
import PriceMain from '../../PriceMain/PriceMain';

export const CardWrapper = styled.div`
    max-width: 50%;
    padding: 0px 10px 10px;

    &:nth-child(even) {
        padding-left: 0;
    }
    
    @media (max-width: 992px) {
        flex: 1 0 auto;
        max-width: 100%;
        padding: 0 10px 10px;
        width: 100%;
        
        &:nth-child(even) {
            padding-left: 10px;
        }
    }

    @media (min-width: 1280px) {
        padding-left: 0;

        &:nth-child(even) {
            padding-right: 0;
        }
    }
`;
export const CardTitle = styled(BootstrapCard.Title)`
    font-weight: 700;
    font-size: 1.7em;
    line-height: 1em;
`;
export const CardStyled = styled(BootstrapCard)`
    background-color: #F0F0F0;
    padding: 30px;
    height: 100%;
    position: relative;
    text-align: left;
    color: #00437E;
    border: none;
    border-radius: 0;

    @media (max-width: 575px) {
        padding-right: 40px;
    }
    
    &.black-week {
        color: black;
    }
    
    &.black-week ${CardTitle} {
        color: black;
    }
    
    &.black-week ${Button} {
        background-color: black;
    }
    &.black-week ${Button}:hover {
        color: black;
        background-color: white;
    }
    
    &.black-week ${ButtonAddToCard}  {
        background-color: #FD0606 !important;
    }
    &.black-week ${ButtonAddToCard}:hover {
        color: #FD0606;
        background-color: white !important;
    }
    
    &.christmas {
        color: black;
    }
    &.christmas ${CardTitle} {
        color: black;
    }    
    &.christmas ${ButtonAddToCard}  {
        background-color: #008626 !important;
    }
    &.christmas ${ButtonAddToCard}:hover {
        color: #008626;
        background-color: white !important;
    }
`;
export const CardImg = styled(LazyLoadImage)`
    width: 100%;

    @media (max-width: 575px) {
        padding: 20px;
    }
`;
export const CardLink = styled(Link)`
    width: 40%;
    padding-right: 1.5rem;
    color: #00437E;
    
    &:hover {
        color: #00437E;
    }
`;

export const CardSectionContainer = styled.div`
    height: 100%;   
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    color: #00437E;
    
    &:hover {
        color: #00437E;
    }
`;

export const CardBody = styled(BootstrapCard.Body)`
    max-width: 60%;
    padding: 0;
`;
export const CardBodyNarrow = styled(BootstrapCard.Body)`
    padding: 0;
`;
export const CardTitleLink = styled(Link)`
    color: #00437E;
    
    &:hover {
        color: #00437E;
    }
`;
export const CardText = styled(BootstrapCard.Text)`
    margin-top: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15px;
    line-height: 1.4;
`;
export const PriceElement = styled.div`
    font-weight: 700;
    font-size: 27px;
    word-break: keep-all;
    line-height: normal;
    width: 40%;

    @media(max-width: 576px){
        max-width: 50%;
    }
`;
export const PriceElementFullWidth = styled.div`
    font-weight: 700;
    font-size: 27px;
    word-break: keep-all;
    line-height: 1.05;
    width: 100%;
    padding-top: 8px;
    margin-bottom: -10px;
`;
export const ButtonsWrapper = styled.div`
    margin-top: 20px;
`;
export const CardFooter = styled(BootstrapCard.Footer)`
    padding: 0;
    margin-top: 20px;
`;
export const PreorderText = styled.div`
    margin-top: 5px;
    color: #00AFFF;
    align-self: flex-start;
    font-family: 'Roboto Condensed',sans-serif;
    line-height: 1;
    font-size: 15.4px;
    text-decoration: none;
    font-weight: 800;
`;
export const StockText = styled.div`
    margin-top: 5px;
    color: #F50303;
    align-self: flex-start;
    font-family: 'Roboto Condensed',sans-serif;
    line-height: 1;
    font-size: 15.4px;
    text-decoration: none;
    font-weight: 800;
`;

export const StyledPriceMain = styled(PriceMain)`
    font-size: 50px;
    margin-top: 2px;
    margin-bottom: 1px;

    @media(max-width: 575px){
        margin: 12px 20px 0 0;
    }
`;

export const StyledPrice = styled(PriceMain)`
    font-size: 20px;

    @media(max-width: 575px){
        margin-right: 20px;
    }
`;
