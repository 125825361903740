import styled, { css } from 'styled-components';
import Navbar from 'react-bootstrap/Navbar';
import { Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const SaleContainer = styled.div`
    width: 100%;
    height: 57rem;
    position: absolute;
    
    @media (max-width: 991px) {
        height: 71rem;
    }
    
    @media (max-width: 767px) {
        height: 57rem;
    }
    
    @media (max-width: 690px) {
        height: 44rem;
    }
    
    @media (max-width: 540px) {
        height: 49rem;
    }
    
    & a {
        display: block;
        width: 100%;
        height: 100%;
    }
`;

export const NavWrapper = styled(Navbar)`
    z-index: 16777251;
    color: #fff;
    font-weight: bold;
    font-size: 1.2em;
    width: 80rem;
    max-width: 80rem;
    padding: 0;
    max-height: 5.5rem;
    display: flex;
    padding-right: 0px !important;

    & .navbar-toggler{
        border: none;
        margin-right: 16px !important;
    }
    
    &.with-breaking-news{
        padding-top: 2.7rem;
        max-height: 8.3rem;
    }

    @media (max-width: 1280px){
        width: 100%;
        margin: 0;
    }

    @media (max-width: 800px){
        max-width: 100%;
    }

    @media (max-width: 575px) {
        & .navbar-toggler {
            margin-right: 4px !important;
        }
    }
`;

export const CartElement = styled.div`
    z-index: 10;
    
    @media (max-width: 991px) {
        margin-left: auto;
        position: absolute;
        right: 60px;
    }
`;

export const CartBadge = styled.div`
    top: -6px;
    right: -12px;
    position: absolute;
    color: white;
    background-color: red;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    padding: 3px;
    min-width: 22px;
    border-radius: 11px;
    white-space: nowrap;
    vertical-align: baseline;
`;

export const HeaderContainer = styled(Container)`
    overflow: hidden;
    position: initial;
    min-height: 5.5rem;
    max-width: 100%;
    ${({ isHomePage }) => isHomePage
        && `background-image: url(${process.env.REACT_APP_CDN_URL}/christmas/swieta_genibot.jpg);`}
    z-index: 100;
    
    &.with-breaking-news{
        height: 8.2rem;
        max-height: 8.2rem;
    }
`;

export const NavElement = styled(Link)`
    margin-left: 12px;
    color: ${(props) => (props.color ? props.color : '#fff')};
    font-weight: bold;
    font-size: 22px;
    padding: 1.5rem .5rem;
    position: relative;

    &:hover {
        color: ${(props) => (props.color ? props.color : '#00AFFF')};
    }
  
    &:before {
        content: "";
        position: absolute;
        height: 2px;
        bottom: 20px;
        width: 0;
        background-color: ${(props) => (props.color ? props.color : '#00AFFF')};
        transition: all 0.3s ease-in-out 0s;
        left: 50%;
    }
  
    &.active{
        color: #00AFFF;
    }
  
    &:hover:before {
        width: 80px;
        left: calc((100% - 80px) / 2);
    }

    @media (max-width: 1280px){
        font-size: 1em;
    }

    @media (max-width: 991px){
        font-size: 1.2em;

        &:hover:before {
            width: 10%;
            left: 45%;
        }
    }

    @media (max-width: 575px) {
        padding: 8px;

        &:before {
            bottom: 8px;
        }

        &:hover:before {
            width: 20%;
            left: 40%;
        }
    }

    @media (max-height: 500px) {
        padding: 4px;

        &:before {
            bottom: 4px;
        }
    }
`;
export const CartNavElement = styled(NavElement)`
    display: block;
    margin: 0 40px 0 20px;
    
    &:before {
        bottom: 20px;
    }

    @media (max-width: 1280px) {
        margin: 0 20px 0 10px;
    }
`;

export const CartIcon = styled.div`
    width: 28px;
    height: 28px;
    background-image: url("${process.env.REACT_APP_CDN_URL}/kosz_edu.svg");
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    
    ${CartNavElement}:hover & {
        background-image: url("${process.env.REACT_APP_CDN_URL}/kosz_edu_light_blue.svg");
    }
`;

export const NavSeparator = styled.div`
    margin-left: 10px;
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    padding-left: .5rem;
    padding-right: .5rem;
`;

export const TileContainer = styled.div`
    z-index: 1;
    position: relative;
    margin: 13.5rem auto;
    margin-bottom: 0;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    justify-content: center;
    padding-left: 1rem;
    
    .with-breaking-news &{
        margin-top: 16.2rem;
     }
    
    @media (max-width: 1200px){
        margin-top: 6rem;
    }
    @media (min-width: 992px){
        width: 55rem;
        height: 40rem;
    }
    @media (max-width: 991px){
        width: 42rem;
    }
    @media (max-width: 767px){
        width: 41rem;
    }
    @media (max-width: 690px) {
        width: 90vw;
        height: 30rem;
    }
    @media (max-width: 540px) {
        width: 90vw;
        height: 32rem;
    }
    
    @media (min-width: 1200px) and (max-width: 1310px){
        padding-left: 12.5rem;
        margin-left: 0;
        width: 68.5rem;
    }
`;

export const NavToggle = styled(Navbar.Toggle)`
    margin-right: 1rem;
    
    ${({ isexpanded }) => isexpanded && css`
        background-color: red;
    `};
`;

export const EventVideoWrapper = styled.div`
    position: absolute;
    z-index: 0;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    overflow: hidden;
`;

export const CloseIcon = styled.i`
    font-size: 1.5em;
    color: white;
    width: 30px;
    height: auto;
`;

export const HamburgerIcon = styled.i`
    font-size: 1.5em;
    color: white;
    width: 30px;
    height: auto;
`;

export const SearchWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 106px;
    transition: 0.2s all;
    
    &:focus-within {
        right: calc((100% - 640px) / 2);
    }

    ${(props) => props.isSearchActive && 'right: calc((100% - 640px) / 2);'}

    @media (max-width: 991px) {
        width: 95%;
        margin: 16px auto 0;
        position: unset;
        
        &:focus-within {
            right: 0;
        }
        
        ${(props) => props.isSearchActive && 'right: 0'}
    }
`;

export const LogoImage = styled.img`
    margin: ${({ isCompact }) => (isCompact ? '10px' : '20px')};

    @media (max-width: 575px) {
        margin: 10px;
    }
`;

export const NavbarCollapse = styled(Navbar.Collapse)`
    @media (max-width: 991px) {
        padding-bottom: 30px;
    }
`;

export const MobileNav = styled(Nav)`
    display: none;

    @media (max-width: 767px) {
        display: flex;
    }
`;
