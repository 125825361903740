import styled from 'styled-components';
import { StyledCheckbox } from '../SummaryStyles';

export const StyledPreorder = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    margin: 40px;
`;

export const Header = styled.h3`
    color: #00437E;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 16px;
    justify-content: left;
    text-align: left;
    font-weight: 700;
    font-size: 1.7em;
    line-height: 1em;
`;

export const StyledSplitCheckbox = styled(StyledCheckbox)`
    padding: 10px 0 0;
    align-items: center;
    color: #00AFFF;

    & .label {
        font-size: 16px;
    }
`;

export const PreorderInfo = styled.p`
    color: #00AFFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    margin: 0;
    max-width: 550px;
`;
