import styled, { css } from 'styled-components';
import {
    Col, Nav, Navbar, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const BarWrapper = styled(Col)`
    margin: auto;
    padding: 18px 0 8px 88px;
    top: 5.5rem;
    width: 80rem;
    font-size: 18px;
    max-width: 80rem;
    z-index: 1;
    background-color: white;
    display: flex;
    justify-content: space-between;
    position: fixed;
    
    &.with-news {
        top: 8.2rem;
    }
    @media (max-width: 1280px) {
        left: 0rem;
        width: 100%;
        max-width: 100%
    }
    
    @media (max-width: 992px) {
        max-width: 100%;
        padding: 0;
        flex-wrap: wrap;
    }
`;

export const Bar = styled(Navbar)`
    padding: 0;
    overflow-x: hidden;
    flex-grow: 1;
`;

export const CategoriesNavigation = styled(Nav)`
    overflow-x: hidden;
    flex-grow: 1;
    display: flex;

    @media(max-width: 575px) {
        overflow-x: auto;
    }
`;

const categoryLinkStyles = css`
    font-weight: bold;
    text-transform: uppercase;
    transition: 0.3s transform;
    color: #00437E !important;
    min-width: 90px;
    width: 90px;

    &:hover {
        opacity: 0.8;
    }
`;

export const CategoryLink = styled(Nav.Link)`
    ${categoryLinkStyles}
`;

export const CategoryLinkInProduct = styled(Link)`
    ${categoryLinkStyles}
    padding: 8px;
`;

export const CategoryLabel = styled.span`
    display: inline-block;
    font-size: 11px;
    font-weight: 700;
    line-height: 1;
    margin-top: 5px;
    font-family: 'Roboto Condensed';
`;

export const CategoryColor = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin: 0 auto;
`;

export const RowStyled = styled(Row)`
    margin: 0;
`;

export const EmptyCategoryLabel = styled.div`
    margin: 7px auto;
    height: 12px;
    width: 60px;
    background-color: #a9a9a9;
`;

const activeSearchWrapper = `
    transform: translateX(-320px);

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: -320px;
        background-image: linear-gradient(90deg, hsla(0,0%,100%,0) 0, #fff 50%);
    }

    @media (max-width: 1280px) {
        transform: translateX(calc((640px - 100vw) / 2));
        
        &:before {
            left: calc((640px - 100vw) / 2);
            background-image: linear-gradient(90deg, hsla(0,0%,100%,0) 0, #fff 10%);
        }
    }

    @media (max-width: 992px) {
        transform: none;

        &:before {
            height: 0;
        }
    }
`;

export const SearchWrapper = styled.div`
    flex-shrink: 2;
    transition: 0.2s transform;

    @media (max-width: 992px) {
        width: 95%;
        margin: 8px auto;
        order: -1;
    }

    &:focus-within {
        ${activeSearchWrapper}
    }

    ${(props) => props.isSearchActive && activeSearchWrapper};
`;

export const ScrollButton = styled.button`
    border: none;
    outline: none;
    color: #00437E;
    background: none;
    padding: 20px;
    
    &:hover {
        color: #00AFFF;
    }

    &:focus {
        outline: none;
    }
    
    @media (min-width: 1280px) {
        display: none;
    }
`;

export const LeftScrollButton = styled(ScrollButton)`
    left: 0;
`;

export const RightScrollButton = styled(ScrollButton)`
    right: 0;
`;

export const EmptyCategory = styled.div`
    min-width: 80px;
    padding: 8px 8px 9px;
`;
