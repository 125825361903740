import React from 'react';
import { Form } from 'react-bootstrap';
import {
    RadioColumn, Radio, SectionHeader, StyledWarningMessage,
} from '../Deliveries/DeliveriesStyles';
import { Info, PaymentContainer, StyledContainer } from './PaymentsStyles';

export const ONLINE_TRANSFER = 'online';
export const STANDARD_TRANSFER = 'standard';

const Payments = ({ setDataValue, totalCartPrice, data }) => (
    <StyledContainer>
        <PaymentContainer>
            <SectionHeader>Forma płatności:</SectionHeader>
            <RadioColumn>
                <Radio id="payment1">
                    <Form.Check.Input
                        onChange={
                            (e) => setDataValue('paymentType', e.currentTarget.checked && ONLINE_TRANSFER)
                        }
                        checked={data.paymentType === ONLINE_TRANSFER}
                        name="payment"
                        type="radio"
                        required
                    />
                    <Form.Check.Label>
                        <RadioColumn className="fit">
                            <span className="placeholder" />
                        </RadioColumn>
                        <RadioColumn className="pl-5">
                            <RadioColumn className="header">Przelew elektroniczny</RadioColumn>
                            <RadioColumn className="sub-payments">
                                Płatność natychmiastowa (przelew elektroniczny, karta).
                            </RadioColumn>
                        </RadioColumn>
                    </Form.Check.Label>
                    <StyledWarningMessage type="invalid">Wybierz formę płatności</StyledWarningMessage>
                </Radio>
            </RadioColumn>
            <RadioColumn>
                <Radio id="payment2">
                    <Form.Check.Input
                        onChange={
                            (e) => setDataValue('paymentType', e.currentTarget.checked && STANDARD_TRANSFER)
                        }
                        checked={data.paymentType === STANDARD_TRANSFER}
                        name="payment"
                        type="radio"
                        required
                    />
                    <Form.Check.Label>
                        <RadioColumn className="fit">
                            <span className="placeholder" />
                        </RadioColumn>
                        <RadioColumn className="pl-5">
                            <RadioColumn className="header">Przelew tradycyjny</RadioColumn>
                            <RadioColumn className="sub-payments">
                                Płatność na podstawie faktury, którą otrzymają Państwo
                                na podany adres mailowy w przeciągu 2 dni roboczych.
                            </RadioColumn>
                        </RadioColumn>
                    </Form.Check.Label>
                    <StyledWarningMessage type="invalid">Wybierz formę płatności</StyledWarningMessage>
                </Radio>
            </RadioColumn>
        </PaymentContainer>
        {+totalCartPrice > 15000
        && <Info>Jeśli chcesz skorzystać z mechanizmu podzielonej płatności (MPP) wybierz przelew tradycyjny</Info>}
    </StyledContainer>
);

export default Payments;
