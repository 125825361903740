import React, { useContext } from 'react';
import AppContext from '../../context/app-context';
import {
    PriceMainSection, StyledPriceMain, PriceMainCurrencyContainer, PriceMainCurrency, Description,
} from './PriceMainStyles';

const PriceMain = ({
    className, price, isOld, savings, description = '',
}) => {
    const appContext = useContext(AppContext);
    const language = appContext.lang;

    const translations = {
        oldPrice: { pl: 'Regularna\u00a0cena', en: 'Regular\u00a0price' },
        savings: { pl: 'Oszczędzasz', en: 'Savings' },
    };

    return (
        <PriceMainSection className={className} isOld={isOld} savings={savings}>
            {description
                && (
                    <Description>
                        {translations[description][language]}
                        :
                    </Description>
                )}
            <StyledPriceMain savings={savings}>
                {Math.floor(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00a0')}
            </StyledPriceMain>
            <PriceMainCurrencyContainer savings={savings}>
                <PriceMainCurrency>{(+price % 1).toFixed(2).toString().slice(-2)}</PriceMainCurrency>
                <PriceMainCurrency>zł</PriceMainCurrency>
            </PriceMainCurrencyContainer>
        </PriceMainSection>
    );
};

export default PriceMain;
