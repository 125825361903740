import React, { useContext } from 'react';
import {
    FooterContainer,
    StyledLink,
    IconsContainer,
    InfoText,
    FooterAnchor,
    StyledContainer,
    SmallText,
    DepartmentsWrapper,
    Wrapper,
    LinksColumn,
    AddressColumn,
    InvoiceColumn,
    ContactColumn,
    EmailLink,
    SocialLink,
    SocialImage,
    PatronImage,
    ImageContainer,
    TextContainer,
    RightsReserved,
} from './FooterStyles';
import AppContext from '../../context/app-context';
import Contact from './Contact/Contact';
import { customerServiceOfficeData, salesDepartmentData } from './footerContent';

export default function Footer() {
    const { routeMap, lang } = useContext(AppContext);
    return (
        <FooterContainer>
            <FooterAnchor id="kontakt" />
            <StyledContainer>
                <DepartmentsWrapper>
                    <Contact
                        deparmentName={customerServiceOfficeData.name}
                        employees={customerServiceOfficeData.employees}
                    />
                    <Contact
                        deparmentName={salesDepartmentData.name}
                        employees={salesDepartmentData.employees}
                    />
                </DepartmentsWrapper>
                <InfoText>
                    Biuro czynne od poniedziałku do piątku w godzinach 8:30 - 16:00
                </InfoText>
                <Wrapper>
                    <LinksColumn>
                        <StyledLink to={`${routeMap.products_for_preschool.path[lang]}`}>Dla przedszkoli</StyledLink>
                        <StyledLink to={`${routeMap.products_for_school.path[lang]}`}>Dla szkół</StyledLink>
                        <StyledLink to={`${routeMap.courses.path[lang]}`}>Szkolenia</StyledLink>
                        <StyledLink to={`${routeMap.company.path[lang]}`}>Firma</StyledLink>
                        <StyledLink to={`${routeMap.faq.path[lang]}`}>Pomoc</StyledLink>
                    </LinksColumn>
                    <LinksColumn>
                        <StyledLink to={`${routeMap.regulations.path[lang]}`}>Regulamin</StyledLink>
                        <StyledLink to={`${routeMap.certifications.path[lang]}`}>Certyfikaty</StyledLink>
                        <StyledLink to={`${routeMap.warranty.path[lang]}`}>Gwarancja</StyledLink>
                        <StyledLink to={`${routeMap.privacy.path[lang]}`}>Polityka prywatności</StyledLink>
                    </LinksColumn>
                    <AddressColumn>
                        EduSense SA
                        <br />
                        Oddział w Gdańsku
                        <br />
                        EduSense SA
                        <br />
                        ul. Kieturakisa 10/12
                        <br />
                        80-741 Gdańsk
                    </AddressColumn>
                    <InvoiceColumn>
                        Dane do faktury:
                        <br />
                        EduSense SA
                        <br />
                        ul. Dobrzańskiego 3
                        <br />
                        20-262 Lublin
                        <br />
                        NIP: 946-265-72-65
                    </InvoiceColumn>
                    <ContactColumn>
                        <EmailLink href="mailto:kontakt@edu-sense.com?subject=Kontakt ze strony www">
                            kontakt@edu-sense.com
                        </EmailLink>
                        <IconsContainer>
                            <SocialLink
                                href="https://www.facebook.com/UczymyDzieciProgramowac/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <SocialImage
                                    src={`${process.env.REACT_APP_CDN_URL}facebook-logo.svg`}
                                    alt="Facebook"
                                    title="Znajdź nas na Facebooku!"
                                />
                            </SocialLink>
                            <SocialLink
                                href="https://www.youtube.com/channel/UCd3KlH_Z77XZIpVAhwKzdNg"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <SocialImage
                                    src={`${process.env.REACT_APP_CDN_URL}youtube-logo.svg`}
                                    alt="Kanał YouTube"
                                    title="Zobacz nasz kanał na YouTube!"
                                />
                            </SocialLink>
                        </IconsContainer>
                    </ContactColumn>
                </Wrapper>
                <ImageContainer>
                    <PatronImage src={`${process.env.REACT_APP_CDN_URL}fe-eu-ncbir-w.png`} alt="" />
                </ImageContainer>
                <TextContainer>
                    <SmallText>
                        EduSense SA realizuje projekt „EduSense Editor – silnik do edycji
                        scenariuszy gier z wykorzystaniem robota edukacyjnego”,
                        w ramach Działania 1.2 „Sektorowe Programy B+R” Programu
                        Operacyjnego Inteligentny Rozwój 2014-2020, współfinansowany ze
                        środków Europejskiego Funduszu Rozwoju Regionalnego.
                    </SmallText>
                    <SmallText>
                        EduSense SA realizuje projekt „EduPython – platforma do nauki
                        programowania  z wykorzystaniem gry edukacyjnej”, dofinansowany
                        w ramach Działania 1.2 „Sektorowe Programy B+R” Programu Operacyjnego
                        Inteligentny Rozwój 2014-2020, współfinansowany ze środków Europejskiego
                        Funduszu Rozwoju Regionalnego.
                    </SmallText>
                </TextContainer>
                <RightsReserved>
                    2016-
                    {(new Date()).getFullYear()}
                    {' '}
                    © EDUSENSE SA WSZELKIE PRAWA ZASTRZEŻONE
                </RightsReserved>
            </StyledContainer>
        </FooterContainer>
    );
}
