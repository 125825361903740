import styled from "styled-components";
import {Form} from "react-bootstrap";

export const FormGroup = styled(Form.Group)`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    
    & input {
        margin-bottom: 0;
    }
`;
export const FormLabel = styled(Form.Label)`
    flex: 1 1 auto;
    display: block;
    margin-bottom: 0;
    color: #00437E;
    font-weight: 700;
    &:first-letter {
        text-transform: capitalize;
    }
`;
export const FormControl = styled(Form.Control)`
    flex: 1 1 auto;
    display: block;
    margin-bottom: 10px;
    padding: 10px;
    color: #00437E;
    background-color: white;
    outline-color: #00AFFF;
    border: 1px #00437E solid;
    border-radius: 0;
    font-size: 1rem;
`;
