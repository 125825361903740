import { DELIVERY_INPOST } from '../../Deliveries/Deliveries';

export const isFirstStepValid = (numberOfItemsInCart, items) => (
    numberOfItemsInCart > 0 && items.every((item) => item.isVisible && item.isBuyable)
);

export const isSecondStepValid = (data, onlyDigitalItems) => {
    const {
        paymentType, deliveryType, deliveryPrice, deliveryExtra, deliveryInpostAddress,
    } = data;

    const isInpostDeliveryInvalid = deliveryType === DELIVERY_INPOST && (!deliveryExtra || !deliveryInpostAddress);
    const deliveryIsValid = onlyDigitalItems || (
        deliveryType && deliveryPrice !== undefined && !isInpostDeliveryInvalid
    );

    if (paymentType && deliveryIsValid) {
        return true;
    }

    return false;
};

export const isThirdStepValid = (data) => {
    const manualValidation = () => ![
        'address',
        'city',
        'email',
        'firstName',
        'institution',
        'lastName',
        'phone',
        'postCode',
    ].find((e) => !data[e] || data[e].length < 2);

    return manualValidation();
};
