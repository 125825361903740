import React, { useState, useEffect, useContext } from 'react';
import ProductDescription from '../../ProductDescription/ProductDescription';
import Container from '../../Container/Container';
import { getNoAuth } from '../../../api/Api';
import AppContext from '../../../context/app-context';
import EmptyProductDescription from '../../ProductDescription/EmptyProductDescription';
import escapeDiacritics from '../../../tools/escapeDiacritics';
import CategoriesBar from '../../CategoriesBar/CategoriesBar';

export default function Product({ match }) {
    const productSlug = match.params.product;
    const appContext = useContext(AppContext);
    const language = appContext.lang;
    const [product, setProduct] = useState(null);
    const { setGoogleData } = appContext;
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getNoAuth(`products/slug/${productSlug}`).then((response) => {
            if (typeof response === 'object' && response.id !== undefined) {
                setProduct(response);
            } else {
                window.location = '/pl/produkty';
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productSlug]);

    useEffect(() => {
        if (!product) return;

        setGoogleData({
            ecomm_pagetype: 'product',
            ecomm_prodid: product.sku,
            ecomm_totalvalue: product.price,
        });
    }, [setGoogleData, product]);

    useEffect(() => window.fbq('track', 'PageView'), []);

    useEffect(() => {
        if (product) {
            window.scrollTo(0, 0);
            const translation = product.translations.filter((t) => t.language === language)[0];
            window.fbq('track', 'ViewContent', {
                content_name: escapeDiacritics(translation.name),
                content_type: 'product',
                content_ids: [product.sku ?? product.id],
                value: product.price,
                currency: 'PLN',
            });
        }
    }, [product, language]);

    useEffect(() => {
        getNoAuth('categories?sortBy=position&subpage=0')
            .then((categories) => setCategories(categories.filter((category) => category.products.length > 0)))
            .catch(() => {
                setCategories([]);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container className="bg-none">
            <CategoriesBar categories={categories} language={language} />
            {product === null
                ? <EmptyProductDescription />
                : <ProductDescription product={product} language={language} />}
        </Container>
    );
}
