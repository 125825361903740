import React from 'react';
import { Modal } from 'react-bootstrap';
import { StyledModal, StyledModalBody, ModalInfo } from './MaxQuantityModalStyles';

export default ({ show, onHide }) => (
    <StyledModal show={show} onHide={onHide} centered>
        <Modal.Header closeButton />
        <StyledModalBody>
            <ModalInfo>Niestety nie możesz dodać do koszyka więcej sztuk tego produktu</ModalInfo>
        </StyledModalBody>
    </StyledModal>
);
