import React from 'react';
import { CategoryAnchor, RowStyled } from './ProductsListStyles';
import Container from '../Container/Container';
import EmptyCard from './Card/EmptyCard';
import {
    EmptyRowTitle,
} from './Card/EmptyCardStyles';

export default function EmptyProductList({ subpage = 0 }) {
    return (
        <Container className="bg-none">
            {[...Array(2)].map((a, i) => (
                <React.Fragment key={i}>
                    <CategoryAnchor id={0} subpage={subpage} />
                    <RowStyled>
                        <EmptyRowTitle color="#a9a9a9" text={<i>&nbsp;</i>} />
                        <RowStyled className="d-flex flex-wrap flex-col w-100">
                            <EmptyCard />
                            <EmptyCard />
                        </RowStyled>
                    </RowStyled>
                </React.Fragment>
            ))}
        </Container>
    );
}
