import styled from 'styled-components';
import { Button as BootstrapButton } from 'react-bootstrap';

export const Button = styled(BootstrapButton)`
    word-break: keep-all;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.6rem;
    padding: 20px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 0;
    background-color: #00437E;
    color: white;
    outline: none;
    width: 80%;
    
    &:disabled {
        color: white;
        background-color: gray !important;    
        opacity: 1;
    }
    
    &:disabled:hover {
        color: white;
        background-color: gray !important;    
    }

    &.black-week {
        background-color: #f6242c !important;
    }
    &.black-week:hover {
        color: #f6242c;
        background-color: white !important;
    }
    
    &:active {
        box-shadow: none;
    }    
    
    &:focus {
        box-shadow: none;
    }
    
    &:hover {
        color: #00437E;
        background-color: white;
        border-color: white;
    }
    
    &:hover * {
        border-color: white;
    }
    
    &.clicked {
        background-color: #65BA00;
    }
    &.clicked:hover {
        color: white;
    }
    &.clicked:active {
        box-shadow: none;
    }    
    
    &.clicked:focus {
        box-shadow: none;
    }
    @media(max-width: 576px){
        width: 100%;
    }
`;

export const CartIcon = styled.div`
    width: 1.5em;
    height: 1.5em;
    background-image: url("${process.env.REACT_APP_CDN_URL}/kosz_edu.svg");
    background-repeat: no-repeat;
    background-position: center;

    ${Button}:disabled:hover & {
        background-image: url("${process.env.REACT_APP_CDN_URL}/kosz_edu.svg");
    }

    ${Button}:hover & {
        background-image: url(${(props) => (props.hoverImageUrl)});
    }

    ${Button}:active & {
        background-image: url("${process.env.REACT_APP_CDN_URL}/kosz_edu.svg");
    }
`;
