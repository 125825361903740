import React, { useEffect, useState } from 'react';
import { getNoAuth } from '../../../api/Api';

const LinkedProductsLister = ({ productId }) => {
    const [linkedProductsList, setLinkedProductsList] = useState([]);

    useEffect(() => {
        setLinkedProductsList([]);
        getNoAuth(`products/${productId}/linkedItems`)
            .then((list) => setLinkedProductsList(Object.values(list)))
            .catch(() => setLinkedProductsList([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId]);

    return linkedProductsList.length > 0 && (
        <>
            <h4>Warto dokupić</h4>
            <ul>
                {linkedProductsList.map((linkedProduct) => (
                    <li key={linkedProduct.id}>
                        <a href={linkedProduct.url}>{linkedProduct.name}</a>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default LinkedProductsLister;
