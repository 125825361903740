import React, { useState, useEffect, useContext } from 'react';
import { getNoAuth } from '../../../api/Api';
import Container from '../../Container/Container';
import ProductsList from '../../ProductsList/ProductsList';
import EmptyProductList from '../../ProductsList/EmptyProductList';
import AppContext from '../../../context/app-context';
import MetaTags from '../../MetaTags/MetaTags';
import InfoSection from './InfoSection/InfoSection';

export default function LabProducts() {
    const [categories, setCategories] = useState([]);
    const appContext = useContext(AppContext);
    const language = appContext.lang;
    const { setGoogleData } = appContext;

    useEffect(() => {
        setGoogleData({ ecomm_pagetype: 'category' });
    }, [setGoogleData]);

    useEffect(() => {
        getNoAuth('categories?sortBy=position&subpage=2')
            .then((categories) => setCategories(categories.filter((category) => category.products.length > 0)))
            .catch(() => {
                setCategories([]);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => window.fbq('track', 'PageView'), []);

    return (
        <Container className="bg-none">
            <MetaTags title="Produkty EduSense" />
            <InfoSection />
            {
                categories.length > 0
                    ? <ProductsList categories={categories} language={language} subpage={2} />
                    : <EmptyProductList />
            }
        </Container>
    );
}
