export const redirects = {
    "/produkty/materialy/mata-edukacyjna": "/produkty/materialy/mata-do-kodowania-100x100",
    "/produkty/ozobot/zestaw-szkolny-6-ozobot-evo": "/produkty/ozobot/zestaw-szkolny-4-ozobot-evo",
    "/produkty/ozobot/zestaw-szkolny-6-ozobot-evo-2-puzzle": "/produkty/ozobot/zestaw-szkolny-4-ozobot-evo-2-puzzle",
    "/produkty/laboratorium-chemiczne/zestaw-szkolny-chemia-tablica": "/produkty/laboratorium-chemiczne/zestaw-szkolny-professor-why-chemia",
    "/produkty/ozobot/zestaw-szkolny-6-pak": "/produkty/ozobot/zestaw-szkolny-6-ozobot-bit",
    "/produkty/ozobot/zestaw-przedszkolny-6-pak-puzzle": "/produkty/ozobot/zestaw-przedszkolny-6-ozobot-bit-2-puzzle",
    "/produkty/akcesoria/markery-ozobot": "/produkty/akcesoria/mazaki-ozobot",
    "/produkty/roboty/zestaw-szkolny-edusense-6-pack": "/produkty/roboty/zestaw-szkolny-6-pak",
    "/produkty/roboty/ozobot-2-0-bit-dwupak": "/produkty/roboty/ozobot-bit-dwupak",
    "/produkty/roboty/ozobot-2-0-bit-bialy": "/produkty/roboty/ozobot-bit-bialy",
    "/produkty/roboty/ozobot-2-0-bit-czarny": "/produkty/roboty/ozobot-bit-czarny",
    "/produkty/aktywna-tablica/zestaw-szkolny-chemia": "/produkty/laboratorium-chemiczne/zestaw-szkolny-chemia-ar",
    "/produkty/aktywna-tablica/chemia": "/produkty/laboratorium-chemiczne/chemia",
    "/produkty/aktywna-tablica/zestaw-szkolny-chemia-ar": "/produkty/laboratorium-chemiczne/zestaw-szkolny-chemia-ar",
    "/produkty/aktywna-tablica/zestaw-szkolny-chemia-tablica": "/produkty/laboratorium-chemiczne/zestaw-szkolny-chemia-tablica",
    "/produkty/przedsprzedaz/krazki-ruchu-cyfry-do-maty": "/produkty/mata/krazki-ruchu-cyfry-do-maty",
    "/produkty/przedsprzedaz/ksiazka-kodowanie-na-dywanie-2": "/produkty/mata/ksiazka-kodowanie-na-dywanie-2",
    "/produkty/materialy/plastikowe-kubeczki-do-maty": "/produkty/mata/kubeczki-do-maty",
    "/produkty/nowosci/ksiazka-kodowanie-na-dywanie-2": "/produkty/mata/ksiazka-kodowanie-na-dywanie-2",
    "/produkty/materialy/pakiet-startowy-kodowanie-na-dywanie": "/produkty/mata/zestaw-z-mata-do-kodowania",
    "/produkty/nowosci/krazki-ruchu-cyfry-do-maty": "/produkty/mata/krazki-ruchu-cyfry-do-maty",
    "/produkty/materialy/mata-do-kodowania-100x100": "/produkty/mata/mata-do-kodowania-100x100",
    "/produkty/materialy/mata-do-kodowania-150x150": "/produkty/mata/mata-do-kodowania-150x150",
    "/produkty/materialy/ksiazka-kodowanie-na-dywanie-2": "/produkty/mata/ksiazka-kodowanie-na-dywanie-2",
    "/produkty/materialy/zestaw-z-mata-do-kodowania": "/produkty/mata/zestaw-z-mata-do-kodowania",
    "/produkty/materialy/kubeczki-do-maty": "/produkty/mata/kubeczki-do-maty",
    "/produkty/materialy/krazki-ruchu-cyfry-do-maty": "/produkty/mata/krazki-ruchu-cyfry-do-maty",
    "/produkty/akcesoria/edu-karty": "/produkty/laboratorium-chemiczne/edu-karty",
    "/produkty/nowosci/ozobot-drewniane-puzzle-do-ozobota": "/produkty/nowosci/ozobot-bit-drewniane-puzzle-do-ozobota",
    "/produkty/nowosci/krazki-matematyczne-do-maty": "/produkty/mata/krazki-matematyczne-do-maty",
    "/produkty/nowosci/krazki-humanistyczne-do-maty": "/produkty/mata/krazki-humanistyczne-do-maty",
    "/produkty/nowosci/dodatkowe-krazki-matematyczne-do-maty": "/produkty/mata/dodatkowe-krazki-matematyczne-do-maty",
    "/produkty/nowosci/edukacja-przedszkolna-czesc-ii": "/produkty/materialy/edukacja-przedszkolna-czesc-ii",
    "/produkty/nowosci/drewniane-puzzle-do-ozobota-angielskie": "/produkty/puzzle/drewniane-puzzle-do-ozobota-angielskie",
    "/produkty/nowosci/ozobot-bit-drewniane-puzzle-do-ozobota": "/produkty/puzzle/ozobot-bit-drewniane-puzzle-do-ozobota",
    "/produkty/nowosci/ozobot-evo-drewniane-puzzle-do-ozobota": "/produkty/puzzle/ozobot-evo-drewniane-puzzle-do-ozobota",
    "/produkty/nowosci/drewniane-puzzle-edukacja-przedszkolna-czesc-ii": "/produkty/puzzle/drewniane-puzzle-edukacja-przedszkolna-czesc-ii",
    "/produkty/roboty/ozobot-bit-czarny": "/produkty/ozobot/ozobot-bit-czarny",
    "/produkty/roboty/ozobot-bit-dwupak": "/produkty/ozobot/ozobot-bit-dwupak",
    "/produkty/roboty/ozobot-evo-bialy": "/produkty/ozobot/ozobot-evo-bialy",
    "/produkty/roboty/ozobot-evo-czarny": "/produkty/ozobot/ozobot-evo-czarny",
    "/produkty/roboty/zestaw-szkolny-6-pak": "/produkty/ozobot/zestaw-szkolny-6-pak",
    "/produkty/roboty/zestaw-przedszkolny-6-pak": "/produkty/ozobot/zestaw-przedszkolny-6-pak",
    "/produkty/roboty/zestaw-przedszkolny-6-pak-puzzle": "/produkty/ozobot/zestaw-przedszkolny-6-pak-puzzle",
    "/produkty/roboty/ozobot-edukacja-przedszkolna": "/produkty/ozobot/ozobot-edukacja-przedszkolna",
    "/produkty/nowosci/drewniane-puzzle-do-ozobota-zestaw-zaawansowany": "/produkty/nowosci/drewniane-puzzle-do-ozobota-zestaw-dodatkowy",
    "/produkty/nowosci/drewniane-puzzle-do-ozobota-zestaw-podstawowy-i-zaawansowany": "/produkty/nowosci/drewniane-puzzle-do-ozobota-zestaw-podstawowy-i-dodatkowy",
    "/produkty/wielkanoc/ozobot-bit-drewniane-puzzle-do-ozobota": "/produkty/wiosna/ozobot-bit-drewniane-puzzle-do-ozobota",
    "/produkty/wielkanoc/ozobot-bit-zestaw-konstrukcyjny-flamastry": "/produkty/wiosna/ozobot-bit-zestaw-konstrukcyjny-flamastry",
    "/produkty/wielkanoc/zestaw-6-pak-puzzle-zestaw-konstrukcyjny": "/produkty/wiosna/zestaw-6-pak-puzzle-zestaw-konstrukcyjny",
    "/produkty/wielkanoc/zestaw-z-mata-do-kodowania": "/produkty/wiosna/zestaw-z-mata-do-kodowania",
    "/produkty/nowosci/zestaw-zeszytow-cwiczen-5-6-latka-kodowanie-na-dywanie": "/produkty/mata/zestaw-zeszytow-cwiczen-5-6-latka-kodowanie-na-dywanie",
    "/produkty/nowosci/zestaw-z-mata-do-kodowania": "/produkty/mata/zestaw-z-mata-do-kodowania-cwiczenia",
    "/produkty/nowosci/drewniane-puzzle-do-ozobota-zestaw-podstawowy-i-dodatkowy": "/produkty/puzzle/drewniane-puzzle-do-ozobota-zestaw-podstawowy-i-dodatkowy",
    "/produkty/nowosci/drewniane-puzzle-ar-rozszerzona-rzeczywistosc": "/produkty/puzzle/drewniane-puzzle-ar-rozszerzona-rzeczywistosc",
    "/produkty/promocja/ozobot-bit-drewniane-puzzle-do-ozobota-puzzle-ar": "/produkty/puzzle/ozobot-bit-drewniane-puzzle-do-ozobota-puzzle-ar",
    "/produkty/nowosci/drewniane-puzzle-do-ozobota-zestaw-dodatkowy": "/produkty/puzzle/drewniane-puzzle-do-ozobota-zestaw-dodatkowy",
    "/produkty/nowosci/ksiazka-kodowanie-nie-tylko-na-dywanie": "/produkty/mata/ksiazka-kodowanie-nie-tylko-na-dywanie",
};