import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Container from '../Container/Container';
import { Description, RadioColumn } from './OrderFreeTestFormStyles';
import {
    Header,
    PostCodeColumn,
    Radio,
    RulesAndSubmitContainer,
    RulesColumn,
    SubmitButton,
    SubmitColumn,
} from '../pages/Cart/CartStyles';
import Input from '../Input/Input';
import TermsCheckbox from '../TermsCheckbox/TermsCheckbox';
import { CategoryAnchor } from '../ProductsList/ProductsListStyles';
import { post } from '../../api/Api';
import useNotification from '../NotificationContainer/hook/useNotification';
import { WarningMessage } from '../Input/InputStyles';

export const ROBOT_OZOBOT = 'ozobot';
export const ROBOT_GENIBOT = 'genibot';

export default function OrderFreeTestForm() {
    const [data, setData] = useState({});

    const setDataField = (key, value) => setData((data) => {
        data[key] = value;
        return { ...data };
    });

    const [validated, setValidated] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const { notify } = useNotification();
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        const isValid = form.checkValidity();
        setValidated(true);
        if (isValid === false) return null;

        setInProgress(true);
        post('order-free-test', data).then((result) => {
            if (result.status !== 'ok') {
                notify('Wystąpił błąd. Spróbuj ponownie później.', 'error');
                setInProgress(false);
                return;
            }
            setIsSend(true);
        }).catch(() => {
            notify('Wystąpił błąd. Spróbuj ponownie później.', 'error');
            setInProgress(false);
        });

        return null;
    };

    return (
        <Container className="text-left bg-none">
            <CategoryAnchor id="bezplatny-test-robota" />
            <div className="bg-white p-3">
                <Row>
                    <Col>
                        <h2 className="mb-3" style={{ color: '#00437E' }}>ZAMÓW BEZPŁATNY 7-DNIOWY TEST ROBOTA</h2>
                    </Col>
                </Row>
                {isSend
                    ? (
                        <Row>
                            <Col>
                                <Description success={isSend}>Dziękujemy. Formularz kontaktowy został wysłany.</Description>
                            </Col>
                        </Row>
                    )
                    : (
                        <>
                            <Row>
                                <Col>
                                    <Description>
                                        Każdy nauczyciel może samodzielnie przez tydzień sprawdzić jak dobrze pracuje się
                                        z robotami. Po zakończeniu testów przyślemy kuriera (na nasz koszt) po odbiór.
                                        Wypełnij formularz poniżej.
                                    </Description>
                                </Col>
                            </Row>
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                                className="w-100 pr-5 pl-4"
                            >
                                <Row>
                                    <Col sm={12} md={6} lg={4} className="d-flex mb-5">
                                        <RadioColumn>
                                            <Radio id="robot1">
                                                <Form.Check.Input
                                                    onChange={() => setDataField('selected_robot_type', ROBOT_GENIBOT)}
                                                    name="selected_robot_type"
                                                    type="radio"
                                                    required
                                                />
                                                <Form.Check.Label>
                                                    <RadioColumn className="fit">
                                                        <span className="placeholder" />
                                                    </RadioColumn>
                                                    <RadioColumn className="pl-5">
                                                        <RadioColumn className="header">Genibot</RadioColumn>
                                                    </RadioColumn>
                                                </Form.Check.Label>
                                                <WarningMessage type="invalid">Wybierz robota</WarningMessage>
                                            </Radio>
                                        </RadioColumn>
                                        <RadioColumn>
                                            <Radio id="robot2">
                                                <Form.Check.Input
                                                    onChange={() => setDataField('selected_robot_type', ROBOT_OZOBOT)}
                                                    name="selected_robot_type"
                                                    type="radio"
                                                    required
                                                />
                                                <Form.Check.Label>
                                                    <RadioColumn className="fit">
                                                        <span className="placeholder" />
                                                    </RadioColumn>
                                                    <RadioColumn className="pl-5">
                                                        <RadioColumn className="header">Ozobot</RadioColumn>
                                                    </RadioColumn>
                                                </Form.Check.Label>
                                                <WarningMessage type="invalid">Wybierz robota</WarningMessage>
                                            </Radio>
                                        </RadioColumn>
                                    </Col>
                                </Row>
                                <Row><Col><Header>Dane do wysyłki</Header></Col></Row>
                                <Row>
                                    <Col sm={12} md={6} lg={4}>
                                        <Input
                                            onChange={(value) => setDataField('first_name', value)}
                                            id="firstName"
                                            label="Imię"
                                            feedback="Podaj imię"
                                        />
                                        <Input
                                            onChange={(value) => setDataField('last_name', value)}
                                            id="lastName"
                                            label="Nazwisko"
                                            feedback="Podaj nazwisko"
                                        />
                                    </Col>
                                    <Col sm={12} md={6} lg={4}>
                                        <Input
                                            onChange={(value) => setDataField('email', value)}
                                            id="email"
                                            label="Adres e-mail"
                                            feedback="Podaj adres e-mail"
                                            type="email"
                                        />
                                        <Input
                                            onChange={(value) => setDataField('phone', value)}
                                            id="phone"
                                            label="numer telefonu"
                                            type="tel"
                                        />
                                    </Col>
                                    <Col sm={12} md={12} lg={4}>
                                        <Input
                                            onChange={(value) => setDataField('school_name', value)}
                                            id="schoolName"
                                            label="Nazwa placówki"
                                            feedback="Podaj nazwę placówki"
                                        />
                                        <Input
                                            onChange={(value) => setDataField('address', value)}
                                            id="address"
                                            label="Ulica i numer placówki"
                                            feedback="Podaj ulicę"
                                        />
                                        <Form.Row>
                                            <PostCodeColumn>
                                                <Input
                                                    onChange={(value) => setDataField('postcode', value)}
                                                    id="postcode"
                                                    type="postcode"
                                                    label="kod pocztowy"
                                                    placeholder="00-000"
                                                />
                                            </PostCodeColumn>
                                            <Col>
                                                <Input
                                                    onChange={(value) => setDataField('city', value)}
                                                    id="city"
                                                    label="Miejscowość"
                                                />
                                            </Col>
                                        </Form.Row>
                                    </Col>
                                    <RulesAndSubmitContainer className="p-0 mr-2 ml-2">
                                        <RulesColumn className="m-0 p-0">
                                            <TermsCheckbox className="m-0 p-0" />
                                        </RulesColumn>
                                        <SubmitColumn className="d-flex justify-content-center">
                                            <SubmitButton
                                                type="submit"
                                                className={inProgress ? 'in-progress' : ''}
                                                disabled={inProgress}
                                            >
                                                {inProgress ? 'Wysyłanie...' : 'Zamów test'}
                                            </SubmitButton>
                                        </SubmitColumn>
                                    </RulesAndSubmitContainer>
                                </Row>
                            </Form>
                        </>
                    )}
            </div>
        </Container>
    );
}
