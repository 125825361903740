import styled from 'styled-components';
import { ContainerStyled } from '../Deliveries/DeliveriesStyles';

export const StyledContainer = styled(ContainerStyled)`
    flex-wrap: wrap;    
    align-items: center;

    @media (max-width: 1250px) { 
        align-items: start;
    }
`;

export const PaymentContainer = styled(ContainerStyled)`
    padding: 0;
    border-bottom: none;
`;

export const Info = styled.p`
    margin: 16px 0 0;
    color: red;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding-left: 242px;
    line-height: 1.2;

    @media (max-width: 992px) { 
        padding-left: 48px;
    }
`;
