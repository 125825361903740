import React, { useCallback, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import {
    ContainerStyled, CopyAddressDataButton, FormColumn, Header, PostCodeColumn, VatCheckbox, InvoiceFormHeaderContainer,
    Info, StyledInput, ContactDetailsHeader, DeliveryDetailsHeader,
} from './ContactDetailsFormStyles';
import TextArea from '../TextArea/TextArea';

const ContactDetailsForm = ({ data, setDataValue }) => {
    const [isInvoiceDisabled, setInvoiceDisabled] = useState(
        JSON.parse(localStorage.getItem('isInvoiceDisabled')) !== false,
    );
    const [isInvoiceRecipientDisabled, setInvoiceRecipientDisabled] = useState(
        JSON.parse(localStorage.getItem('isInvoiceRecipientDisabled')) !== false,
    );
    const invoice = ['invoiceName', 'invoiceAddress', 'invoicePostcode', 'invoiceCity', 'invoiceTaxId'];
    const invoiceRecipient = [
        'invoiceRecipientName',
        'invoiceRecipientAddress',
        'invoiceRecipientPostcode',
        'invoiceRecipientCity',
        'invoiceRecipientTaxId',
    ];

    const resetPropertiesDataValues = (properties) => {
        properties.map((property) => {
            setDataValue(property, null);
            document.getElementById(property).value = '';

            return property;
        });
    };

    const handleInvoiceChecboxChange = () => {
        setInvoiceDisabled((isInvoiceDisabled) => !isInvoiceDisabled);
        localStorage.setItem('isInvoiceDisabled', JSON.stringify(!isInvoiceDisabled));

        if (!isInvoiceDisabled) {
            setInvoiceRecipientDisabled(true);
            localStorage.setItem('isInvoiceRecipientDisabled', JSON.stringify(true));
        }

        resetPropertiesDataValues(invoice);
        resetPropertiesDataValues(invoiceRecipient);
    };

    const handleInvoiceRecipientChecboxChange = (e) => {
        setInvoiceRecipientDisabled((isInvoiceRecipientDisabled) => !isInvoiceRecipientDisabled);
        localStorage.setItem('isInvoiceRecipientDisabled', JSON.stringify(!isInvoiceRecipientDisabled));
        setDataValue('invoiceSeparateRecipient', e.currentTarget.checked);
        resetPropertiesDataValues(invoiceRecipient);
    };

    const copyRecipientToInvoiceClient = useCallback(() => {
        setDataValue('invoiceName', data.institution);
        setDataValue('invoiceAddress', data.address);
        setDataValue('invoicePostcode', data.postCode);
        setDataValue('invoiceCity', data.city);
        document.getElementById('invoiceName').value = data.institution || '';
        document.getElementById('invoiceAddress').value = data.address || '';
        document.getElementById('invoicePostcode').value = data.postCode || '';
        document.getElementById('invoiceCity').value = data.city || '';
    }, [data, setDataValue]);

    const copyRecipientToInvoiceRecipient = useCallback(() => {
        setDataValue('invoiceRecipientName', data.institution);
        setDataValue('invoiceRecipientAddress', data.address);
        setDataValue('invoiceRecipientPostcode', data.postCode);
        setDataValue('invoiceRecipientCity', data.city);
        document.getElementById('invoiceRecipientName').value = data.institution || '';
        document.getElementById('invoiceRecipientAddress').value = data.address || '';
        document.getElementById('invoiceRecipientPostcode').value = data.postCode || '';
        document.getElementById('invoiceRecipientCity').value = data.city || '';
    }, [data, setDataValue]);

    return (
        <>
            <ContainerStyled>
                <FormColumn className="padding-right">
                    <ContactDetailsHeader>
                        Dane kontaktowe
                    </ContactDetailsHeader>
                    <StyledInput
                        onChange={(value) => setDataValue('firstName', value)}
                        value={data.firstName || ''}
                        id="firstName"
                        label="imię"
                    />
                    <StyledInput
                        onChange={(value) => setDataValue('lastName', value)}
                        value={data.lastName || ''}
                        id="lastName"
                        label="nazwisko"
                    />
                    <StyledInput
                        onChange={(value) => setDataValue('email', value)}
                        value={data.email || ''}
                        id="email"
                        label="adres e-mail"
                        type="email"
                    />
                    <StyledInput
                        onChange={(value) => setDataValue('phone', value)}
                        value={data.phone || ''}
                        id="phone"
                        label="numer telefonu"
                        type="tel"
                    />
                    <TextArea
                        onChange={(value) => setDataValue('incentive', value)}
                        value={data.incentive || ''}
                        id="incentive"
                        label="Skąd wiesz o produkcie?"
                    />
                </FormColumn>
                <FormColumn className="padding-left">
                    <DeliveryDetailsHeader>Dane do wysyłki</DeliveryDetailsHeader>
                    <Info>
                        {isInvoiceDisabled
                        && '* Jeśli nie wprowadzisz danych do faktury, poniższe dane pojawią się na dowodzie zakupu.'}
                    </Info>
                    <StyledInput
                        onChange={(value) => setDataValue('institution', value)}
                        value={data.institution || ''}
                        id="institution"
                        label="Nazwa instytucji lub imię i nazwisko"
                        feedback="Podaj nazwę instytucji lub imię i nazwisko - max 160 znaków"
                        minLength={1}
                        maxLength={160}
                        type="fullName"
                    />
                    <StyledInput
                        onChange={(value) => setDataValue('address', value)}
                        value={data.address || ''}
                        id="address"
                        label="Ulica i numer domu / mieszkania"
                        feedback="Podaj ulicę i numer domu"
                    />
                    <Form.Row>
                        <PostCodeColumn>
                            <StyledInput
                                onChange={(value) => setDataValue('postCode', value)}
                                value={data.postCode || ''}
                                id="postCode"
                                type="postcode"
                                label="kod pocztowy"
                                placeholder="00-000"
                                maxLength={6}
                            />
                        </PostCodeColumn>
                        <Col>
                            <StyledInput
                                onChange={(value) => setDataValue('city', value)}
                                value={data.city || ''}
                                id="city"
                                label="Miejscowość"
                            />
                        </Col>
                    </Form.Row>
                    <TextArea
                        onChange={(value) => setDataValue('comment', value)}
                        value={data.comment || ''}
                        id="comment"
                        label="Dodatkowe informacje"
                    />
                </FormColumn>
            </ContainerStyled>
            <ContainerStyled>
                <FormColumn className="padding-right">
                    <VatCheckbox
                        type="checkbox"
                        id="invoiceCopy"
                        onChange={handleInvoiceChecboxChange}
                        checked={!isInvoiceDisabled}
                        isValid
                        label={(
                            <>
                                <span className="checkbox-placeholder" />
                                <span className="checkbox-label">Chcę otrzymać Fakturę VAT</span>
                            </>
                        )}
                    />
                    <InvoiceFormHeaderContainer>
                        <Header disabled={isInvoiceDisabled}>Dane nabywcy na fakturze</Header>
                        <CopyAddressDataButton
                            disabled={isInvoiceDisabled}
                            type="button"
                            className="btn btn-primary"
                            onClick={copyRecipientToInvoiceClient}
                        >
                            Skopiuj z danych do wysyłki
                        </CopyAddressDataButton>
                    </InvoiceFormHeaderContainer>
                    <StyledInput
                        disabled={isInvoiceDisabled}
                        onChange={(value) => setDataValue('invoiceName', value)}
                        value={data.invoiceName || ''}
                        id="invoiceName"
                        label="Nazwa instytucji lub imię i nazwisko"
                        feedback="Podaj nazwę instytucji lub imię i nazwisko - max 160 znaków"
                        type="fullName"
                    />
                    <StyledInput
                        disabled={isInvoiceDisabled}
                        onChange={(value) => setDataValue('invoiceAddress', value)}
                        value={data.invoiceAddress || ''}
                        id="invoiceAddress"
                        label="Ulica i numer domu / mieszkania"
                        feedback="Podaj ulicę i numer domu"
                    />
                    <Form.Row>
                        <PostCodeColumn>
                            <StyledInput
                                disabled={isInvoiceDisabled}
                                onChange={(value) => setDataValue('invoicePostcode', value)}
                                value={data.invoicePostcode || ''}
                                id="invoicePostcode"
                                label="kod pocztowy"
                                type="postcode"
                                placeholder="00-000"
                                maxLength={6}
                            />
                        </PostCodeColumn>
                        <Col>
                            <StyledInput
                                disabled={isInvoiceDisabled}
                                onChange={(value) => setDataValue('invoiceCity', value)}
                                value={data.invoiceCity || ''}
                                id="invoiceCity"
                                label="Miescowość"
                            />
                        </Col>
                    </Form.Row>
                    <StyledInput
                        disabled={isInvoiceDisabled}
                        onChange={(value) => setDataValue('invoiceTaxId', value)}
                        value={data.invoiceTaxId || ''}
                        id="invoiceTaxId"
                        label="Numer NIP (jeśli posiada)"
                        type="nip"
                        feedback="Podaj 10-cyfrowy numer NIP"
                        required={false}
                    />
                </FormColumn>
                <FormColumn className="padding-left">
                    <div>
                        <VatCheckbox
                            type="checkbox"
                            id="invoiceSeparateRecipient"
                            onChange={handleInvoiceRecipientChecboxChange}
                            checked={!isInvoiceRecipientDisabled}
                            disabled={isInvoiceDisabled}
                            isValid
                            label={(
                                <>
                                    <span className={`checkbox-placeholder ${isInvoiceDisabled ? 'disabled' : ''}`} />
                                    <span className={`checkbox-label ${isInvoiceDisabled ? 'disabled' : ''}`}>
                                        Dane odbiorcy na fakturze
                                    </span>
                                </>
                            )}
                        />
                        <InvoiceFormHeaderContainer>
                            <Header disabled={isInvoiceRecipientDisabled}>Dane odbiorcy na fakturze</Header>
                            <CopyAddressDataButton
                                disabled={isInvoiceRecipientDisabled}
                                type="button"
                                className="btn btn-primary"
                                onClick={copyRecipientToInvoiceRecipient}
                            >
                                Skopiuj z danych do wysyłki
                            </CopyAddressDataButton>
                        </InvoiceFormHeaderContainer>
                        <StyledInput
                            disabled={isInvoiceRecipientDisabled}
                            onChange={(value) => setDataValue('invoiceRecipientName', value)}
                            value={data.invoiceRecipientName || ''}
                            id="invoiceRecipientName"
                            label="Nazwa instytucji lub imię i nazwisko"
                            feedback="Podaj nazwę instytucji lub imię i nazwisko - max 160 znaków"
                            type="fullName"
                        />
                        <StyledInput
                            disabled={isInvoiceRecipientDisabled}
                            onChange={(value) => setDataValue('invoiceRecipientAddress', value)}
                            value={data.invoiceRecipientAddress || ''}
                            id="invoiceRecipientAddress"
                            label="Ulica i numer domu / mieszkania"
                            feedback="Podaj ulicę i numer domu"
                        />
                        <Form.Row>
                            <PostCodeColumn>
                                <StyledInput
                                    disabled={isInvoiceRecipientDisabled}
                                    onChange={(value) => setDataValue('invoiceRecipientPostcode', value)}
                                    value={data.invoiceRecipientPostcode || ''}
                                    id="invoiceRecipientPostcode"
                                    label="kod pocztowy"
                                    type="postcode"
                                    placeholder="00-000"
                                    maxLength={6}
                                />
                            </PostCodeColumn>
                            <Col>
                                <StyledInput
                                    disabled={isInvoiceRecipientDisabled}
                                    onChange={(value) => setDataValue('invoiceRecipientCity', value)}
                                    value={data.invoiceRecipientCity || ''}
                                    id="invoiceRecipientCity"
                                    label="Miescowość"
                                />
                            </Col>
                        </Form.Row>
                        <StyledInput
                            disabled={isInvoiceRecipientDisabled}
                            onChange={(value) => setDataValue('invoiceRecipientTaxId', value)}
                            value={data.invoiceRecipientTaxId || ''}
                            id="invoiceRecipientTaxId"
                            label="Numer NIP (jeśli posiada)"
                            type="nip"
                            feedback="Podaj 10-cyfrowy numer NIP"
                            required={false}
                        />
                    </div>
                </FormColumn>
            </ContainerStyled>
        </>
    );
};

export default ContactDetailsForm;
