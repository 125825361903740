import styled from 'styled-components';

export default styled.button`
    border: none;
    background-color: unset;
    color: #00437E;
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 15px;
    
    & > span {
        position: relative;
    }
    
    & > span:after {
        content: "";
        position: absolute;
        height: 2px;
        top: 20px;
        width: 100%;
        left: 0;
        background-color: #00AFFF;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
    }

    &:hover {
        color: #00AFFF;

        & > span:after {
            visibility: visible;
            transform: scaleX(1);
        }
    }
    
    @media (max-width: 575.98px) {
        margin-bottom: 20px;
        padding-left: 0; 
    }
`;
