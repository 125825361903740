import React from 'react';

const AppContext = React.createContext({
    lang: 'pl',
    page: null,
    setPage: () => {},
    routePage: '/pl',
    setRoutePage: () => {},
    routeMap: {},
    googleData: {},
    setGoogleData: () => {},
    content: null,
    setContent: () => {},
});

export default AppContext;
