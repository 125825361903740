import { all } from 'redux-saga/effects';
import { watchFetchSearchProducts } from './client/pages/SearchResults/searchResultsSaga';
import { watchFetchSearch } from './client/Search/searchSaga';

export default function* saga() {
    yield all([
        watchFetchSearchProducts(),
        watchFetchSearch(),
    ]);
}
