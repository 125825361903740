import React from 'react';
import {MetaTags as ReactMetaTags} from "react-meta-tags";

export default function MetaTags({
    title = "Edu Sense - Uczymy dzieci programować!",
    image = "https://cdn.edu-sense.com/es/edusense_logo.jpg",
    locale = "pl_PL",
    url = document.location.href,
    description
}) {
    return <ReactMetaTags>
        <title>{title}</title>
        <meta id="og-image-meta-tags" property="og:image" content={image}/>
        <meta id="og-title-meta-tags" property="og:title" content={title}/>
        <meta id="og-locale-meta-tags" property="og:locale" content={locale}/>
        <meta id="og-url-meta-tags" property="og:url" content={url}/>
        {description && <meta id="og-description-meta-tags" property="og:description" content={description}/>}
    </ReactMetaTags>;
}
