import React, { useState, useEffect } from 'react';
import { FaqElementWrapper } from './FaqStyles';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

export default function FaqElement({ index, title, content, openedElement, setOpenedElement, searchPhrase }) {
    const [isCollapsed, setIsCollapsed] = useState(openedElement !== index);
    const [isHidden, setIsHidden] = useState(false);

    useEffect(() => {
        setIsCollapsed(openedElement !== index);
        if (searchPhrase) {
            let searchFound = false;
            searchFound = title.toLowerCase().indexOf(searchPhrase) !== -1;
            if (typeof content.props.children === 'object') {
                searchFound = searchFound || !!(content.props.children.find(e => typeof e === 'string' && e.toLowerCase().indexOf(searchPhrase) !== -1) );
            } else if (typeof content.props.children === 'string') {
                searchFound = searchFound || content.props.children.toLowerCase().indexOf(searchPhrase) !== -1;
            }
            setIsHidden(!searchFound);
            setIsCollapsed(!searchFound);
        } else {
            setIsHidden(false);
        }
    }, [index, content, title, openedElement, searchPhrase]);

    const toggleClick = () => {
        if (isCollapsed) {
            setOpenedElement(index);
            setIsCollapsed(false);
        } else {
            setOpenedElement(null);
        }
    }

    return (
        <FaqElementWrapper className={isHidden ? 'd-none' : ''} key={index}>
            <Accordion.Toggle onClick={() => toggleClick()} as={Card.Header} eventKey={index}><i className={"fa fa-chevron-right mr-2 transition05" + (isCollapsed ? "" : " rotate90")}></i> {title}</Accordion.Toggle>
            <Accordion.Collapse className={isCollapsed ? '' : 'show'} eventKey={index}><Card.Body>{content}</Card.Body></Accordion.Collapse>
        </FaqElementWrapper>
    );
}
