import React, { useContext } from 'react';
import { StyledButtonMore } from './ButtonMoreStyles';
import AppContext from '../../context/app-context';

export default function ButtonMore({ className, to }) {
    const appContext = useContext(AppContext);
    const language = appContext.lang;

    const translations = {
        more: { pl: 'Zobacz więcej', en: 'See more' },
    };

    return (
        <StyledButtonMore className={className} to={to}>
            {translations.more[language]}
        </StyledButtonMore>
    );
}
