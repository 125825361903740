import React from 'react';
import {
    ButtonsWrapper,
    CardBody, CardBodyNarrow,
    CardImg,
    CardLink,
    CardStyled,
    CardText,
    CardTitle,
    CardTitleLink,
    CardWrapper,
    PreorderText,
    PriceElementFullWidth,
    StockText,
    CardSectionContainer,
    StyledPriceMain,
    StyledPrice,
} from './CardStyles';
import ButtonMore from '../../ButtonMore/ButtonMore';
import ButtonAddToCart from '../../ButtonAddToCart/ButtonAddToCart';

export default function Card({
    product, language, productUrl, isBlackWeek, isChristmas,
}) {
    const productContent = product.translations.filter((item) => item.language === language)[0];
    if (productContent === undefined) return <div />;

    return (
        <CardWrapper className="flex-grow-1 flex-shrink-0">
            <CardStyled
                className={
                    `d-flex flex-row flex-wrap ${isBlackWeek ? 'black-week' : ''} ${isChristmas ? 'christmas' : ''}`
                }
            >
                <CardLink to={productUrl} className="d-none d-sm-block">
                    <CardSectionContainer>
                        <CardImg
                            variant="left"
                            src={`${product.main_image
                                && (process.env.REACT_APP_CDN_URL + product.main_image.path)}`}
                            className="align-self-center"
                        />
                        <PriceElementFullWidth
                            className="text-right product-price-fullwidth flex-column justify-content-end"
                        >
                            {product.price_before
                                && <StyledPrice description="oldPrice" price={parseFloat(product.price_before)} />}
                            {product.price_before
                                && (
                                    <StyledPrice
                                        description="savings"
                                        savings
                                        price={parseFloat(product.price_before) - parseFloat(product.price)}
                                    />
                                )}
                            {product.price && <StyledPriceMain price={product.price} />}
                        </PriceElementFullWidth>
                    </CardSectionContainer>
                </CardLink>
                <CardBody className="d-none d-sm-block">
                    <CardSectionContainer>
                        <CardTitleLink to={productUrl}>
                            <CardTitle>{productContent.name}</CardTitle>
                            {productContent.preorder_text
                                && <PreorderText>{productContent.preorder_text}</PreorderText>}
                            {productContent.stock_text && <StockText>{productContent.stock_text}</StockText>}
                            <CardText>
                                {productContent.short_description}
                                <ButtonMore to={productUrl} />
                            </CardText>
                        </CardTitleLink>
                        <ButtonsWrapper className="d-flex w-100 align-self-end product-list-button-wrapper">
                            <ButtonAddToCart
                                isDisabled={!product.is_buyable}
                                isBlackweek={isBlackWeek}
                                hoverImageUrl={`${process.env.REACT_APP_CDN_URL}/kosz_edu_hover.svg`}
                                isChristmas={isChristmas}
                                item={{
                                    id: product.id,
                                    sku: product.sku,
                                    name: productContent.name,
                                    price: product.price,
                                    priceBefore: product.price_before,
                                    image: product.main_image && product.main_image.path,
                                    preOrderText: productContent.preorder_text,
                                    stockText: productContent.stock_text,
                                    isLargeSize: product.is_large_size,
                                    isDigital: product.is_digital,
                                    url: productUrl,
                                    contentType: product.marketing[0].fb_content_type,
                                    isBuyable: product.is_buyable,
                                    maxQuantityPerOrder: product.max_quantity_per_order,
                                }}
                            />
                        </ButtonsWrapper>
                    </CardSectionContainer>
                </CardBody>
                <CardBodyNarrow className="d-block d-sm-none">
                    <CardTitleLink to={productUrl} style={{ display: 'block' }}>
                        <CardTitle>{productContent.name}</CardTitle>
                        <CardImg
                            variant="center"
                            src={`${product.main_image
                                && (process.env.REACT_APP_CDN_URL + product.main_image.path)}`}
                            className="align-self-center"
                        />
                        {productContent.preorder_text && <PreorderText>{productContent.preorder_text}</PreorderText>}
                        {productContent.stock_text && <StockText>{productContent.stock_text}</StockText>}
                        <CardText>
                            {productContent.short_description}
                            <ButtonMore to={productUrl} />
                        </CardText>
                    </CardTitleLink>
                </CardBodyNarrow>
                <PriceElementFullWidth className="text-right product-price-narrow-mobile">
                    {product.price_before && <StyledPrice description="oldPrice" price={parseFloat(product.price_before)} />}
                    {product.price_before
                        && (
                            <StyledPrice
                                description="savings"
                                savings
                                price={parseFloat(product.price_before) - parseFloat(product.price)}
                            />
                        )}
                    {product.price && <StyledPriceMain price={product.price} />}

                    <ButtonsWrapper className="d-flex w-100 product-price-narrow-mobile">
                        <ButtonAddToCart
                            isBlackweek={isBlackWeek}
                            hoverImageUrl={`${process.env.REACT_APP_CDN_URL}/kosz_edu_hover.svg`}
                            isChristmas={isChristmas}
                            item={{
                                id: product.id,
                                sku: product.sku,
                                name: productContent.name,
                                price: product.price,
                                priceBefore: product.price_before,
                                image: product.main_image && product.main_image.path,
                                preOrderText: productContent.preorder_text,
                                stockText: productContent.stock_text,
                                isLargeSize: product.is_large_size,
                                isDigital: product.is_digital,
                                url: productUrl,
                                contentType: product.marketing[0].fb_content_type,
                                isBuyable: product.is_buyable,
                                maxQuantityPerOrder: product.max_quantity_per_order,
                            }}
                        />
                    </ButtonsWrapper>
                </PriceElementFullWidth>
            </CardStyled>
        </CardWrapper>
    );
}
