import React from 'react';
import {redirects} from "../../CustomRedirects";
import {Col, Container, Row} from "react-bootstrap";

export default function Redirects() {
    return <Container>
        {Object.keys(redirects).map((key, i) =>
            <Row key={i} className="border-bottom">
                <Col className="col-1">{i}.</Col>
                <Col className="col-5 text-nowrap"><a href={"/pl" + key}>{key}</a></Col>
                <Col className="col-1">=></Col>
                <Col className="col-5 text-nowrap"><a href={"/pl" + redirects[key]}>{redirects[key]}</a></Col>
            </Row>
        )}
    </Container>;
}