import { useContext } from 'react';
import NotificationContext from '../context/NotificationContext';

export default function useNotification() {
    const context = useContext(NotificationContext);

    return {
        notify: context.notify,
        notification: context.notification,
    };
}
