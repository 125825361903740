import styled from 'styled-components';
import Container from '../Container/Container';
import TermsCheckbox from '../TermsCheckbox/TermsCheckbox';
import { Wrapper } from '../TermsCheckbox/TermsCheckboxStyles';

export const ContainerStyled = styled(Container)`
    display: flex;
    padding: 30px 40px;
    min-height: 0;
    text-align: left; 

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
`;

export const ContainerStyled2 = styled(Container)`
    display: flex;
    padding: 30px 40px;
    min-height: 0;
    text-align: left; 

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
`;

export const Addresses = styled.div`
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    border-left: #00437E 1px solid;

    @media (max-width: 767.98px) {
        flex-direction: column;
    }

    @media (max-width: 575.98px) {
        border: none;
        border-top: #00437E 1px solid;
        margin-top: 0;
    }
`;

export const AddressSection = styled.div`
    border: #00437E 1px solid;
    border-left: none;
    color: #00437E;
    padding: 30px;
    flex: 1 1 0px;
    text-align: left;
    min-width: 300px;


    @media (max-width: 1199.98px) {
        flex-basis: 50%;
    }

    @media (max-width: 575.98px) {
        padding: 20px;
        border: none;
        border-bottom: #00437E 1px solid;
    }
`;

export const AddressHeader = styled.h2`
    margin: 0 0 30px 0;
    font-size: 20px;
    line-height: 1.1;
    font-weight: 700;
`;

export const Detail = styled.p`
    margin: 0;
    font-weight: 600;
    line-height: 1.3;
`;

export const StyledTermsCheckbox = styled(TermsCheckbox)`
    max-width: 550px;
    color: #00437E;

    & .placeholder {
        border-color: #00437E;
        width: 23px;
        height: 23px;
    }

    & input:checked + label .placeholder::after {
        background-color: #00437E;
        border-color: #00437E;
    }

    & .label {
        padding-left: 20px;
        font-size: 12px;
        font-weight: 600;
        line-height: 1;
        text-align: justify;
    }
`;

export const StyledCheckbox = styled(Wrapper)`
    color: #00437E;
    max-width: 550px;
    padding: 0 0 20px 20px;
    display: flex;

    & .placeholder {
        border-color: #00437E;
        width: 23px;
        height: 23px;
    }

    & input:checked + label .placeholder::after {
        background-color: #00437E;
        border-color: #00437E;
    }

    & .label {
        padding-left: 20px;
        font-size: 12px;
        font-weight: 600;
        line-height: 1;
        text-align: justify;
    }
`;

export const TermsContainer = styled.div`
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 575.98px) {
        flex-direction: column;
        margin-top: 20px;
    }
`;

export const ChechboxInfo = styled.p`
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    padding: 10px 0 0 70px;
    margin: 0;
    color: #00437E;
    text-align: left;
`;
