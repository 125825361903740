import React from 'react';
import {ModalWrapper} from './WelcomeModalStyles';
import Tile from "../Tile/Tile";

const WelcomeModal = ({onHide}) => {

    return <ModalWrapper onClick={e => e.stopPropagation()} size="lg" show={true} onHide={onHide}>
        <div className={'modal-tile-container bg-light-blue m-3 p-4'}>
            <h4 className={'modal-custom-close bg-white font-weight-bold px-3 py-1 font-light-blue'}
                onClick={onHide}>ZAMKNIJ &times;</h4>
            <Tile bg="dark-blue" className={'c text-center'} textMd="Uczymy" url={'https://www.facebook.com/groups/UczymyDzieciProgramowac'}/>
            <Tile bg="transparent"/>
            <Tile bg="white" image={process.env.REACT_APP_CDN_URL + 'logo-edusense-pelne.jpg'}
                  title="EduSense"/>
            <Tile className={'big px-4'} bg="dark-blue"
                  textMd={'WYSYŁKA GRATIS'}
                  textSm="Nasz sklep internetowy;działa bez zmian. Gwarantujemy szybką i darmową dostawę."
                  url={'/pl/produkty'}
            />
            <Tile bg="transparent"/>
            <Tile bg="dark-blue" textMd="Dzieci" url={'https://www.facebook.com/groups/UczymyDzieciProgramowac'}/>
            <Tile className={'big px-2 font-light-blue'} bg="white"
                  textMd={'KODUJ Z NAMI'}
                  textSm="to materiały dla uczniów przygotowane specjalnie w ramach akcji #ZostajemyWDomu &rarr;"
                  url={'https://www.youtube.com/playlist?list=PL3VS5bjUA2TiAAiqN1eRXNywmBsevHlGP'}
            />
            <Tile bg="transparent"/>
            <Tile bg="white" className={'kodowanie p-2'} image={process.env.REACT_APP_CDN_URL + 'kodowanie_logo.svg'}
                  title="Kodowanie na dywanie"/>
            <Tile bg="dark-green" textMd="Pro;gra;mować" className={'programowac'}
                  url={'https://www.facebook.com/groups/UczymyDzieciProgramowac'}
                  title="Kliknij" textLgStyle={{lineHeight: 1}}/>
            <Tile className={'big px-2'} bg="light-blue" textFooter={'FB →'}
                  textSm="Najnowsze materiały;edukacyjne;znajdziecie na"
                  url={'https://www.facebook.com/edusense'}
            />
            <Tile bg="transparent"/>
        </div>

    </ModalWrapper>
};

export default WelcomeModal;