import styled from 'styled-components';

export const TileElement = styled.a`
    ${(props) => (props.image ? `background-image:url(${props.image});` : '')}
    width: 13.5rem;
    height: 13.5rem;
    margin: 0;
    padding: 2rem;
    text-align: left;
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    transition: .5s;

    &.bg-black-week {
        background-color: #f50303;
    }
    &.bg-black-week:hover {
        background-color: #2542a4 !important;
    }
    &.bg-black-week-inverted {
        background-color: #2542a4;
    }
    &.bg-black-week-inverted:hover {
        background-color: #f50303 !important;
    }
    &.tile-big{
        width: 27rem;
    }

    & i, & span{
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        display: block;
    }
    & .main-icon{
        font-size: 700%;
        line-height: 150%;
        width: 100%;
        text-align: center;
        align-self: center;
    }    
    &.tile-buy-now h1{
        border: 2px solid #fff;
        border-radius: 50% 50%;
        width: 100%;
        height: 100%;
        padding: 15%;
        text-align: center;
    }
    &.tile-yt-btn:hover i {
        color: #00437E;
        transition: .5s;
    }
    &.tile-yt-btn:hover {
        cursor: pointer;
        background-color: #fff;
        color: #00437E;
        transition: .5s;
    }
    &.tile-buy-now:hover span {
        color: #f00;
    }    
    &.tile-buy-now:hover,  
    &.tile-buy-now:hover i, 
    &.tile-buy-now:hover h1{
        background-color: #fff;
        color: #f00;
        border-color: #f00;
    }
    &.tile-buy-now,
    &.tile-buy-now h1,
    &.tile-buy-now span {
        transition: .5s;
    }
    &.tile-buy-now:hover,
    &.tile-buy-now:hover h1,
    &.tile-buy-now:hover span {
        transition: .5s;
    }
    &.tile-puzzle-ar {
        transition: .5s;
    }
    &.tile-puzzle-ar:hover{
        background-color: #00437E;
        transition: .5s;
    }
    &.tile-udp:hover {
        background-color: #fff;
        color: #00AFFF;
        transition: .5s;
    }
    &.tile-udp:hover span {
        color: #00AFFF;
        transition: .5s;
    }
    &.tile-genibot, &.tile-blackweek {
        background-size: 100%;
    }
    &.tile-genibot:hover, &.tile-blackweek:hover {
        background-image:url('${(props) => props.imageHover}');
    }

    @media (max-width: 690px) {
        width: 10rem;
        height: 10rem;
        padding: 1rem;
        
        & i{
            font-size: 600%;
        }
    }
    
    @media (max-width: 575px) {
        display: none;
        
        &.display-mobile {
            display: block;
            width: 60vw;
            height: 60vw;
            max-width: 250px;
            max-height: 250px;
        }
    }
`;
