import React, { useContext, useState } from 'react';
import { Button, CartIcon } from './ButtonAddToCartStyles';
import useCart from '../Cart/hook/useCart';
import CartItemValueObject from '../Cart/valueObject/CartItemValueObject';
import useNotification from '../NotificationContainer/hook/useNotification';
import AppContext from '../../context/app-context';
import escapeDiacritics from '../../tools/escapeDiacritics';

export default function ButtonAddToCart({
    isDisabled, isBlackWeek, className, hoverImageUrl, item: {
        id, sku, name, price, priceBefore, image, preOrderText, stockText, isLargeSize, isDigital, url,
        contentType, isVisible = true, isBuyable, maxQuantityPerOrder,
    },
}) {
    const { notify } = useNotification();
    const { addItem } = useCart();
    const [clicked, setClicked] = useState(false);
    const handleClick = () => {
        addItem(new CartItemValueObject(id, sku, name, price, priceBefore, image, preOrderText, stockText, isLargeSize,
            isDigital, url, contentType, isVisible, isBuyable, maxQuantityPerOrder));
        notify(`Produkt o nazwie "${name}" dodany do koszyka`);
        setClicked(true);

        window.gtag('event', 'add_to_cart', {
            items: [{
                id: sku,
                name: escapeDiacritics(name),
                quantity: 1,
                price,
            }],
        });

        window.fbq('track', 'AddToCart', {
            content_name: escapeDiacritics(name),
            content_type: 'product',
            content_ids: [sku ?? id],
            value: price,
            currency: 'PLN',
        });
    };

    const appContext = useContext(AppContext);
    const language = appContext.lang;

    const translations = {
        added: { pl: 'Dodany', en: 'Added' },
        add: { pl: 'Do\u00a0koszyka', en: 'To\u00a0cart' },
    };

    return (
        <Button
            disabled={isDisabled}
            className={(className || '') + (clicked ? ' clicked' : '') + (isBlackWeek ? ' black-week' : '')}
            onClick={handleClick}
        >
            {clicked
                ? <i className="fa fa-fw fa-check" style={{ fontSize: '1.25em' }} />
                : <CartIcon hoverImageUrl={hoverImageUrl} />}
            &nbsp;&nbsp;&nbsp;
            {clicked ? translations.added[language] : translations.add[language]}
        </Button>
    );
}
