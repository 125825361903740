import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
    &.modal-content {
        background-color: #F7F7F7;
    }
`;

export const StyledModalBody = styled(Modal.Body)`
`;

export const ModalInfo = styled.h4`
    font-weight: 800;
    font-size: 20px;
    line-height: 1;
    color: #00437E;
`;
