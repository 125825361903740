import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
    &.modal-content {
        background-color: #F7F7F7;
    }
`;

export const StyledButton = styled(Button)`
    color: #fff;
    font-size: 1.3em;
    font-weight: 700;
    background-color: #00437E;
    border: 2px solid #00437E;
    width: 100%;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    
    &:hover {
        color: #00437E;
        background-color: white;
    }
`;

export const RedStyledButton = styled(StyledButton)`
    background-color: #39B54A;
    border-color: #39B54A;

    &:hover {
        color: #39B54A;
        border-color: #39B54A;
    }

    &:active {
        color: #39B54A !important;
        border-color: #39B54A !important;
        background-color: white !important;
        outline-color: #39B54A !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem rgb(57 181 74 / 50%) !important;
    }
`;

export const StyledModalBody = styled(Modal.Body)`
`;

export const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding: 10px 30px;

    &button {
        font-weight: 700;
    }
`;

export const ModalInfo = styled.h4`
    font-weight: 800;
    font-size: 20px;
    line-height: 1;
    color: #00437E;
`;
