import styled from 'styled-components';
import { Button as BootstrapButton } from 'react-bootstrap';
import StandardInput from '../Input/Input';

export const Wrapper = styled.div`
    line-height: 0.6;
    display: flex;
    align-items: center;
    min-width: 200px;
    
    & .font-small {
        font-size: 0.8em;
    }

    @media (max-width: 575.98px) { 
        justify-content: center;
    } 
`;

export const Input = styled(StandardInput)`
    margin-bottom: 0;
    
    & input {
        text-transform: uppercase;
        max-width: 300px;
        font-size: 0.7em;
        outline: none;
        height: 100%;
        border: #00437E 2px solid;
        background-color: #00437E;
        color: #FFFFFF;
        padding: 6px;
        max-height: 35px;

        @media (max-width: 575.98px) {
            max-width: 100%;
            width: 100%;
            font-size: 1em;
            max-height: 50px;
            height: 50px;
        }
    }

    & input:focus {
        outline: none;
        box-shadow: none;
        border-color: #00437E;
        background-color: #00437E;
        color: #FFFFFF;
    }

    & .is-invalid {
        outline: none;
        box-shadow: none;
        border-color: red;
        background-color: red;
        padding-right: 0;
        background-image: unset;
        color: #FFFFFF;

        &:focus {
            outline: none;
            box-shadow: none;
            border-color: red;
            background-color: red;
            padding-right: 0;
            background-image: unset;
            color: #FFFFFF;
        }
    }

    & .invalid-feedback {
        margin-top: 10px;
    }
    
    & label {
        display: none;
    }

    & ::placeholder {
        color: #FFFFFF;
        font-weight: 700;
        text-transform: none;
        text-align: center;
    }

    @media (max-width: 575.98px) {
        width: 100%;
    }
`;

export const Button = styled(BootstrapButton)`
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    background-color: #FFFFFF;
    color: #39B54A;
    border: #FFFFFF 2px solid; 
    border-radius: 0;
    outline: none;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    align-self: stretch;
    font-size: 0.7em;
    outline: none;
    line-height: 1;
    max-height: 35px;

    &:focus {
        box-shadow: none;
    }
        
    &:active {
        filter: brightness(1.2);
        color: #FFFFFF !important;
        background-color: #39B54A !important;
        border-color: #FFFFFF !important;
        outline: none;
        box-shadow: none !important;
    }

    &:hover {
        color: #FFFFFF;
        background-color: #39B54A;
        border: #FFFFFF 2px solid; 
    }

    &:hover * {
        border-color: white; 
    }

    @media (max-width: 575.98px) {
        max-height: 50px;
        height: 50px;
        width: 50px;
        font-size: 18px;
    }
`;

export const RemoveButton = styled.button`
    color: #FFFFFF;
    background-color: #39B54A;
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 11px;
    max-height: 37px;

    &:hover {
        background-color: #FFFFFF;
        color: #39B54A;
    }
`;

export const Message = styled.div`
    margin: 0;
    color: #FFFFFF;
    line-height: 25px;
    font-size: 1rem;

    @media (max-width: 575.98px) {
        width: 100%;
    }
`;

export const MessageContainer = styled.div`
    display: flex;

    @media (max-width: 575.98px) {
        width: 100%;
    }
`;

export const DiscountCode = styled.div`
    border: #FFFFFF 2px solid;
    font-size: 1rem;
    padding: 4px 20px;
    text-transform: uppercase;
`;

export const DiscountValue = styled.div`
    display: flex;
    color: #FFFD00;
    font-size: 1rem;
    text-transform: uppercase; 
    margin-top: 5px;
    justify-content: center;
    max-width: 220px;
    line-height: 1.1;  

    @media (max-width: 575.98px) {
        max-width: 100%; 
    } 
`;
