import { Carousel } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledContact = styled.section`
    font-family: 'Roboto Condensed';
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 30px;
    justify-content: center;

    @media (max-width: 575px) {
        margin: 0 -24px;
    }
`;

export const DepartmentHeader = styled.h3`
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-right: 50px;

    @media (max-width: 992px) {
        margin-right: 0;
    }
`;

export const EmployeesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${({ numberOfEmployees: number }) => (number ? `${number}` : '1')}, 1fr);

    @media (max-width: 575px) {
        display: none;
    }
`;

export const EmployeesCarousel = styled(Carousel)`
    & a.carousel-control-next, a.carousel-control-prev {
        height: 70%;
    }

    @media (min-width: 575.01px) {
        display: none;
    }
`;

export const EmployeeCarouselItem = styled(Carousel.Item)`

`;
