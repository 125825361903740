import React, { useRef, useState } from 'react';
import {
    FormControl,
    FormGroup,
    FormLabel,
    WarningMessage,
} from './InputStyles';

export default function Input({
    className, id, label, feedback, placeholder, onChange, type = 'text', disabled = false,
    isInvalid = false, required = true, onBlur = () => {}, minLength = null, maxLength = null,
    value,
}) {
    const setPattern = () => {
        switch (type) {
        case 'email':
            return "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$";
        case 'postcode':
            return '^([0-9]){2}-([0-9]){3}$';
        case 'nip':
            return '^((\\d{3}[- ]?\\d{3}[- ]?\\d{2}[- ]?\\d{2})|(\\d{3}[- ]?\\d{2}[- ]?\\d{2}[- ]?\\d{3}))$';
        case 'tel':
            return '^(?:\\(?(\\+|00)?48)?(?:[-\\.\\(\\)\\s]*(\\d)){9}\\)?$';
        case 'fullName':
            return '.*[a-zA-Z0-9]+.*';
        default:
            return '\\S+.+';
        }
    };

    const pattern = setPattern();
    const inputRef = useRef(null);

    const filterInput = (e) => {
        if (type === 'postcode') {
            if (!/^\d*[-,]?\d*$/.test(e.currentTarget.value)) {
                e.currentTarget.value = e.currentTarget?.oldValue;
            }
            if (e.currentTarget.value.length === 2 && e.currentTarget.oldValue.length === 1) {
                e.currentTarget.value = `${e.currentTarget.value}-`;
            }
            e.currentTarget.oldValue = e.currentTarget.value;
        }
    };

    const [wasValidated, setWasValidated] = useState(false);
    const isEmailValid = type === 'email' ? inputRef.current?.value.match(/\.\./g) === null : true;
    const isInputValueMatchPattern = new RegExp(pattern).test(inputRef.current?.value) && isEmailValid;

    const handleChange = (e) => {
        filterInput(e);

        const newValue = e.currentTarget.value;

        if (onChange) {
            onChange(newValue === '' ? null : newValue);
        }
    };

    return (
        <FormGroup controlId={id} className={className}>
            <FormLabel disabled={disabled}>{label}</FormLabel>
            <FormControl
                ref={inputRef}
                onChange={handleChange}
                onClick={handleChange}
                disabled={disabled}
                required={required}
                type={type === 'email' ? 'text' : type}
                placeholder={placeholder || ''}
                isInvalid={value !== '' && (isInvalid || (wasValidated && !isInputValueMatchPattern))}
                isValid={wasValidated && isInputValueMatchPattern}
                pattern={pattern}
                minLength={minLength}
                maxLength={maxLength}
                onBlur={() => {
                    setWasValidated(id !== 'discount' && true);
                    onBlur();
                }}
                value={value}
            />
            <WarningMessage type="invalid">
                {feedback || `Podaj ${label.toLowerCase()}`}
            </WarningMessage>
        </FormGroup>
    );
}
