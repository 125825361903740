import ClientOAuth2 from 'client-oauth2';
import Cookies from 'universal-cookie';

export function getClient() {
    return new ClientOAuth2({
        clientId: process.env.REACT_APP_API_CLIENT_ID,
        clientSecret: process.env.REACT_APP_API_CLIENT_SECRET,
        accessTokenUri: process.env.REACT_APP_API_ACCESS_TOKEN_URI,
        redirectUri: '',
        scopes: []
    });
}

function getClientByUsernamePassword(username, password) {
    return new ClientOAuth2({
        clientId: process.env.REACT_APP_API_CLIENT_ID,
        clientSecret: process.env.REACT_APP_API_CLIENT_SECRET,
        accessTokenUri: process.env.REACT_APP_API_ACCESS_TOKEN_URI,
        redirectUri: '',
        grantType: 'password',
        username: username,
        password: password,
        scopes: []
    });
}

export async function authorize() {
    const cookies = new Cookies();

    if (cookies.get('token')) return;

    await getClient().owner.getToken('api', 'api').then(token => {
        cookies.set('token', token.data, {path: '/', httpOnly: false, SameSite: 'None'});
        cookies.set('token_expires', token.expires, {path: '/', httpOnly: false, SameSite: 'None'});
    });
}

export async function authorizeByUsernamePassword(username, password, forceCheck = false) {
    const cookies = new Cookies();
    if (!forceCheck && cookies.get('token')) return false;
    try {
        const token = await getClientByUsernamePassword().owner.getToken(username, password).then(token => token);
        if (token && token.data) {
            cookies.set('token', token.data, {path: '/', httpOnly: false, SameSite: 'None'});
            cookies.set('token_expires', token.expires, {path: '/', httpOnly: false, SameSite: 'None'});
            return true;
        }
    } catch (e) {}

    return false;
}

export async function getToken() {
    const cookies = new Cookies();

    const token = cookies.get('token');
    if (!token || token === '') {
        await authorize();
    }

    let currentTime = new Date().getTime();
    let tokenExpires = new Date(cookies.get('token_expires')).getTime();

    if (currentTime > tokenExpires) {
        const token = getClient().createToken(
            cookies.get('token').access_token, cookies.get('token').refresh_token
        );
        if (token && token.refreshToken) {
            token.refresh().then(token => {
                cookies.set('token', token.data, {path: '/', httpOnly: false, SameSite: 'None'});
                cookies.set('token_expires', token.expires, {path: '/', httpOnly: false, SameSite: 'None'});
            });
        }
    }

    return cookies.get('token').access_token;
}
