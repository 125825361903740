import styled, { css } from 'styled-components';
import { Form } from 'react-bootstrap';

export const FormGroup = styled(Form.Group)`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    
    & input {
        margin-bottom: 0;
    }
`;
export const FormLabel = styled(Form.Label)`
    flex: 1 1 auto;
    display: block;
    margin-bottom: 0;
    color: #00437E;
    font-weight: 700;
    &:first-letter {
        text-transform: capitalize;
    }

    ${(props) => props.disabled && css`
        color: #A1A1A1;
    `};
`;
export const FormControl = styled(Form.Control)`
    flex: 1 1 auto;
    display: block;
    margin-bottom: 10px;
    padding: 10px;
    color: #00437E;
    background-color: white;
    outline-color: #00AFFF;
    border: 1px #00437E solid;
    border-radius: 0;
    font-weight: 600; 
    
    ${(props) => props.disabled && css`
        border: 1px #A1A1A1 solid;
        background-color: white !important;
    `};
`;

export const WarningMessage = styled(Form.Control.Feedback)`
    color: red;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    text-align: ${(props) => (props.iscentered ? 'center' : 'left')};

    @media (max-width: 575.98px) {
        white-space: unset;
    }
`;
