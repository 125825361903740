import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Container from '../../Container/Container';
import { FooterContactButton } from '../../Footer/FooterStyles';
import { PersonalCard, StyledColumn } from './CompanyStyles';
import AppContext from '../../../context/app-context';

export default function Company() {
    const appContext = useContext(AppContext);
    const { setGoogleData } = appContext;
    useEffect(() => {
        setGoogleData({ ecomm_pagetype: 'other' });
    }, [setGoogleData]);
    useEffect(() => window.fbq('track', 'PageView'), []);

    return (
        <Container className="font-size18">
            <Row>
                <Col xs="12" lg="3" className="text-left font-dark-blue mt-4 pt-5 pl-5">
                    <div className="font-weight-bold h3 mt-5">Kanały dystrybucji</div>
                    <p style={{ paddingRight: '15px' }}>
                        Nasze roboty pracują w ponad 2000 szkół, przedszkoli i innych placówek edukacyjnych na terenie całej
                        Polski. Wiele tysięcy nauczycieli korzysta z przygotowanych przez nas materiałów dydaktycznych do
                        nauki programowania w klasach 1-8 szkoły podstawowej oraz w zerówkach i przedszkolach. Prowadzimy
                        zajęcia w dużych i mniejszych miastach, także na terenach wiejskich. Uczestniczyliśmy w większości
                        znaczących konferencji dotyczących edukacji w Polsce.
                    </p>
                </Col>
                <Col xs="12" lg="9">
                    <img className="img-fluid w-100" src={`${process.env.REACT_APP_CDN_URL}distribution.png`} alt="" />
                </Col>
            </Row>
            <Row className="px-0 mt-2 mx-0">
                <Col xs="12" lg="6">
                    <Row className="h-100">
                        <Col
                            className="text-left font-dark-blue mr-1 bg-light-blue d-flex flex-column justify-content-center"
                        >
                            <div className="p-5">
                                <div className="font-weight-bold h3 mb-4">Pakiet dla prasy</div>
                                <p className="mt-2">
                                    Kliknij w poniższy przycisk aby pobrać pakiet przygotowany dla
                                    prasy.

                                </p>
                                <FooterContactButton
                                    className="col-xs-10 col-sm-6 col-lg-4 mt-4"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.dropbox.com/home/sp%C3%B3%C5%82ki/EduSense/marketing/!-materialy-dla-prasy"
                                >
                                    POBIERZ
                                </FooterContactButton>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs="12" lg="6">
                    <Row className="h-100">
                        <Col className="px-0 bg-light-blue ml-lg-1 mt-lg-0 mt-2">
                            <img
                                className="img-fluid w-100 h-100"
                                src={`${process.env.REACT_APP_CDN_URL}company-photo-press.jpg`}
                                alt=""
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="px-0 mx-0 mt-5">
                <Col xs="12" lg="4" className="text-left font-dark-blue px-5">
                    <div className="font-weight-bold h3 mt-5">ZARZĄD</div>
                    <p className="pb-4 pt-2">
                        W skład zarządu spółki EduSense SA wchodzą dwie osoby: prezes zarządu
                        Rafał Mitkowski posiadający wieloletnie doświadczenie w branży edukacyjnej w Polsce oraz
                        wiceprezes zarządu Mieszko Bisewski specjalista od spraw finansowych.
                    </p>
                </Col>
                <StyledColumn
                    xs="12"
                    lg="8"
                    className="px-0 d-flex flex-row justify-content-around align-items-center pt-5 pl-5"
                >
                    <PersonalCard className="mx-4" border="white">
                        <Card.Img src={`${process.env.REACT_APP_CDN_URL}avatar-rafal-mitkowski.png`} />
                        <Card.Body>
                            <Card.Title as="h4" className="font-light-blue font-weight-bold">
                                Rafał Mitkowski |
                                CEO
                            </Card.Title>
                            <Card.Text>
                                <a
                                    href="https://www.linkedin.com/in/rafa%C5%82-mitkowski-9b418a5a/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="h3 social-icon"
                                >
                                    <i className="fab fa-linkedin" />
                                </a>
                            </Card.Text>
                        </Card.Body>
                    </PersonalCard>
                    <PersonalCard className="mx-4" border="white">
                        <Card.Img src={`${process.env.REACT_APP_CDN_URL}avatar-mieszko-bisewski.png`} />
                        <Card.Body>
                            <Card.Title as="h4" className="font-light-blue font-weight-bold">
                                Mieszko Bisewski |
                                CFO
                            </Card.Title>
                            <Card.Text>
                                <a
                                    href="https://www.linkedin.com/in/mieszkobisewski/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="h3 social-icon"
                                    disabled
                                >
                                    <i className="fab fa-linkedin" />
                                </a>
                            </Card.Text>
                        </Card.Body>
                    </PersonalCard>
                </StyledColumn>
            </Row>
            <Row className="px-0 mx-0 mt-5">
                <Col xs="12" lg="4" className="text-left font-dark-blue px-5">
                    <div className="font-weight-bold h3 mt-5">RADA NADZORCZA</div>
                    <p className="pb-4 pt-2">
                        Radę Nadzorczą spółki EduSense SA tworzą ludzie wspierający swoim
                        doświadczeniem zarząd spółki. Członkowie Rady Nadzorczej od wielu lat działają w branżach
                        związanych z prawem, rynkiem inwestycyjnym, a także sprzedażą elektroniczną i tradycyjną.
                    </p>
                </Col>
                <StyledColumn
                    xs="12"
                    lg="8"
                    className="px-0 d-flex flex-row justify-content-around align-items-center pt-5 pl-5"
                >
                    <PersonalCard className="mx-4" border="white">
                        <Card.Img src={`${process.env.REACT_APP_CDN_URL}avatar-marek-trojanowicz.png`} />
                        <Card.Body>
                            <Card.Title as="h4" className="font-light-blue font-weight-bold">
                                Marek
                                Trojanowicz
                            </Card.Title>
                            <Card.Text>
                                <a
                                    href="https://www.linkedin.com/in/marektrojanowicz/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="h3 social-icon"
                                >
                                    <i className="fab fa-linkedin" />
                                </a>
                            </Card.Text>
                        </Card.Body>
                    </PersonalCard>
                    <PersonalCard className="mx-4" border="white">
                        <Card.Img src={`${process.env.REACT_APP_CDN_URL}avatar-adam-meszczynski.png`} />
                        <Card.Body>
                            <Card.Title as="h4" className="font-light-blue font-weight-bold">
                                Adam
                                Meszczyński
                            </Card.Title>
                            <Card.Text>
                                <a
                                    href="https://www.linkedin.com/in/adammeszczynski/"
                                    rel="noopener noreferrer"
                                    className="h3 social-icon"
                                >
                                    <i className="fab fa-linkedin" />
                                </a>
                            </Card.Text>
                        </Card.Body>
                    </PersonalCard>
                </StyledColumn>
            </Row>
            <Row className="px-0 mx-0">
                <Col xs="12" sm="6" className="text-left font-dark-blue mt-4 px-5" />
                <Col xs="12" sm="6" className="mt-4 px-5 font-dark-blue text-left">
                    <div className="font-weight-bold h3 mt-5">MARKA</div>
                    <p>
                        Zarówno nazwa EduSense jak i #UczymyDzieciProgramować jest zastrzeżonym znakiem towarowym numer
                        zgłoszenia Z.477588
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
