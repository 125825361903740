import React from 'react';
import {
    CardBody,
    CardFooter,
    CardStyled,
    CardWrapper,
    PriceElement,
} from "./CardStyles";
import {EmptyCardImg, EmptyCardText, EmptyCardTitle} from "./EmptyCardStyles";

export default function EmptyCard() {
    return (
        <CardWrapper className="flex-grow-1 flex-shrink-0">
            <CardStyled className="d-flex flex-row flex-wrap">
                <EmptyCardImg/>
                <CardBody>
                    <EmptyCardTitle>&nbsp;</EmptyCardTitle>
                    <EmptyCardText>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                    </EmptyCardText>
                </CardBody>
                <CardFooter className="w-100 bg-transparent border-0 d-flex">
                    <PriceElement className="text-right pr-4 d-flex flex-column justify-content-end"/>
                </CardFooter>
            </CardStyled>
        </CardWrapper>
    );
}