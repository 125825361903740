import {Carousel} from "react-bootstrap";
import styled from "styled-components";

export const CarouselStyled = styled(Carousel)`
    & a.carousel-control-prev, & a.carousel-control-next {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: auto;
        color: #fff;
        text-align: center;
        opacity: 1;
        transition: opacity .15s ease;
    }
    
    & a.carousel-control-prev {
        left: 0;
        z-index: 2;
    }
    
    & a.carousel-control-next {
        right: 0;
        z-index: 2;
    }
`;

export const Icon = styled.i`
    background-color: #00AFFF;
    padding: 20px 25px;
    font-size: 2em;
`;

export const IndicatorsContainer = styled.ol`
    z-index: 2;
    position: static;
    display: flex;
    justify-content: center;
    padding: 5px;
    margin: 0;
    background-color: white;
`;

export const Indicator = styled.li`
    ${IndicatorsContainer} & {
        position: relative;
        margin: 5px;
        height: auto;
        width: auto;
        border: 0;
    }
    
    & img {
        opacity: 0.5;
        max-height: 100px;
        max-width: 100%;
    }
    
    &.active img {
        opacity: 1;
    }
    
    &:hover img {
        opacity: 0.75;
    }
`;