export default function OrderClient(
    firstName,
    lastName,
    email,
    phone,
    recipientName,
    recipientAddress,
    recipientPostCode,
    recipientCity,
    recipientComment
) {
    this.first_name = firstName;
    this.last_name = lastName;
    this.email = email;
    this.phone = phone;
    this.recipient_name = recipientName;
    this.recipient_address = recipientAddress;
    this.recipient_postcode = recipientPostCode;
    this.recipient_city = recipientCity;
    this.recipient_comment = recipientComment;

    return Object.freeze(this);
}