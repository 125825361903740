import styled from 'styled-components';
import { Col, Row as BootstrapRow } from 'react-bootstrap';
import ContainerStyled from '../../Container/Container';

export const StyledContainer = styled(ContainerStyled)`
    display: flex;
    min-height: 0;
    flex-direction: column;
`;

export const Row = styled(BootstrapRow)`
    background-color: #F0F0F0;
    margin: 0;
    text-align: left;
    
    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
`;

export const ProductsRow = styled(Row)`
    color: #00437E;
    font-size: 1.2em;
    font-weight: 600;
`;

const animation = `
    background-image: linear-gradient(90deg, #e9e8e8, #a9a9a9);
    background-size: 400% 400%;
    background-position: 0 0;
    background-repeat: no-repeat;
 
    animation: move 1s ease infinite;
    
    @keyframes move {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
`;

export const RowHeader = styled(Col)`
    color: white;
    font-weight: 700;
    font-size: 24px;
    background-color: #00437E;
    padding: 30px 40px;
    text-align: center;
    margin-top: 40px;

    @media (max-width: 991.98px) {
        margin-top: 0;
    }

    @media (max-width: 575.98px) { 
        font-size: 18px;
        padding: 20px;
    } 

    a {
        text-decoration: underline;
        color: white;

        &:hover {
            color: #00AFFF;
        }
    }
    
    &.empty {
        ${animation}
    }
`;

export const FormValue = styled.div`
    flex: 1 1 auto;
    display: block;
    margin-bottom: 10px;
    font-size: 1.3em;
    font-weight: 700;
    color: #00AFFF;
    text-overflow: ellipsis;
    word-break: break-word;
`;

export const Container = styled(ContainerStyled)`
    margin-bottom: 3rem;
`;

export const PaymentInfo = styled.div`
    display: block;
    margin: 0 auto 10px auto;
    border: 1px solid #0194d7;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3;
    color: #00437E;
    text-align: left;
    flex-basis: 55%;

    & span  {
        text-transform: uppercase;
        color: #00AFFF;
    }

    & small {
        font-size: 100%;
    }

    padding: 40px 60px;
    margin: 40px 0 0 40px;

    @media (max-width: 991.98px) {
        padding: 40px 60px;
        margin: 0;
    }

    @media (max-width: 767.98px) {
        padding: 20px 30px;
    }
`;

export const List = styled.ol`
    padding: 0;
    margin: 0;
    background-color: #F7F7F7;
`;

export const ProductsCol = styled(Col)`
    padding: 0;
`;

export const SuccessPaidParagraph = styled.p`
    max-width: 920px;
    margin: 0 auto;
    padding: 40px 80px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.3;
    background-color: #39B54A;
    color: #FFFFFF;
    margin-top: 40px;

    &.two-columns {
        display: flex;
        align-items: center;
        text-align: left;
        flex-basis: 45%;
    }
    
    @media (max-width: 991.98px) {
        padding: 40px 60px;
        margin: 0;
        max-width: unset;
    }

    @media (max-width: 767.98px) {
        margin: 0;
        padding: 20px 30px;
        font-size: 16px;
    }
`;

export const SummaryColumn = styled(Col)`
    display: flex;
    align-items: center;
    background-color: #00437E;
    color: white;
    padding: 30px 40px;

    @media (max-width: 767.98px) {
        margin: 0;
        padding: 20px 30px;
    }
`;

export const DeliveryColumn = styled(SummaryColumn)`
    justify-content: flex-start;

    @media screen and (max-width: 900px) {
        white-space: nowrap ;
    }

    @media (max-width: 767.98px) {
        margin: 0;
        padding: 20px;
    }
`;

export const PriceColumn = styled(SummaryColumn)`
    justify-content: flex-end;
`;

export const TotalRow = styled(BootstrapRow)`
    background-color: #00437E;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media screen and (max-width: 900px) {
        flex-wrap: wrap;
    }
    
    &.empty ${SummaryColumn} {
        ${animation}
    }    
`;

export const InfoContainer = styled.div`
    display: flex;

    @media (max-width: 991.98px) {
        flex-direction: column;
    }
`;
