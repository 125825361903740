import React from 'react';
import { TileElement } from "./TileStyles";

export default function Tile({ textLg, textMd, textSm, textFooter, image, imageHover, icon, bg, url, hover, className, altText = "", title = "", onClick, textLgStyle={} }) {
    return (
        <TileElement title={title} onClick={onClick} image={image} imageHover={imageHover} href={url} target="_blank" rel="noopener noreferrer"
                     className={"tile bg-" + bg
                     + (className ? " tile-" + className : "")
                     + ( bg === 'transparent' ? ' d-none d-lg-block' : ( !onClick && !url ? ' d-none d-md-block' : '')) }>
            {textLg ? <h1 style={textLgStyle}>{textLg.split(';').map(e => (<span key={e}>{e}<br /></span>))}</h1> : null}
            {textMd ? <h4>{textMd.split(';').map(e => (<span key={e}>{e}<br /></span>))}</h4> : null}
            {textSm ? <h6>{textSm.split(';').map(e => (<span key={e}>{e}<br /></span>))}</h6> : null}
            {textFooter ? <h4>{textFooter.split(';').map(e => (<span key={e}>{e}<br /></span>))}</h4> : null}
            {icon ? <i className={icon + " main-icon"} title={title}/> : null}
        </TileElement>
    );
}
