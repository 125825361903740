import React from 'react';
import {Col, Row} from "react-bootstrap";
import Container from "../../Container/Container";
import {ContactText, ErrorText, Image, Rectangle} from "./NotFoundStyles";

export default function NotFound() {
    return (
        <Container className="mt-5">
            <Row className="m-0">
                <Col className="col-lg-5 col-sm-6 d-none d-sm-flex flex-column justify-content-center">
                    <Image url={process.env.REACT_APP_CDN_URL + '/tonge.png'}/>
                </Col>
                <Col className="col-lg-7 col-12 col-sm-6 px-5 px-sm-0">
                    <Row className="ml-5 ml-sm-0">
                        <Rectangle color="#00437e"/>
                        <ErrorText>
                            <span>Błąd 404</span>
                            <h3>Ups. Wygląda na to, że ta strona nie istnieje.</h3>
                            <h3><a href="/">Idź na start »</a></h3>
                        </ErrorText>
                    </Row>
                    <Row className="d-flex flex-row ml-5 ml-sm-0">
                        <Rectangle color="transparent"/>
                        <Rectangle color="#00AFFF"/>
                        <ContactText className="d-flex flex-sm-column flex-row justify-content-between justify-content-sm-start">
                            <div className="col-lg-auto col-sm-6 col-5 px-0 h-auto">
                                W celu uzyskania szczegółowej informacji prosimy o kontakt z naszym Biurem Obsługi Klienta:
                            </div>
                            <div className="mt-sm-3 col-lg-auto col-sm-6 col-5 px-0">
                                e-mail: <a href="mailto:kontakt@edu-sense.pl?subject=Błąd 404 na stronie Edu-Sense.com">kontakt@edu-sense.pl</a>
                                <br/>
                                tel.: +48 585 055 914
                                <br/>
                                <a href="/">www.edu-sense.com</a>
                            </div>
                        </ContactText>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}