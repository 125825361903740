export default function Order(
    incentive,
    deliveryType,
    deliveryExtra,
    deliveryInpostAddress,
    deliverySplit,
    items,
    client,
    discount,
    payment,
    referer,
    marketingConsent,
) {
    this.referer = referer;
    this.incentive = incentive;
    this.discount = discount;
    this.delivery_type = deliveryType;
    this.delivery_extra = deliveryExtra;
    this.delivery_inpost_address = deliveryInpostAddress;
    this.delivery_split = deliverySplit;
    this.items = items;
    this.client = client;
    this.payment = payment;
    this.marketing_consent = marketingConsent;

    Object.freeze(this);
}
