import React, {
    useContext, useEffect, useRef, useState,
} from 'react';
import { withRouter } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useSelector } from 'react-redux';
import Tile from '../Tile/Tile';
import AppContext from '../../context/app-context';
import {
    CartBadge,
    CartElement,
    CartIcon,
    CartNavElement,
    CloseIcon,
    EventVideoWrapper,
    HamburgerIcon,
    HeaderContainer,
    LogoImage,
    MobileNav,
    NavbarCollapse,
    NavElement,
    NavToggle,
    NavWrapper,
    SearchWrapper,
    TileContainer,
} from './HeaderStyles';
import useCart from '../Cart/hook/useCart';
import Modal from '../Modal/Modal';
import WelcomeModal from './WelcomeModal';
import TickerBar from '../TickerBar/TickerBar';
import { getNoAuth } from '../../api/Api';
import CartContext from '../Cart/context/cartContext';
import Search from '../Search/Search';
import { selectIsSearchActive } from '../Search/searchSlice';

export default withRouter(() => {
    const today = (new Date()).getDate().toString();
    const appContext = useContext(AppContext);
    const { itemsCount } = useCart();
    const headerRef = useRef({ clientHeight: 0 });
    const pagesWithSearchInHeader = ['lab', 'labs'];
    const [isEventVideo] = useState(true);
    const [isHomePage, setIsHomePage] = useState(appContext.page === 'home');
    const [isProductPage, setIsProductPage] = useState(pagesWithSearchInHeader.includes(appContext.page));
    const [isWelcomeModal, setIsWelcomeModal] = useState(false);
    const [isCompact, setIsCompact] = useState(window.scrollY > 50 || !isHomePage);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const cartContext = useContext(CartContext);
    const { setNumberOfActiveStep } = cartContext;
    const isSearchActive = useSelector(selectIsSearchActive);

    const translations = {
        products_for_school: { pl: 'Dla szkół', en: 'For schools' },
        products_for_preschool: { pl: 'Dla przedszkoli', en: 'For preschools' },
        products: { pl: 'Produkty', en: 'Products' },
        courses: { pl: 'Szkolenia', en: 'Courses' },
        labs: { pl: 'Laboratoria przyszłości', en: 'Lab' },
        events: { pl: 'Warsztaty', en: 'Events' },
        company: { pl: 'Firma', en: 'Company' },
        academy: { pl: 'AKADEMIA', en: 'Academy' },
        help: { pl: 'Pomoc', en: 'Help' },
        faq: { pl: 'Pomoc', en: 'Help' },
        cart: { pl: 'Koszyk', en: 'Cart' },
        regulations: { pl: 'Regulamin', en: 'Regulations' },
        certifications: { pl: 'Certyfikaty', en: 'Certification' },
        warranty: { pl: 'Gwarancja', en: 'Warranty' },
        privacy: { pl: 'Polityka Prywatności', en: 'Privacy' },
        contact: { pl: 'Kontakt', en: 'Contact' },
    };

    useEffect(() => {
        const handleScroll = () => setIsCompact(window.scrollY > 50 || !isHomePage);

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isCompact, isHomePage]);

    useEffect(() => {
        setIsHomePage(appContext.page === 'home');
        setIsProductPage(pagesWithSearchInHeader.includes(appContext.page));

        if (['products', 'courses'].indexOf(appContext.page) === -1 && translations[appContext.page]) {
            window.document.title = `${translations[appContext.page][appContext.lang]} EduSense`;
        }
        // eslint-disable-next-line
    }, [appContext.page, appContext.lang]);

    useEffect(() => {
        getNoAuth('content/breaking-news').then((breakingNews) => {
            if (breakingNews.error === undefined) {
                appContext.setBreakingNews(breakingNews);
            } else {
                appContext.setBreakingNews(null);
            }
        }).catch(() => appContext.setBreakingNews(null));
        // eslint-disable-next-line
    }, []);

    const getEventVideo = (height, width) => (
        window.innerWidth >= 991 && isHomePage && isEventVideo
            ? (
                <EventVideoWrapper
                    style={{ height: `${height}px`, width: `${width}px` }}
                >
                    <video
                        id="event-video"
                        autoPlay
                        muted
                        loop
                        poster={`${process.env.REACT_APP_CDN_URL}ES_Genibot_loop.jpg`}
                    >
                        <source
                            id="mp4"
                            src={`${process.env.REACT_APP_CDN_URL}ES_Genibot_loop-compressed.mp4`}
                            type="video/mp4"
                        />
                    </video>
                </EventVideoWrapper>
            ) : null
    );

    const getModal = () => <Modal isOpen={isModalOpen} onHide={() => setIsModalOpen(false)} />;

    const closeWelcomeModal = () => {
        window.localStorage.setItem('popup-koronawirus', today);
        setIsWelcomeModal(false);
    };

    const getWelcomeModal = () => <WelcomeModal onHide={closeWelcomeModal} />;

    const getTileContainer = () => (
        <TileContainer className="tile-container">
            <Tile bg="light-blue" textLg="Uczymy" />
            <Tile
                bg="transparent"
                url={`${appContext.lang}${appContext.routeMap.products.path[appContext.lang]}`}
            />
            <Tile bg="dark-blue" textLg="Dzieci" />
            <Tile
                bg="light-blue"
                className="udp pt-4 display-mobile"
                textMd="#Uczymy;Dzieci;Programować"
                title="Kliknij"
                url="https://uczymydzieciprogramowac.pl/?utm_medium=es&utm_source=main"
            />
            <Tile
                bg="dark-green"
                textLg="Pro;gra;mować"
                url="https://uczymydzieciprogramowac.pl/?utm_medium=es&utm_source=main"
                title="Kliknij"
                textLgStyle={{ lineHeight: 1 }}
            />
            <Tile
                bg="dark-blue"
                icon="fab fa-youtube fa-6"
                className="yt-btn"
                onClick={() => setIsModalOpen(!isModalOpen)}
                title="Kliknij"
            />
            <Tile
                bg="transparent"
                url={`${appContext.lang}${appContext.routeMap.products.path[appContext.lang]}`}
            />
            <Tile
                hover="dark-blue"
                className="genibot display-mobile"
                image={`${process.env.REACT_APP_CDN_URL}/tiles/button_lab_pink2.svg`}
                imageHover={`${process.env.REACT_APP_CDN_URL}/tiles/button_lab_blue2.svg`}
                title="Laboratoria przyszłości"
                url={`${appContext.lang}${appContext.routeMap.labs.path[appContext.lang]}#laboratoria-przyszlosci`}
            />
            <Tile bg="dark-blue" textMd="GENIBOT;GENIUSZ;WŚRÓD;ROBOTÓW" />
            <Tile
                hover="dark-blue"
                className="genibot"
                image={`${process.env.REACT_APP_CDN_URL}/christmas/kwadrat_geni_01.png`}
                imageHover={`${process.env.REACT_APP_CDN_URL}/christmas/kwadrat_geni_01_hover.png`}
                title="GENIBOT"
                url={`${appContext.lang}${appContext.routeMap.products.path[appContext.lang]}#genibot`}
            />
            <Tile
                hover="dark-blue"
                className="genibot"
                image={`${process.env.REACT_APP_CDN_URL}/kolorowe_kartoniki_1.png`}
                imageHover={`${process.env.REACT_APP_CDN_URL}/kolorowe_kartoniki_2.png`}
                title="KARTONIKI"
                url={`${appContext.lang}${appContext.routeMap.products.path[appContext.lang]}/1000-kolorowych-kartonikow-do-maty-do-kodowania`}
            />
            <Tile
                transparent
                url={`${appContext.lang}${appContext.routeMap.products.path[appContext.lang]}`}
            />
        </TileContainer>
    );

    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <HeaderContainer
            fluid="true"
            as="header"
            isHomePage={isHomePage}
            className={(isCompact || !isHomePage ? 'nav-compact' : '')
                + (!isHomePage && appContext.breakingNews?.is_visible ? ' with-breaking-news' : '')}
            ref={headerRef}
        >

            {getEventVideo(headerRef.current.clientHeight, document.body.clientWidth)}
            <NavWrapper
                expand="lg"
                className={`container bg-dark-blue ${appContext.breakingNews?.is_visible ? 'with-breaking-news' : ''}`}
                fixed="top"
                expanded={isExpanded}
            >
                <Navbar.Brand href={`/${appContext.lang}`} className="navbar-brand bg-white p-0">
                    <LogoImage
                        alt=""
                        src={`${process.env.REACT_APP_CDN_URL}edu_logo.svg`}
                        className="img-fluid d-inline-block align-top"
                        isCompact={isCompact}
                    />
                </Navbar.Brand>
                <NavToggle
                    className="navbar-dark"
                    aria-controls="nav-collapse"
                    label="Rozwiń/zwiń menu"
                    onClick={() => setIsExpanded((e) => !e)}
                >
                    {isExpanded ? <CloseIcon className="fa fa-times" /> : <HamburgerIcon className="fa fa-bars" />}
                </NavToggle>
                <NavbarCollapse id="nav-collapse" className="bg-dark-blue">
                    <Nav className="mr-auto" onClick={() => setIsExpanded(false)}>
                        <NavElement
                            className={appContext.page === 'products_for_preschool' ? 'active' : ''}
                            to={`${appContext.routeMap.products_for_preschool.path[appContext.lang]}`}
                        >
                            {translations.products_for_preschool[appContext.lang]}
                        </NavElement>
                        <NavElement
                            className={appContext.page === 'products_for_school' ? 'active' : ''}
                            to={`${appContext.routeMap.products_for_school.path[appContext.lang]}`}
                        >
                            {translations.products_for_school[appContext.lang]}
                        </NavElement>
                        <NavElement
                            className={appContext.page === 'courses' ? 'active' : ''}
                            to={`${appContext.routeMap.courses.path[appContext.lang]}`}
                        >
                            {translations.courses[appContext.lang]}
                        </NavElement>
                        <NavElement
                            className={appContext.page === 'events' ? 'active' : ''}
                            to={`${appContext.routeMap.events.path[appContext.lang]}`}
                        >
                            {translations.events[appContext.lang]}
                        </NavElement>
                        <NavElement
                            className={appContext.page === 'labs' ? 'active' : ''}
                            to={`${appContext.routeMap.labs.path[appContext.lang]}`}
                        >
                            {translations.labs[appContext.lang]}
                        </NavElement>
                    </Nav>
                    <MobileNav>
                        <NavElement
                            as="a"
                            href="#kontakt"
                            onClick={() => { setIsExpanded(false); }}
                            to=""
                        >
                            {translations.contact[appContext.lang]}
                        </NavElement>
                    </MobileNav>
                    <Nav>
                        <NavElement
                            as="a"
                            to="https://akademia.uczymydzieciprogramowac.pl/"
                            color="#85DA00"
                            href="https://akademia.uczymydzieciprogramowac.pl/"
                        >
                            {translations.academy[appContext.lang]}
                        </NavElement>
                    </Nav>
                </NavbarCollapse>
                <CartElement>
                    <CartNavElement
                        to="/koszyk"
                        onClick={() => {
                            setNumberOfActiveStep('1');
                            setIsExpanded(false);
                        }}
                    >
                        <CartIcon>
                            <CartBadge>{itemsCount}</CartBadge>
                        </CartIcon>
                    </CartNavElement>
                </CartElement>
                <TickerBar breakingNews={appContext.breakingNews} />
                {(isHomePage || isProductPage) && (
                    <SearchWrapper isSearchActive={isSearchActive}>
                        <Search />
                    </SearchWrapper>
                )}
            </NavWrapper>
            {isHomePage && getTileContainer()}
            {isHomePage && getModal()}
            {isWelcomeModal && getWelcomeModal()}
        </HeaderContainer>
    );
});
