import {getClient, getToken} from "./Auth";
import Cookies from 'universal-cookie';

const handleErrors = response => {
    if (!response.ok) {
        if (response.status && (response.status === 401 || response.status === 400)) {
            getClient().owner.getToken('api', 'api').then(token => {
                const cookies = new Cookies();
                cookies.set('token', token.data, {path: '/', httpOnly: false, SameSite: 'none'});
                cookies.set('token_expires', token.expires, {path: '/', httpOnly: false, SameSite: 'none'});
                setTimeout( () => window.location.reload(), 100 );
            });
            return false;
        }
    }
    return response;
};

export async function getNoAuth(endpoint) {
    return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`)
        .then(result => result.json());
}

export async function get(endpoint) {
    const token = await getToken();

    return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleErrors)
        .then(result => result.json());
}

export async function post(endpoint, body) {
    const token = await getToken();

    return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    })
        .then(handleErrors)
        .then(result => result.json());
}
